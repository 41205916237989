html { 
  font-size: 62.5%;
} 
body {
  font-family: $font-family-default;
  font-size: 1.3rem;
  background: $body-bg;
  color: $text-color;
  &.layout-boxed {
    background: #e6e6e6;
  }
  &.show-boxpage {
    overflow: hidden;
    height: 100%;
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  // .container.no-index {
  //     width: 1200px;
  // }
  #wrapper-site {
    &:not(.page-index) {
      /* .container.no-index {
          width: 1200px;
      } */
    }
  }
}

//Overright bootstrap  --------------------------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
  .col-sm-cus-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

//Topbar  --------------------------------------------------------------------------------------------------------------
#topbar {
  color: $topbar-color;
  font-size: $topbar-font-size;
  padding: 15px 0;
  background-color: $topbar-bg;
  font-family: $font-family-default;
  position: relative;
  z-index: 101;
  a {
    color: $topbar-link-color;
  }
}

//Header  --------------------------------------------------------------------------------------------------------------
#header {
  .logo {
        margin: 0 auto;
  }
  .js-btn-search {
    width: 21px;
    height: 21px;
    cursor: pointer;
    @include rtl-margin-right(20px);
    padding-top: 3px;
    text-align: center;
    i {
      font-size: 19px;
      color: #383838;
    }
    &:hover{
      i{
        color: $theme-color-default;
      }
    }
  }
  .social-header{
    ul{
      li{
        &:not(:last-child){
          @include rtl-margin-lr(0, 10px);
        }
        a{
          i{
            font-size: 0;
            text-align: center;
            min-width: 20px;
            width: 20px;
            height: 20px;
            border: 1px solid #a5a5a5;
            @include border-radius(50%);
            color: #505050;
            @include transition(all 0.35s ease);
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            &:before{
              font-size: 1.2rem;
            }
          }
          &:hover{
            i{
              border-color: $theme-color-default;
              background-color: $theme-color-default;
              @include scale(1.2,1.2);
              &:before{
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
#header-sticky {
  visibility: hidden;
  display: none;
}
@media (min-width: 768px) {
  #header-sticky {
    position: fixed;
    width: 100%;
    top: -50px;
    @include rtl-left(0);
    padding: 10px 0;
    z-index: 10;
    @include box-shadow(0 6px 5px rgba(#000,0.1));
    @include transition(top 0.45s ease);
    &.sticky-menu-active {
      visibility: visible;
      top: 0;
      @include transition(top 0.45s ease);
      display: block;
      background-color: #f7f8f8;
      .contentstickynew_search{
        #search_widget{
          .toggle-search{
            display: none!important;
          }
          form{
            display: block!important;
            &:after{
              display: none;
            }
            .ui-autocomplete-input{
              width: 100%;
              @include box-shadow(0 0 10px 0 rgba(0,0,0,0.15));
              padding-top: 0;
              padding-bottom: 0;
              @include rtl-padding-lr(30px,135px);
              min-height: 46px;
              font-size: 1.4rem;
              @include border-radius(25px);
              background-color: $white;
              background-image: none;
              border: none;
              @include rtl-text-align-left();
              @include placeholder {
                text-transform: uppercase;
                font-size: 1.3rem;
                color: #d7d7d7;
              }
            }
            button{
              top: 50%;
              height: 100%;
              width: 50px;
              @include rtl-right(10px);
            }
          }
        }
      }
      .group-header-list{
        .header_link_myaccount{
          .block-novaccount{
            @include square(40px);
            background: $bg-header;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(50%);
            @include transition(all 0.35s ease);
            @include rtl-margin-lr(0, 10px);  
            cursor: pointer;
            .icon-novaccount{
              background-image: url(../img/icon/icon-group-header.png);
              background-repeat: no-repeat;
              background-position: -36px -0px;
              width: 15px;
              height: 19px;
              @include transition(all 0.35s ease);
              display: inline-block;
            }
            &:hover{
              background: $theme-color-default;
              .icon-novaccount{
                background-position: -36px -26px;
              }
            }
          }
        }
        .header_link_wishlist{
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include square(40px);
          @include border-radius(50%);
          @include rtl-margin-lr(0, 10px);
          background: $bg-header;
          @include transition(all 0.35s ease);
          a{
            display: inline-block;
            .icon-novwishlist{
              display: inline-block;
              background-image: url(../img/icon/icon-group-header.png);
              background-repeat: no-repeat;
              background-position: -24px -2px;
              width: 16px;
              height: 14px;
              @include transition(all 0.35s ease);
            }
          }      
          &:hover{
            background: $theme-color-default;
            a{
              .icon-novwishlist{
                background-position: -24px -20px;
              }
            }

          }
        }
        .header_link_myaccount_infos{
          .myaccount-icon{
            @include square(40px);
            background: $bg-header;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(50%);
            @include transition(all 0.35s ease);
            cursor: pointer;
            &:after{
              display: none;
            }
            .icon_link_myaccount_infos{
              display: inline-block;
              background-image: url(../img/icon/icon-group-header.png);
              background-repeat: no-repeat;
              background-position: -68px -0px;
              width: 19px;
              height: 19px;
              @include transition(all 0.35s ease);
            }
            &:hover{
              background: $theme-color-default;
              .icon_link_myaccount_infos{
                background-position: -68px -26px;
              }
            }
          }
          .account-list{
            top: calc(100% + 25px);
          }
        }
      }
    }
  }
}

.btnov-lines {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 1px;
  background: #383838;
  @include transition(all 0.3s ease);
  cursor: pointer;
  &:before,&:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 13px;
    height: 1px;
    background: #383838;
    @include transition(all .3s ease);
  }
  &:before {
      top: 4px;
  }
  &:after {
      top: -4px;
  }
  .act & {
    background: transparent;
    @include transition(all 0.3s ease);
    &:before, &:after {
        top: 0;
        @include transition(all .3s ease);
    }
    &:before {
      @include rotate3d(0,0,1,45deg);
    }
    &:after {
      @include rotate3d(0,0,1,-45deg);
    }
  }
}
.toggle-group-menu-vertical {
  @include rtl-margin-right(29px);
}
.btnov-lines-large {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 3px;
  background: #383838;
  @include transition(all 0.3s ease);
  cursor: pointer;
  &:before,&:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 16px;
    height: 3px;
    background: #383838;
    @include transition(all .3s ease);
  }
  &:before {
      top: 6px;
  }
  &:after {
      top: -6px;
  }
  .act & {
    background: transparent;
    @include transition(all 0.3s ease);
    &:before, &:after {
        top: 0;
        @include transition(all .3s ease);
    }
    &:before {
      @include rotate3d(0,0,1,45deg);
    }
    &:after {
      @include rotate3d(0,0,1,-45deg);
    }
  }
}
.social-home{
  ul{
    li{
      &:not(:last-child){
        @include rtl-margin-lr(0, 10px);
      }
      a{
        i{
          font-size: 0;
          text-align: center;
          min-width: 20px;
          width: 20px;
          height: 20px;
          border: 1px solid #a5a5a5;
          @include border-radius(50%);
          color: #505050;
          @include transition(all 0.35s ease);
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          &:before{
            font-size: 1.2rem;
          }
        }
        &:hover{
          i{
            border-color: $theme-color-default;
            background-color: $theme-color-default;
            @include scale(1.2,1.2);
            &:before{
              color: $white;
            }
          }
        }
      }
    }
  }
}
.toggle-nav {
  position: relative;
  text-align: center;
  padding: 3px;
  overflow: hidden;
  color: #fff;
  text-transform: uppercase;
  font-family: $font-family-primary;
  cursor: pointer;
  span:not(.btnov-lines) {
    @include rtl-margin-left(10px);
    font-size: 12px;
  }
}
.displayhomenovone {
  &:not(.page-index) {
    .toggle-nav {
      cursor: pointer;
    }
  }
}
.displayhomenovtwo {
  &:not(.page-index) {
    .toggle-nav {
      cursor: pointer;
    }
  }
}
.displayhomenovthree {
  .toggle-nav {
    cursor: pointer;
  }
}
.displayhomenovfour {
  &:not(.page-index) {
    .toggle-nav {
      cursor: pointer;
    }
  }
}



.nov-cart{
  position: relative;
  z-index: 1001;
}

//Header menu  --------------------------------------------------------------------------------------------------------------
#_desktop_top_menu {
  
}

// Topcolumn  --------------------------------------------------------------------------------------------------------------
.top-column {
  z-index: 1;
  margin-top: 2rem;
  position: relative;
}

// Slideshow  --------------------------------------------------------------------------------------------------------------
#slidershow {
  margin-bottom: 9.5rem;
}
.slideshow-container {
  background-color: #FFF;
  position: relative;
  z-index: 1;
  margin-bottom: 3.5rem;
}

#displayTop {
}
//SEARCH-HOME
#module-novadvancedsearch-result{
  #content-wrapper{
    #main{
      margin-bottom: 30px;
    }
    .page-content{
      .row{
        .item{
          margin-bottom: 30px;
        }
      }
    }
  }
}
//Breadcrumb  --------------------------------------------------------------------------------------------------------------
.breadcrumb-container {
  background: $breadcrumb-bg;
  position: relative;
  z-index: 1;
}

// center_column
#main {
  margin-bottom: 9rem;
  #index & {
    margin-bottom: 0;
  }
}
.columns-container {
  z-index: 1;
  position: relative;
}

.nov-row {
  position: relative;
  .background-row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .lang-rtl & {
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
    }
  }
}

.page-home {
  @media (max-width: 767px){
    overflow: hidden;
  }
  
  .nov-row {
    &.no-gutters .nov-row-wrap {
      margin-right: 0;
      margin-left: 0;
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  > .nov-row {
    margin-bottom: 8rem;
  }
  .row-shopcategory {
    margin-top: 16.5rem;
    .html-cate {
      padding-top: 6.6rem;
      padding-bottom: 6rem;
      .title_block {
        @include rtl-padding-left(23px);
        position: relative;
        margin-bottom: 25px;
        &:before {
          content: "";
          width: 20px;
          height: 1px;
          background: $theme-color-primary;
          position: absolute;
          @include rtl-left(0);
          bottom: 0;
        }
      }
      ul {
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        @include rtl-padding-left(23px);
        li {
          line-height: 1.8em;
        }
      }
    }
    .image-special {
      @include rtl-text-align-right();
      margin-top: -120px;
    }
  }
}

.center_column {
  position: relative;
}

//BOTTOM  --------------------------------------------------------------------------------------------------------------
.bottom-container {
    position: relative;
}
  
// Nov Footer ----------------------------------------------------------------------------------------------------------
.footer {
  background-color: $footer-bg;
  font-size: $footer-font-size;
  color: $footer-text-color;
  padding: $footer-padding;
  margin: $footer-margin;
  overflow: hidden;
  .block {
      .title_block{
          position: relative;
          font-family: $font-family-primary;
          font-weight: 600;
          color: $footer-heading-color;
          font-size: $footer-heading-font-size;
          margin-bottom: 23px;
          padding-bottom: 16px;
          text-transform: capitalize;
          @include rtl-text-align-left();
          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            @include rtl-left(0);
            background-color: $theme-color-default;
            width: 70px;
            height: 2px;
          }
          span{
            &.sub_title{
              margin-top: 33px;
              width: 100%;
              color: $footer-link-color;
              line-height: normal;
              text-transform: capitalize;
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
              color: #414141;
            }
          }
      }
      .block_content{
        color: $footer-link-color;
        a {
          color: $footer-link-color;
          font-weight: 400;
          &:hover {
            color: $theme-color-default;
            text-decoration: none;
          }
        }
        p {
          line-height: 20px;
          margin-bottom: 13px;
          font-size: 14px;
        }
        h5{
          font-size: 14px;
          color: #414141;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 12px;
          @include rtl-padding-lr(0, 20px);
          display: inline-block;
        }
      }
  }
  .nov-html {
    ul {
      list-style: none;
      @include rtl-padding-left(0);
      margin-bottom: 0;
      li {
        margin-bottom: 12px;
        font-size: 14px;
        a{
          font-size: 14px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        i {
          min-width: 30px;
          font-size: 1.8rem;
        }
      }
    }
  }
  .social-copyright{
    margin-top: 34px;
    margin-bottom: 40px;
    ul{
      li{
        &:not(:last-child){
          @include rtl-margin-lr(0, 7px);
        }
        a{
          @include transition(all 0.35s ease);
          @include square(40px);
          @include border-radius(5px);
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          background-color: $white;
          i{
            text-align: center;
            line-height: 1;
            &:before{
              font-size: 20px;
            }
          }
          &:hover{
            background-color:#4189ec;
            &.facebook{
              background-color:#4189ec;
            }
            &.google{
              background-color:#d50f25;
            }
            &.twitter{
              background-color:#61bdb2;
            }
            &.linkedin{
              background-color:#0e76a8;
            }
            &.instagram{
              background-color:#92624b;
            }
            i{
              &:before{
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .social-contact{
    margin-top: 22px;
    margin-bottom: 10px;
    ul{
      li{
        &:not(:last-child){
          @include rtl-margin-lr(0, 10px);
        }
        a{
          @include transition(all 0.35s ease);
          i{
            font-size: 0;
            text-align: center;
            min-width: 30px;
            width: 30px;
            height: 30px;
            border: 1px solid #d9dad9;
            @include border-radius(50%);
            color: #414141;
            @include transition(all 0.35s ease);
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            &:before{
              font-size: 11px;
            }
            &.icon-facebook-1{
              &:before{
                font-size: 14px;
              }
            }
          }
          &:hover{
            i{
              border-color: $theme-color-default;
              background-color: $theme-color-default;
              @include transition(all 0.35s ease);
              @include scale(1.15,1.15);
              &:before{
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

// Nov footer copyright ------------------------------------------------------------------------------------------------
#nov-copyright {
  background: $copyright-bg;
  padding: $copyright-padding;
  margin: $copyright-margin;
  color: $copyright-text-color;
  font-size: 14px;
  font-weight: 400;
}



// Canvas --------------------------------------------------------------------------------------------------------------
.nov-canvas-menu {
  color: #666;
  position: fixed;
  z-index: 101;
  top: 0;
  @include rtl-left(-36rem);
  background: #fff;
  width: 36rem;
  height: 100%;
  @include rtl-padding(4rem, 6.2rem, 4rem, 7.3rem);
  @include box-shadow(5px 0 12px rgba(#000,0.25));
  @include transition(all 0.35s ease 0.15s);
  overflow: hidden;
  .casvas-content {
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    @include rtl-right(-16px);
    top: 0;
    bottom: 0;
    @include rtl-padding(4rem, 6.2rem, 4rem, 5.7rem);
    > div {
      @include translateY(50px);
      @include transition(all 0.35s ease 0);
      @include opacity(0);
    }
    .language-selector {
      padding-bottom: 1.5rem;
    }
  }
  &.canvas-active {
    @include rtl-left(0);
    .casvas-content {
      > div {
        @include translateY(0);
        @include transition(all 0.35s ease 0);
        @include opacity(1);
        &:first-child {
          -webkit-transition-delay: .1s;
          transition-delay: .1s;
        }
        &:nth-child(2) {
          -webkit-transition-delay: .3s;
          transition-delay: .3s;
        }
        &:nth-child(3) {
          -webkit-transition-delay: .5s;
          transition-delay: .5s;
        }
        &:nth-child(4) {
          -webkit-transition-delay: .7s;
          transition-delay: .7s;
        }
      }
    }
  }
}
  .canvas-overlay {
    position: fixed;
    top: 0;
    @include rtl-left(0);
    z-index: 9;
    width: 0;
    height: 0;
    @include opacity(0);
    transition: width 0s .5s,height,0s .5s,opacity .5s 0s;
    background: rgba(#000,0.5);
    cursor: url(../img/cusor.png), auto;
    &.act {
      width: 100%;
      height: 100%;
      @include opacity(1);
      transition: width 0s 0s,height,0s 0s,opacity .5s 0s;
    }
  }

// Backtotop -----------------------------------------------------------------------------------------------------------
#_desktop_back_top{
  position: fixed;
  bottom: 50px;
  @include rtl-right(15px);
  @include opacity(1);
  z-index: 999;
  cursor: pointer;
  @include transition(all 0.3s ease);
  text-align: center;
  span{
    padding: 4px 12px;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    border: 1px solid #ccc;
    display: inline-block;
    background-color: #fff;
    color: #ccc;
    font-size: 2rem;
    @include transition(all 0.4s ease);
  }
  .text{
    display: none;
  }
  &:hover {
    @include opacity(1);
    bottom: 55px;
    span {
      color: $theme-color-default;
    }
  }
}
#_mobile_back_top {
  i:before {
    content: "\f39d";
    font-family: $font-icons;
  }
}

// Page Preloader
#page-preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  z-index: 999999999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

$size: 245px;
.page-loading {
  width:  $size;
  height: $size;
  position: relative;
  div {
    width:  $size;
    height: $size;
    position: absolute;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -70px;
    margin-left: -80px;
  }
  div {
    width:  $size * .8;
    height: $size * .8;
    margin: $size * .1;
    border-radius: 20%;
    transform: rotate(45deg);
    mix-blend-mode: multiply;
    animation: 2s cubic-bezier(.66,-.7, .27, 1.6) infinite;
    &:nth-child(1) {
        background-color: #29ffff;
        animation-name: rotate-1;
    }
    &:nth-child(2) {
        background-color: #ff65ff;
        animation-name: rotate-2;
    }
    &:nth-child(3) {
        background-color: #ffc;
        animation-name :rotate-3;
    }
  }
}
//icon-home
.nov-icon-list{
  background-image: url(../img/icon/icon-list.png);
  background-repeat: no-repeat;
  width: 26px;
  height: 14px;
  display: inline-block;
}
.nov-icon-account{
  cursor: pointer;
  background-image: url(../img/icon/icon-account.png);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
}
.nov-icon-menu{
  background-image: url(../img/icon/icon-menu.png);
  background-repeat: no-repeat;
  width: 26px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
}

$r: 45deg;
@keyframes rotate-1 {
    to {
        transform: rotate($r * 3);
    }
}
@keyframes rotate-2 {
    to {
        transform: rotate($r * 5);
    }
}
@keyframes rotate-3 {
    to {
        transform: rotate($r * 7);
    }
}