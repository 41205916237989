//CSS Modules Prestashop 1.7
//nov_sideward
.nov_sideward {
	position: fixed;
	padding-top: 55px;
	padding-bottom: 30px;
	top: 0;
	z-index: 100;
	height: 100%;
	height: 100vh;
	min-width: 300px;
	max-width: 300px;
	visibility: hidden;
	@include opacity(0);
	@include transition(all 0.3s ease 0.1s);
	background: #fff;
	overflow-y: auto;
	&.nov_sideward_right {
		@include rtl-right(-300px);
	}
	&.nov_sideward_left {
		@include rtl-left(-300px);
	}
	.nov_close {
		text-align: center;

		cursor: pointer;
		i {
			font-size: 24px;
			color: #222;
			font-weight: 900;
		}

	}
	.nov_sideward-content {
		padding-top: 58px;
	}

}
.nov-toggle {
	&.active {
	    .nov_sideward {
	      visibility: visible;
	      @include opacity(1);
	    }
	    .nov_sideward_right {
			@include rtl-right(0);
		}
		.nov_sideward_left {
			@include rtl-left(0);
		}
    }
}

// Block block_myaccount_infos
.account-list {
	position: absolute;
	top: calc(100% + 25px);
	display: none;
	z-index: 4;
	@include rtl-right(15px);
	padding-top: 0;
	min-width: 220px;
	background: $white;	
	border: 0;
	margin: 0;
	padding: 0;
	@include box-shadow(0 0 5px 0 rgba(0,0,0,0.05));
	.language-selector{
		@include rtl-margin-lr(15px, 15px);
		border-top: 1px solid #f2f2f3;
		padding-bottom: 30px;
		margin-top: 10px;
		position: relative;
		.language-text{
			font-size: 14px;
			font-weight: 400;
			color: #505150;
			text-transform: uppercase;
			padding-top: 14px;
			padding-bottom: 15px;

		}
		.dropdown-toggle{
			background: #fafafa;
			height: 30px;
			position: relative;
			.expand-more{
				font-size: 12px;
				line-height: 30px;
				@include rtl-padding-lr(15px, 0);
			}
			&:after{
				display: none;
			}
			&:before{
				content: "\e918";
				font-family: $font-icon-tello;
				font-size: 13px;
				color: #444444;
				position: absolute;
				top: 50%;
				@include translateY(-50%);
				@include rtl-right(15px);
			}
		}
		.language-list{
			width: 100%;
			top: calc(100% - 30px);
			padding: 0;
			.language-list-content{
				.language-item{
					padding: 5px 15px;
					a{
						font-size: 1.2rem;
					}
					&:hover{
						background: #fafafa;
						a{
							color: $theme-color-default;

						}

					}
				}
			}
		}

	}
	.currency-selector{
		border-top: 1px solid #f2f2f3;
		@include rtl-margin-lr(15px, 15px);
		padding-top: 14px;
		padding-bottom: 20px;
		span{
			font-size: 14px;
			color: #505150;
			text-transform: uppercase;
			padding-bottom: 7px;
			display: inline-block;
		}
		ul{
			li{
				padding-bottom: 5px;
				a{
					font-size: 12px;
					color: #505250;
					text-transform: capitalize;
					&:hover{
						color: $theme-color-default;
					}
				}
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 0;
		background: #fff;
	}
	.account-list-content{
		position: relative;
		padding-top: 0;
		>li{
			@include rtl-text-align-left();
				
			padding: 4px 15px 4px 15px;
			a{
				color: #505150;
				font-size: 13px;
			}
			&:first-child{
				padding-top: 13px;
			}
			&:last-child{
				padding-bottom: 15px;
			}
			span, >a{
				text-transform: capitalize;
			}
			
			&:hover{
				a{
					color: $theme-color-default;
				}
			}
		}

	}
}	
#block_myaccount_infos {
	position: relative;
	.myaccount-title {
		cursor: pointer;
		padding: 5px 10px;
		background: #fff;
		font-size: 10px;
		@include border-radius(3px);
		&:after {display: none;}
		a {
			color: #909090;
			&:hover {
				color: $theme-color-default;
			}
		}
		i {
			font-size: 18px;
			@include rtl-margin-right(15px);
			vertical-align: middle;
		}
		span {
			display: inline-block;vertical-align: middle;text-transform: uppercase;
			font-family: $font-family-primary;
		}
	}
	
	&.open {
		.account-list {
			padding-top: 0;
			visibility: visible;
			@include rtl-right(0);
			@include opacity(1);
		}
	}
}
#_mobile_user_info {
	position: relative;
	.icon-myaccount {
		margin-bottom: 5px;
	}
	&.active {
		.account-list {
			padding-top: 20px;
			visibility: visible;
			@include opacity(1);
		}
	}
}

//Block shopping cart
.blockcart {
	position: relative; 
	.header-cart {
		text-align: center;
		cursor: pointer;
		@include flexbox();
		@include align-items(center);
		.header-cart-icon{
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			@include square(40px);
			@include border-radius(50%);
			position: relative;
			@include rtl-margin-lr(0, 10px);
			.cart-products-count {
				position: absolute;
				top: -5px;
				@include rtl-left(50%);
				@include translateX(-50%);
				@include size(22px, 11px);
				background: $theme-color-default;
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
				color: $theme-color-primary;
				line-height: 21px;
				&:before {
					content: "";
					position: absolute;
					top: -5px;
					left: 0;
					width: 0;
					height: 0;
					border-left: 11px solid transparent;
					border-right: 11px solid transparent;
					border-bottom: 5px solid $theme-color-default;
				}
				&:after {
					content: "";
					position: absolute;
					bottom: -5px;
					left: 0;
					width: 0;
					height: 0;
					border-left: 11px solid transparent;
					border-right: 11px solid transparent;
					border-top: 5px solid $theme-color-default;
				}
			}
			.shopping-cart{
				display: inline-block;
				width: 21px;
				height: 19px;
				background-image: url(#{$image-theme-path}icon-group-header.png);
				background-position: 0 0;
				background-repeat: no-repeat;
			}
		}
		
	}
	.cart_block {
		position: absolute;
		top: 100%;
		@include rtl-right(0);
		z-index: 100;
		visibility: hidden;
		@include opacity(0);
		height: auto;
		width: 300px;
		padding-top: 20px;
		&.has-scroll {
			ul {
				max-height: 310px;
				overflow-y: scroll;
			}
		}
		.cart-block-content {
			padding-top: 20px;
			background: #fff;
			@include box-shadow(0px 0 10px 0 rgba(#000,0.35));
			@include border-radius(3px);
		}
		ul {
			list-style: none;
			padding: 10px 15px 0 20px;
			margin-bottom: 0;
			li {				
				@include rtl-padding(20px, 20px, 20px, 0);
				position: relative;
				&:first-child {
					padding-top: 0;
				}
				&:not(:last-child) {
					border-bottom: 1px solid #e6e6e6;
				}
			}
			.media {
				position: relative;
			}
			.remove-from-cart {
				position: absolute;
			    font-size: 16px;
			    color: #8f8f8f;
			    display: inline-block;
			    width: 23px;
			    height: 23px;
			    background: red;
			    text-align: center;
			    border-radius: 50%;
			    top: -7px;
			    @include rtl-left(-10px);
			    background: #e6e6e6;
				&:hover {
					background: $theme-color-default;
					color: #fff;
				}
			}
			.product-image {
				width: 62px;
				@include rtl-margin-right(2rem);
				border: 1px solid #e6e6e6;
				@include border-radius(5px);
			}
			.product-name {
				font-size: 1.1rem;
				margin-bottom: 5px;
				font-weight: 500;
			}
			.product-price {
				color: $gray-dark;
				font-weight: 700;
				font-size: 1.2rem;
				font-family: $font-family-primary;
			}
			.media-body {
				position: relative;
				.remove-from-cart {
					font-size: 16px;
					color: #999999;
					display: inline-block;
					&:hover {
						color: $theme-color-default;
					}
				}
			}
		}
		.cart-products-top {
			padding: 72px 0 57px;
			position: relative;
			text-align: center;
			.cart-products-count {
				font-size: 11px;
				line-height: 22px;
				position: absolute;
				top: 45px;
				left: 50%;
				width: 22px;
				height: 22px;
				@include rtl-margin-left(-11px);
				background: $theme-color-default;
				@include border-radius(50%);
				color: #fff;
			}
		}
		.cart-subtotals, .cart-total {
			font-family: $font-family-default;
			font-size: 1.6rem;
			color: #222;
			font-weight: 900;
			.label {
				text-transform: uppercase;
				background-color: $white;
				color: $gray-dark;
				@include rtl-padding-right(15px);
			}
			.value {
				display: inline-block;
				position: relative;
				font-size: 14px;
				float: right;
			}
		}
		.cart-subtotals {
			padding: 10px 20px;
			.label {
				margin-bottom: 1.2rem;
			}
			> div {
				border-top: 1px solid #222;
				margin-top: 1.3rem;
				&:first-child{
					margin-top: 0;
				}
			}
		}
		.cart-total {
			padding: 0 20px;
			>div {
				padding: 25px 0 2px;
				border-top: 1px solid #222;
				margin-top: -1px;
			}
			.value {
				font-size: 16px;
				font-weight: 900;
				line-height: 1;
				color: $gray-dark;
			}
		}
		.cart-buttons {
			padding: 20px;
			a {
				display: block;
				font-size: 11px;
				font-family: $font-family-default;
				@include border-radius(3px);
				min-width: 120px;
				text-align: center;
				height: 44px;
				line-height: 44px;
				padding: 0;
				border: none;
				&:last-child {
					@include rtl-margin-right(0);
				}
				&.btn-cart {
					background: #ececec;
					color: #222;
				}
				&.btn-checkout {
					background: #222;
				}
				&:hover{
					background: $theme-color-default;
					color: #fff;
				}
			}
		}
		.no-items {
			padding: 20px;
			font-size: 1.4rem;
			background: #fff;
		}
		&.hover-active {
			visibility: visible;
			@include opacity(1);
			-webkit-animation-name: slideInUpNov;
            animation-name: slideInUpNov;
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            -webkit-animation-timing-function: ease;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
		}
	}
}

// Modal Cart Prestashop
#blockcart-modal {
	padding: 0 !important;
	.modal-dialog {
		max-width: 652px;
	}
	.modal-content {
		@include box-shadow(0px 0px 10px rgba(#222,0.35));
		@include border-radius(0.5rem);
		border: none;
	}
	.modal-header {
		.modal-title {
			font-weight: 600;
			text-transform: uppercase;
			font-family: $font-family-primary;
			color: $theme-color-default;
			font-size: 1.4rem;
			i {
				@include rtl-margin-right(9px);
				@include square(20px);
			    line-height: 20px;
				background: $theme-color-default;
				color: #fff;
				@include border-radius(50%);
				text-align: center;
				vertical-align: top;
			    font-size: 1.2rem;
			}
		}
		button {
			@include square(18px);
			background: $theme-color-default;
			@include border-radius(50%);
			position: absolute;
			@include rtl-right(-10px);
			top: -10px;
			i {
				@include square(18px);
				line-height: 18px;
				color: #fff;
				font-size: 1.2rem;
			}
			&:hover {
				background: $theme-color-primary;
				i {
					color: #fff;
				}
			}
		}
		@media (max-width: 450px){
			.modal-title{
				text-align: center;
				width: 100%;
				i{
					display: none;
				}
			}
		}
	}
	.modal-body {
		font-size: 1.2rem;
		padding: 0 20px;
		border-top: 1px dotted #828282;
		.divide-right {
			@include rtl-border-right(1px dotted #828282);
			.col-md-5 {
				@include rtl-padding-right(20px);
			}
		}
		.product-name {
			font-size: 1.4rem;
			color: #444;
			font-family: $font-family-primary;
			font-weight: 300;
		}
		.product-price {
			color: $theme-color-default;
			font-size: 1.6rem;
			font-weight: 700;
		}
		.cart-products-count {
			font-size: 1.6rem;
			color: #444;
		}
		> .row > .col-md-6 {
			padding-top: 22px;
			padding-bottom: 22px;
		}
		@media (max-width: 767px){
			>.row >.col-md-6{
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	.modal-footer {
		border-top: 1px dotted #828282;
		-webkit-justify-content: center;
		justify-content: center;
		padding-bottom: 15px;
		button {
			padding-left: 25px;
			padding-right: 25px;
			padding-top: 9px;
			padding-bottom: 9px;
		}
		> :not(:last-child) {
		    @include rtl-margin-right(1rem);
		}
		@media (max-width: 767px) {
			text-align: center;
			.btn  {
				padding-left: 15px;
				padding-right: 15px;
				height: 32px;
			}
		}
	}
}

// Block Seach
#search_widget {
	.toggle-search {
		cursor: pointer;
		color: #515151;
		display: none;
		@include align-items(center);
		@include justify-content(center);
		@include square(40px);
		@include border-radius(50%);
		.search-header{
			width: 19px;
			height: 19px;
			display: inline-block;
			background-image: url(../img/icon/icon-search.png);
			background-repeat: no-repeat;
			background-position: 0px 0;

		}
		&:hover {
			color: $theme-color-default;
			.search-header{
				background-position: -41px 0;
			}
		}
	}
	.form-search{
		position: relative;
	}
	form {
		@include box-shadow(none);
		input {
			width: 270px;
			max-height: 40px;
			padding-top: 10px;
			padding-bottom: 10px;
			@include rtl-padding-lr(20px, 40px);
			@include border-radius(0);
			@include box-shadow(none);
			@include rtl-text-align-left();
			outline: none;
			background-color: #f7f8f8;
			color: #747474;
			border: none;
			overflow: hidden;
			font-size: 12px;
			@include transition(all 0.4s ease-in-out);
			@include placeholder{
				font-size: 12px;
				color: #747474;
			}
			&:hover, &:focus {
				@include box-shadow(none);
				border-color: $theme-color-primary;
				outline: medium none;
				@include transition(all 0.4s ease-in-out);
			}
		}
		button {
			position: absolute;
			top: 50%;
			@include translateY(-50%);
			@include rtl-right(20px);
			border: none;
			padding: 0;
			background: none;
			line-height: 0.8;
			.search-header{
				width: 19px;
				height: 19px;
				display: inline-block;
				background-image: url(../img/icon/icon-search.png);
				background-repeat: no-repeat;
				background-position: -0px 0;
				cursor: pointer;
			}
			&:focus,&:hover {
				border: none;
				outline: none;
				.search-header{
					background-position: -41px 0;
				}
			}
		}
	}
}
#_mobile_search_content {
	border-radius: 30px;
	background: #fff;
	overflow: hidden;
	position: relative;

	.toggle-search {
		display: none;
		.search-header{
			width: 14px;
			height: 14px;
			display: inline-block;
			background-image: url(../img/icon/icon-search.png);
			background-repeat: no-repeat;
			background-position: 0 0;

		}
	}
	input {
		width: 100%;
		height: 34px;
		@include box-shadow(none);
		outline: none;
		border: none;
		padding: 0 34px 0 10px;
	}
	button {
		background: transparent;
		position: absolute;
		border: none;
		top: 50%;
		@include translateY(-50%);
		@include rtl-right(5px);
		@include flexbox();
		@include align-items(center);
		.search-header{
			width: 14px;
			height: 14px;
			display: inline-block;
			background-image: url(../img/icon/icon-search.png);
			background-repeat: no-repeat;
			background-position: -20px 0;
			cursor: pointer;

		}
		&:hover{
			.search-header{
				background-position: -19px 0;
			}
		}
	}
	.button-search{
		button{
			font-size: 0;
			top: 50%;
			@include translateY(-50%);
			padding: 0;
			z-index: 3;
			.icon-novsearch{
				width: 14px;
				height: 14px;
				display: inline-block;
				background-image: url(../img/icon/icon-search.png);
				background-repeat: no-repeat;
				background-position: -20px 0;
				cursor: pointer;
			}
		}
	}
}

// SearchBox
#nov-searchBox {
	z-index: 9999;
	position: fixed;
	width: 100%;
	height: 100%;
	width: 100vw;
	height: 100vh;
	right: -20%;
	top: 0;
	visibility: hidden;
	@include opacity(0);
	@include transition(all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94));
	background: rgba(#fff,0.95);
	&.active {
		right: 0;
		visibility: visible;
		@include opacity(1);
	}
	.nov-seachBoxClose {
		position: fixed;
		top: 30px;
		@include rtl-right(30px);
		color: #343434;
		cursor: pointer;
		i {font-size: 4.8rem;}
	}
	.title_search {
		color: #343434;
		text-transform: uppercase;
		margin-bottom: 6px;
		i {
			font-size: 7.2rem;
		}
		span {
			font-size: 3.6rem;
			font-weight: 700;
			display: block;
			margin-top: 14px;
		}
	}
	.description {
		font-size: 11px;
		font-weight: 500;
		text-transform: uppercase;
		color: #666;
		margin-bottom: 9rem;
		margin-left: auto;
		margin-right: auto;
		max-width: 450px;
	}
}

//Groups Selector
.groups-selector {
	position: relative;
	display: inline-block;
	.dropdown-toggle{
		.expand-more{
			position: relative;
			font-size: 11px;
			color: #505150;
			padding: 10px 20px;
			background: #f7f8f8;
			@include rtl-margin-lr(0, 7px);
			text-transform: uppercase;
			cursor: pointer;
			&:after{
				display: none;
			}
		}
	}
	.dropdown-toggle {
		&:after {
			display: none;
		}
	}
	.dropdown-menu {
		font-size: 12px;
		min-width: 110px;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 0px;
		top: calc(100% + 31px);
		@include rtl-left(0);
		@include transition(all 0.3s ease 0.1s);
		border: none;
		.groups-selector-list-content{
			@include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
			.groups-selector-item{
				@include rtl-margin-lr(15px, 15px);
				border-bottom: 1px solid #f7f8f8;
				padding-top: 5px;
				padding-bottom: 5px;
				&:first-child{
					padding-top: 8px;
				}
				&:last-child{
					border-bottom: none;
				}
				a{
					font-size: 12px;
					color: #505150;
					&:hover{
						color: $theme-color-default;
					}
				}
			}
		}
	}
}

//Form validate
.form-group {
	&.form-error {
		input, textarea {
			border: 1px solid #f13340;
			color: #f13340;
			background: url(../img/icon/form-error.png) 98% 5px no-repeat #fff1f2;
		}
	}
	&.form-ok {
		input, textarea {
			border: 1px solid #46a74e;
			color: #35b33f;
			background: url(../img/icon/form-ok.png) 98% 5px no-repeat #ddf9e1;
		}
	}
	&.form-error, &.form-ok {
		.form-control {
			@include rtl-padding-right(30px);
		}
	}
}

//uniform
.radio-inline, .checkbox {
	@include rtl-padding-left(0);
	.checker {
		@include rtl-float-left();
		span {
			top: 0px;
		}
	}
	div.radio {
		display: inline-block;
		span {
			@include rtl-float-left();
			top: 0px;
		}
	}
}

// module productcomments
.cancel {
  overflow: hidden;
  @include rtl-float-left();
  @include rtl-margin(0, 1px, 0, 0);
  width: 16px;
  height: 16px;
  cursor: pointer;
  a {
  	//background: url(../../../modules/productcomments/img/delete.gif) no-repeat 0 -16px !important;
  	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0;
  }
}

.pack_content {
  margin: 10px 0 10px 0;
}

.confirmation {
  margin: 0 0 10px;
  padding: 10px;
  border: 1px solid #e6db55;
  font-size: 13px;
  background: none repeat scroll 0 0 lightyellow;
}

.listcomment {
	list-style-type: none;
	margin: 0 0 20px 0 !important;
	li {
	  padding: 10px 0;
	  border-bottom: 1px dotted $base-border-color;
	  color: #666666;
	}

	.titlecomment {
	  display: block;
	  font-weight: bold;
	  font-size: 12px;
	  color: $base-text-color;
	  padding: 5px 0;
	}
}

//social
.social {
 	.block_content{
 		position: relative;
    	top: 5px;
 	}
	ul {
	    li {
			display: inline-block;
			padding-top: 0;
			@include size(32px,32px);
			@include border-radius(50%);
			@include rtl-margin-right(10px!important);
			padding: 0px;
			text-align: center;
			@include transition(all 0.2s ease-in-out);
			background-color: #bfbfbf;

			a {
				display:block;
				color:#fff;
				line-height: 32px;
				@include size(100%,100%);
				@include border-radius(50%);
				font-size: 22px;
				span {
				  display: none;
				}
				&:before {
				    display: inline-block;
				    font-family: $font-icon;
				    font-weight: normal;
				    font-size: 12px;
				}
				&:hover {
				  color: $white;
				  background: $theme-color-default;
				}
			}
			&.facebook {
				background-color: #305bbd;
				a {
				  &:before {
				    content: "\f09a";
				  }
				}
			}
			&.twitter {
				background-color: #49bbdc;
				a {
					&:hover{
						background-color: $theme-color-default;
					}
					&.product-name{
						display: none;
					}
				  &:before {
				    content: "\f099";
				  }
				}
			}
			&.rss {
				background-color: #dc4949;
				a {
				  &:before {
				    content: "\f09e";
				  }
				}
			}
			&.youtube {
				background-color: #4d282f;
				a {
				  &:before {
				    content: "\f167";
				  }
				}
			}
			&.google {
				a {
				  &:before {
				    content: "\f0d5";
				  }
				}
			}
			&.instagram {
				a {
				  &:before {
				    content: "\f16d";
				  }
				}
			}
			&.pinterest {
				a {
				  &:before {
				    content: "\f0d2";
				  }
				}
			}
			&:last-child {
				@include rtl-margin-right(0!important);
				a{
					i{
						@include rtl-margin-right(0!important);
					}
				}
			}
	    }
	}
	// @media (min-width: 768px) and (max-width: 1024px) {
	// 	ul li {
	// 		width: 30px;
	// 		height: 30px;
	// 		a {
	// 			line-height: 30px;
	// 			font-size: 14px;
	// 		}
	// 	}
	// }
}

//Reinsurance
#reinsurance_block {
  clear: both !important;
}

//addon RerversoForm
#account-creation_form fieldset.reversoform {
  padding: 10px !important;
}

#account-creation_form fieldset.reversoform .text label {
  display: block;
  padding: 0 0 10px 0;
  width: 100%;
  @include rtl-text-align-left();
}

#account-creation_form fieldset.reversoform .infos-sup {
  padding: 0 !important;
  font-size: 10px;
  font-style: italic;
  @include rtl-text-align-right();
}

//addon customer privacy
#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
  cursor: pointer;
}


//Addons comments products
#product_comments_block_extra {
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1.1rem;
	@include flexbox();
	@include flex-wrap(wrap);
	.comments_note {
		@include flexbox();
		@include rtl-margin-right(15px);
		span{
			font-size: 12px;
			color: #666766;
			font-weight: 400;
			text-transform: capitalize;
			@include rtl-margin-right(10px)
		}
	}
	a {
		text-decoration: none;
	}
	.comments_advices {
		@include flexbox();
		.open-comment-form{
			@include flexbox();
			@include align-items(center);
		}
		a {
			@include rtl-margin-right(22px);
			color: $text-color;
			font-size: 1.2rem;
			font-weight: 500;
			i {
				@include rtl-margin-right(7px);
				color: $theme-color-default;
				font-size: 1.6rem;
			}
			&:hover {
				color: $theme-color-default;
			}
			&:last-child {
				@include rtl-margin-right(0);
			}
		}
		
	}

}

#id_new_comment_form {
	.product-image {
		@include rtl-padding-right(15px);
	}
}
.product-comments {
	> span {
		margin-top: 10px;
		line-height: 1;
	} 
}
.star {
  position: relative;
  display: block;
  @include rtl-float-right();
  width: 15px;
  height: 18px;
  margin-top: -1px;
  overflow: hidden;
  cursor: pointer;
  &:after {
    content: url('#{$image-theme-base-path}star.png');
    display: inline-block;
    position: absolute;
    top: 2px;
    @include rtl-left(0);
  }
  a {
    display: block;
    position: absolute;
    text-indent: -5000px;
	width: 100%;
	height: 100%;
	background-position: 0 0;
	&:hover {
		background-position: 0 -32px;
	}
  }
  &.star_on, &.star_hover {
	  display: block;
	  &:after {
	    content: url('#{$image-theme-base-path}star-on.png');
	  }
	  a {
	  	background-position: 0 -16px;
	  }
  }
}

// + instagram
.instagram{
	.block_content{
		margin-top: 40px;
	}
	#instafeed{
		margin-left: -15px;
    	margin-right: -15px;
		&:after,&:before{
			display: table;
			content: "";
			clear: both;
		}
		a{
			display: block;
			padding:0 15px;
			margin-bottom: 30px;
			float: left;
			img{
				width: 100%;
			}
			width: 33.33333%;
			&:hover{
				img{
					@include opacity(0.4);
				}
			}
		}
	}
}


#popup-subscribe {
	.modal-dialog {
		max-width: 660px;
		z-index: 1060;
		.modal-header {
			border: none;
		}
		.modal-content {
			@include border-radius(6px);
			background: url(../img/newsletter.jpg);
			background-repeat: no-repeat;
			border: none;	
			min-width: 770px;
			max-height: 560px;
			.title_block {
				display: none;
				font-size: 2rem!important;
				color: $theme-color-primary;
				font-family: $font-family-default;
				font-weight: 900;
				@include rtl-padding-left(0);
				display: block;
				text-transform: uppercase;	
				margin-bottom: 11px !important;		
				text-align: center;	
			}
			p{
				font-family: $font-family-primary;
				font-weight: 700;
				font-size: 1.6rem;
				color: #212222;

			}
		}
		.modal-body {
			padding: 0;
		}
	}
	.subscribe_form {
		padding-top: 314px;
		padding-bottom: 37px;
		@include rtl-padding-lr(20px, 20px);
		color: #666;
		font-size: 1.4rem;
		font-weight: 400;
		text-align: center;
		.inner{
			h2{
				font-family: $font-family-default;
				font-size: 3.2rem;
				font-weight: 600;
				color: $theme-color-primary;
				margin-bottom: 16px;
			}
			p{
				font-family: $font-family-default;
				font-size: 1.4rem;
				font-weight: 400;
				color: #6e6e6e;
				margin-bottom: 44px;
			}
		}
		.input-subscribe-wrap {
			margin: 54px auto 0;
			max-width: 570px;
			width: 570px;
			position: relative;
			margin: 0 auto;
			height: 56px;
			input{
				height: 56px;
				@include rtl-padding-lr(20px, 75px);
				@include border-radius(5px !important);
				border: 1px solid #d7d7d7;
				@include placeholder {
					text-transform: initial;
					font-size: 1.3rem;
					color: #6e6e6e;
					@include rtl-text-align-left();
					font-weight: 400;
				}
			}
			.input-group-btn {
				position: absolute;
				@include rtl-right(0);
				height: 100%;
				z-index: 3;
				.btn {
					height: 56px;
					font-size: 0px;
					font-weight: 400;
					color: $white;
					text-transform: initial;
					background: transparent;
					min-width: 50px;
					border: 0;
					padding-left: 15px;
					padding-right: 15px;
					.icon-mail-button{
						display: inline-block;
						background-image: url(../img/icon-mail-button.png);
						background-repeat: no-repeat;
						background-position: center center;
						width: 26px;
						height: 20px;
					}
				}
			}
		}
		.checkbox {
			margin-top: 37px;
			> span {
				display: inline-block;
				vertical-align: middle;
				text-transform: initial;
				font-family: $font-family-primary;
				font-weight: 500;
				color: #505050;
				font-size: 1.3rem;
				line-height: 1;
			}
		}
	}
	.twitter {
		padding-top:23px;
		padding-bottom: 13px;
		iframe {
		    border-radius: 15px;
			overflow: hidden;
		}
		@-moz-document url-prefix() {
		    iframe {
		    	position: relative !important;
		    	width: 172px !important;
		    	height: 28px !important;	
		    	visibility: visible !important;
		    }
		}
	}
}

#msg_newsletter{
	.modal-content{
		background: transparent;
		.modal-body{
			padding: 0;
			background: transparent;
			>p{
				padding: 15px;
				text-align: center;
				margin-bottom: 0;
			}
		}
		.modal-header{
			.close{
				right: 8px;
				top: 0px;
				i{
					color: $gray-dark!important;
					font-size: 1.8rem;
				}
				&:hover{
					i{
						color: $theme-color-default;
					}
				}
			}
		}
	}
}

// Loock Book 

.nov-lookbook {
	.item {
		margin-bottom: 0;
		text-align: center;
	}
	.content-lookbook {
		.item-price {
			margin-top: 28px;
			font-weight: 900;
			font-size: 1.6rem;
			padding-bottom: 8px;
		}
		.item-title{
			>a{
				&:hover{
					color: $theme-color-default;
				}
			}
		}
	}
	.number-lookbook {
		&:hover {
			animation: Animationlookbook 0.5s ease-out infinite;
			background: #252525;
			&:after{
				content: "\f00d"!important;
				font-family: $font-icon;
				font-size: 2rem;
				color: $white;
			}
		}
	}
	.title-lb {
		font-size: 1.4rem;
		color: #444;
		font-weight: 700;
		text-transform: uppercase;
	}
	&.owl-theme{
		.owl-nav{
			.owl-prev{
				@include rtl-right(10px);
			}
			.owl-next{
				@include rtl-left(10px);
			}
		}
	}
	&.owl-theme .owl-dots {
		top: inherit;
		bottom: 22px;
		@include rtl-left(50%);
		@include translateX(-50%);
		.owl-dot {
			span {
				@include square(20px);
				@include rtl-margin-right(18px);
				&:before {
					background: #afacae;
					@include square(20px);
				}
			}
			&:last-child{
				span{
					@include rtl-margin-right(0);
				}
			}
			&:hover, &.active {
				span {
					background: transparent;
					&:before {
						background: $theme-color-primary;
					}
				}
			}
		}
	}
	.owl-nav {
		margin: 0;
	}
	@media (max-width: 1800px) and (min-width: 1601px) {
		.nov-content-lookbook{
			.item-lookbook{
				.content-lookbook{
					width: 240px;
					.item-thumb{
						@include rtl-margin-lr(50px, 50px);
					}
				}
			}
		}
	}
	@media (max-width: 1600px) and (min-width: 1441px){
		.nov-content-lookbook{
			.item-lookbook{
				.content-lookbook{
					width: 200px;
					.item-thumb{
						@include rtl-margin-lr(50px, 50px);
					}
				}
			}
		}
	}
	@media (max-width: 1440px) and (min-width: 768px){
		.nov-content-lookbook{
			.item-lookbook{
				.content-lookbook{
					width: 150px;
					.item-thumb{
						@include rtl-margin-lr(40px, 40px);
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		.nov-content-lookbook{
			.img-responsive{
				padding-bottom: 25px;
			}
		}
		.item-lookbook {
			position: static;
			display: flex;
			padding-top: 0;
			padding-bottom: 25px;
			.number-lookbook {
				@include rtl-margin-right(15px);
				display: none;
			}
			.content-lookbook {
				position: static;
				display: flex;
				left: 0!important;
				transform: translateX(0)!important;
				margin: 0 auto;
				background: #f8f8f8;
			}
		}
		.owl-dots{
			display: none;
		}
	}
	@media (max-width: 425px){
		.item-lookbook{
			width: 50%;
			float: left;
			padding-left: 5px;
			padding-right: 5px;
			.content-lookbook{
				.item-thumb{
					@include rtl-margin-lr(30px,30px);
				}
			}
		}
	}
}

// Social Login
.block-sociallogin {
	margin-bottom: 30px;
	.title_sociallogin {
		margin-bottom: 15px;
		font-size: 1.3rem;
	}
	p{
		font-size: 1.3rem;
	}
	a {
		text-align: center;
		padding-top: 0;
		padding-bottom: 0;
		height: 46px;
		line-height: 46px;
		border-radius: 3px;
		font-size: 1.2rem;
		color: #fff !important;
		font-weight: bold;
		text-transform: capitalize;
		&:not(:last-child) {
			@include rtl-margin-right(7px);
		}
		&.btn-sociallogin-facebook {
			background: #39599f;
			&:hover {
				background: darken(#39599f, 10%);
			}
		}
		&.btn-sociallogin-twitter {
			background: #45b0e3;
			&:hover {
				background: darken(#45b0e3, 10%);
			}
		}
		&.btn-sociallogin-google {
			background: #dd3f1b;
			&:hover {
				background: darken(#dd3f1b, 10%);
			}
		}
	}
	.content_sociallogin {
		margin-top: 0;
		margin-bottom: 0;
		.col {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 5px;
			padding-right: 5px;
		}
		@media (max-width: 425px){
			.col{	
				a{
					margin-bottom: 10px;
				}
			}
		}
	}
}

// WishlistModal
#WishlistModal {
	.modal-dialog {
		max-width: 420px;
	}
	.modal-header {
		border-bottom: 1px solid #e6e6e6;
		position: relative;
		padding: 10px 20px;
		h4 {
			i {
				@include square(17px);
				background: #43ac3b;
				border-radius: 50%;
				text-align: center;
				line-height: 17px;
				color: #fff;
				font-size: 13px;
				@include rtl-margin-right(10px);
			}
			font-size: 1.2rem;
			font-weight: bold;
			color: $text-color-title;
			margin-bottom: 0;
		}
		button {
			background: transparent;
			position: absolute;
			top: 50%;
			@include rtl-right(12px);
			@include translateY(-50%);
			i {
				color: $gray-dark;
				font-size: 20px;
			}
		}
	}
	.modal-body {
		padding: 20px;
		img {
			max-width: 64px;
			border: 1px solid #e6e6e6;
			border-radius: 3px;
			@include rtl-margin-right(20px);
		}
		h5 {
			font-weight: 500;
			font-size: 1.1rem;
			margin-bottom: 9px;
		}
		a {
			display: inline-block;
			padding: 0 15px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			border-radius: 3px;
			background: #454545;
			color: #fff;
			font-size: 1.1rem;
			font-weight: bold;
			&:hover {
				background: $theme-color-default;
			}
			&:not(:last-child) {
				@include rtl-margin-right(5px);
			}
		}
	}
}

@keyframes Animationlookbook {
    from {box-shadow: 0 0 0 0 #fff;}
    to {box-shadow: 0 0 0 5px rgba(#fff, 0.3);}
}

/* Hien thi cho Firefox */
@-moz-keyframes Animationlookbook {
    from {box-shadow: 0 0 0 0 #fff;}
    to {box-shadow: 0 0 0 5px #fff;}
}

/* Hien thi cho Safari and Chrome */
@-webkit-keyframes Animationlookbook {
   from {box-shadow: 0 0 0 0 #fff;}
   to {box-shadow: 0 0 0 5px rgba(#fff, 0.3);}
}