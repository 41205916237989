.title_block {
  font-family: $font-family-primary;
  font-weight: 600;
  font-size: 3.2rem;
  color: $theme-color-primary;
  margin-bottom: 60px;
  line-height: 0.8;
  text-transform: capitalize;
  text-align: center;
  .sub_title{
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    color: #505050;
    margin-bottom: 2.3rem;
    text-transform: initial;
  }
}

@mixin title_block_1(){
  .title_block{
    position: relative;
    display: block;
    padding-bottom: 0px;
    line-height: 18px;
    margin-bottom: 35px;
    text-align: center;
    font-family: $font-family-default;
    span{
      font-family: $font-family-default;
      font-size: 32px;
      font-weight: 600;
      position: relative;
      display: inline-block;
      line-height: 24px;
      @include rtl-padding-lr(40px, 40px);
      &:before, &:after{
        content: "";
        position: absolute;
        top: 50%;
        @include translateY(-50%);
        width: 74px;
        height: 10px;
      }
      &:before{
        @include rtl-right(100%);
        background-image: url('../img/icon-title.png');
        background-repeat: no-repeat;
        background-position: 0 -3px;
      }
      &:after{
        @include rtl-left(100%);
        background-image: url('../img/icon-title.png');
        background-repeat: no-repeat;
        background-position: -86px -3px;
      }
    }
  }
}
.block_content {
  .has-border-top &{
    border-top: 1px solid $base-border-color;
  }
  .has-border-bottom &{
    border-bottom: 1px solid $base-border-color;
  }

}

.groupbanner-special {
  .title_block {
    position: absolute;
    background: #fff;
    z-index: 1;
    @include rtl-left(50%);
    top: 50%;
    width: 310px;
    height: 310px;
    @include translate(-50%,-50%);
    border-radius: 50%;
    margin-bottom: 0;
    > span:not(.sub_title) {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -25px;
    }
    span.sub_title {
        position: absolute;
        top: 50%;
        margin-top: -45px;
    }
    span span {
      display: block;
      line-height: 1.4;
      font-size: 12px;
      font-weight: 400;
      text-transform: initial;
      width: 70%;
      margin: 0 auto;
      margin-top: 5px;
    }
  }
}


// Reassurance -------------------------------------------------------------------------------
#block-reassurance{
  margin-top: 30px;
  margin-bottom: 0;
  ul {
    padding: 0;
    list-style: none;
  }
  
  li{
    border: 1px solid #e9e9e9;
    padding: 8px 13px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    .block-reassurance-item {
      img {
        @include rtl-margin-right(10px);
      }
      .text {
        @include rtl-margin-left(15px);
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }
  #product &{
    span{
      font-size: 1.4rem;
    }
  }
}
.block-opening-hours {
  ul li {
    font-size: 11px;
  }
}

.block-contact {
  .data {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    b {text-transform: uppercase;}
    div {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 0%;
      -ms-flex: 1 1 0%;
      flex: 1 1 0%;
    }
  }
  .logo-footer {
    margin-bottom: 30px;
  }
}

.infostore-inline {
  ul li {
    font-size: 12px;
    font-family: $font-family-primary;
    &.list-inline-item:not(:last-child) {
      @include rtl-margin-right(30px);
    }
    a {
      color: #666;
      &:hover {
        color: $theme-color-default;
      }
    }
    i {
      @include rtl-float-left();
      @include rtl-margin-right(9px);
      font-size: 17px;
      color: $theme-color-default;
    }
  }
}

//Advanced Search
#block_advancedsearch {
  padding-left: 0;
}
.ac_nov_results {
  z-index: 99999;
  background: #fff;
  @include box-shadow(0 0 10px 0 rgba(0,0,0,0.15));
  min-width: 300px;
  padding: 15px 0;
  ul {
    margin: 0;
    max-height: 500px;
    overflow-y: scroll;
    padding: 0;
    &::-webkit-scrollbar {
      width: 0.7rem;
    }
    &::-webkit-scrollbar-track {
      background: #eaeaea;
      @include border-radius(0.4rem);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #222;
      @include border-radius(0.4rem);
    }
    li {
      padding: 0 30px;
      list-style: none;
      img {border: 1px solid $base-border-color;width: 60px; height: auto;@include rtl-margin-right(15px);}
      a{
        font-weight: 500;
        font-size: 1.1rem;
        color: $gray-dark;
      }
    }

  }
  ul {
    list-style-type: none;
    padding: 0;
    &.has-scroll {
      max-height: 500px;
      overflow-y: scroll;
    }
    li {
      padding: 0 30px;
      .media {
        padding: 10px 0;
      }
      &:not(:last-child) .media {
        border-bottom: 1px solid #e9e9e9;
      }
      img {
        max-width: 65px;
        @include rtl-margin-right(20px);
        border-radius: 3px;
        border: 1px solid #e9e9e9;
      }
      a {
        font-weight: 500;
        font-size: 1.1rem;
      }
      .media{
        .media-body{
          .search-price{
            color: $theme-color-default;
            font-weight: 700;
            font-size: 1.3rem;
            margin-top: 10px;
          }
        }
      }
    }
  }
}


#_desktop_search_content {
  @include rtl-padding-right(13px);
  .nov_category_tree {
    @include rtl-padding-right(10px);
    background: #fff;
    @include rtl-border-radius-topleft(5px);
    @include rtl-border-radius-bottomleft(5px);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      @include rtl-right(11px);
      top: calc(50% + 1px);
      @include translateY(-50%);
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 4px solid #d7d7d7;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    >.dropdown-menu{

      &::-webkit-scrollbar {
        width: 0.7rem;
      }
      &::-webkit-scrollbar-track {
        background: #eaeaea;
        @include border-radius(0.4rem);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #222;
        @include border-radius(0.4rem);
      }
    }
    .dropdown-toggle {
      font-size: 1.3rem;
      color: #d7d7d7;
      font-weight: 500;
      text-transform: capitalize;
      border: none;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      padding: 0;
      margin-left: 13px;
      margin-right: 15px;
      min-width: 68px;
      @include rtl-text-align-left();
      background: #fff;
      line-height: 1;
      &:after{
        display: none;
      }
      @include rtl-border-radius-topleft(5px);
      @include rtl-border-radius-bottomleft(5px);
      position: relative;display: inline-block;
      ul{
        margin-bottom: 0;
      }
    }

    .dropdown-menu {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.3rem;
      max-height: 500px;
      overflow-y: scroll;
      .dropdown-item {
        cursor: pointer;
        padding: 6px 19px;
        font-size: 1.1rem;
        span{
          display: block;
        }
        &:hover {
          color: $theme-color-default;
        }
      }
    }
    @media (max-width: $screen-md-max){
      max-width: 110px;
      .dropdown-toggle{
        overflow-x: auto;
      }
    }
  }
  .search_query {
    background: #fff;
    border-color: #fff;
    border: none;
    @include rtl-border-left(1px solid $base-border-color);
    min-width: 245px;
    &:hover, &:focus {
      border-color: #fff;
      @include rtl-border-left(1px solid $base-border-color);
      background: #fff;
      @include box-shadow(none);
      outline: none;
    }
    @include placeholder {
      font-size: 1.3rem;
      color: #d7d7d7;
    }
  }
  .btn.btn-secondary {
    background-color: $theme-color-default;
    border: none;
    padding: 10px 23px;
    @include rtl-border-radius-topright(5px);
    @include rtl-border-radius-bottomright(5px);
    @include rtl-margin-lr(0,0);
    @include flexbox();
    @include align-items(center);
    text-transform: initial;
    font-size: 1.3rem;
    font-weight: 500;

    .icon-novsearch{
      width: 16px;
      height: 16px;
      background-image: url(../img/icon/icon-novsearch.png);
      @include rtl-margin-lr(0,5px);
      display: inline-block;
    }
    &:hover {
      background-color: lighten($theme-color-default, 10%);
      border-color: lighten($theme-color-default, 10%);
    }
  }
}
 
//Block newsletter -------------------------------------------------------------------------------
.block_newsletter{
  .input-group{
    position: relative;
    max-width: 620px;
    margin: 0 auto;
    height: 56px;
    input{
      height: 56px;
      @include rtl-padding-lr(20px, 75px);
      @include border-radius(5px !important);
      border: none;
      @include placeholder {
        text-transform: initial;
        font-size: 1.3rem;
        color: #6e6e6e;
        @include rtl-text-align-left();
        font-weight: 400;
      }
    }
    .input-group-btn {
      position: absolute;
      @include rtl-right(0);
      height: 100%;
      z-index: 3;
      .btn {
        height: 56px;
        font-size: 0px;
        font-weight: 400;
        color: $white;
        text-transform: initial;
        background: transparent;
        min-width: 50px;
        border: 0;
        padding-left: 15px;
        padding-right: 15px;
        .icon-mail-button{
          display: inline-block;
          background-image: url(../img/icon-mail-button.png);
          background-repeat: no-repeat;
          background-position: center center;
          width: 26px;
          height: 20px;
        }
      }
    }
  }
  .image-payment{
    margin-top: 35px;
  }
}

.newsletter-inline {
  .block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .title_block {
    font-size: 1.8rem;
    margin-bottom: 0 !important;
    @include rtl-margin-right(33px);
  }
  .block_newsletter {
    p {display: none;}
    .input-group {
      @include border-radius(23px);
      @include placeholder {
        font-size: 1.1rem;
        color: #a3a3a3;
        text-transform: uppercase;
      }
      &:after {
        content: '\f15a';
        display: inline-block;
        font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
        font-size: 16px;
        color: #a3a3a3;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: 10px;
        top: 14px;
        width: 20px;
        z-index: 2;
      }
      .input-group-btn {
        display: none;
      }
    }
  }
}
//Footer-icon
.icon-contact{
  background: $text-color-title;
  @include square(33px);
  max-width: 33px;
  min-width: 33px;
  text-align: center;
  line-height: 33px;
  @include rtl-margin-right(17px);
  @include border-radius(50%);
  cursor: pointer;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include transition(all 0.35s ease);
  &:hover{
    background: $theme-color-default;
  }
  .home{
    background-position: -1px -25px ;
    background-image: url(../img/icon/icon-contacts.png);
    background-repeat: no-repeat;
    font-size: 0;
    @include size(14px, 13px);
    display: inline-block;
  }
  .phone{
    background-position: -2px -11px ;
    background-image: url(../img/icon/icon-contacts.png);
    background-repeat: no-repeat;
    font-size: 0;
    @include size(12px, 12px);
  }
  .email{
    background-position: -2px 0px;
    background-image: url(../img/icon/icon-contacts.png);
    background-repeat: no-repeat;
    font-size: 0;
    @include size(13px, 9px);
  }
}
//Policy -------------------------------------------------------------------------------
.policy-main-row{
  .policy-items{
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    padding-top: 35px;
    padding-bottom: 45px;
    position: relative;
    &:last-child{
      &:after{
        display: none;
      }
    }
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      @include rtl-right(0px);
      @include translateY(-50%);
      width: 1px;
      height: 20px;
      background: #d7d7d7;
    }
    .policy-icon{
      @include rtl-padding-lr(20px,0);
      .noviconpolicy{
        font-size: 0;
        display: inline-block;
        margin-bottom: 6px;
      }
    }
    .policy-content{
      @include rtl-padding-lr(20px,0);
      .policy-title{
        font-size: 2rem;
        font-weight: 600;
        color: $theme-color-primary;
        padding-bottom: 3px;
      }
      .policy-text{
        font-size: 14px;
        font-weight: 400;
        color: $theme-color-primary;
        line-height: 1;
      }
    }
  }
  @media (max-width: $screen-sm-max) and (min-width: $screen-sm){
    .policy-items{
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      .policy-icon{
        padding: 0;
        margin-bottom: 10px;
      }
      .policy-content{
        text-align: center;
        padding: 0 10px;
        .policy-title{
          font-size: 1.8rem;
          padding-bottom: 10px;
        }
        .policy-text{
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: $screen-xs-max){
    .policy-items{
      padding-bottom: 0;
      &:last-child{
        padding-bottom: 45px;
      }
      &:after{
        display: none;
      }
    }
  }
}
.policy-main{
  @include border-radius(5px);
  border: 1px solid $theme-color-default;
  @include rtl-padding-lr(20px, 20px);
  .policy-items{
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    padding-top: 35px;
    padding-bottom: 45px;
    border-bottom: 1px solid #f2f2f2;
    .policy-icon{
      .noviconpolicy{
        font-size: 0;
        display: inline-block;
        margin-bottom: 6px;
      }
    }
    .policy-content{
      @include rtl-padding-lr(20px,0);
      .policy-title{
        font-size: 20px;
        font-weight: 600;
        color: $theme-color-primary;
        padding-bottom: 3px;
      }
      .policy-text{
        font-size: 14px;
        font-weight: 400;
        color: $theme-color-primary;
        line-height: 1;
      }
    }
  }
}
.policy-hexagon{
  .policy-image{
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include hexagon-image();
    &:hover{
      .noviconpolicy-1 {
        background-position: -50px 0px;
      }
      .noviconpolicy-2 {
        background-position: -50px -46px;
      }
      .noviconpolicy-3 {
        background-position: -50px -93px;
      }
    }
    .noviconpolicy{
      z-index: 3;
    }
  }
  .policy-name{
    margin-top: 40px;
    .policy-title{
      font-family: $font-family-default;
      font-size: 20px;
      font-weight: 600;
      color: $theme-color-primary;
      margin-bottom: 23px;
    }
    .text-content-policy{
      font-family: $font-family-default;
      font-size: 14px;
      font-weight: 400;
      color: $theme-color-primary;
      line-height: 24px;
    }
  }
  .cardinal{
    font-family: $font-family-default;
    font-size: 6rem;
    color: rgba(34,34,34,0.5);
    line-height: 1;
    position: relative;
    margin-top: 62px;
    &.cardinal-special{
      margin-top: 0;
      margin-bottom: 92px;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 9px;
      @include rtl-left(50%);
      @include translateX(-50%);
      height: 1px;
      width: 170px;
      background-color: rgba(34,34,34,0.5);
    }
  }
  @media (max-width: $screen-sm-max) and (min-width: $screen-sm){
    .policy-content{
      .cardinal{
        &:after{
          width: 80px;
        }
      }
    }
  }
  @media (max-width: $screen-xs-max){
    padding-top: 30px;
    .policy-content{
      .cardinal-special{
        margin-bottom: 62px;
      }
      .cardinal{
        &:after{
          @include rtl-left(70px);
          @include translateX(0);
          width: 100px;
        }
      }
    }
  }
}
//Tabs policy -----------------------------------------------------------------------------
.tabs-policy {
  .tabs {
    margin-top: 0;
    .nav-tabs {
      .nav-item {
        &:last-child {
          .nav-link {
            @include rtl-border-right(none);
          }
        }
      }
      .nav-link {
        @include rtl-margin-right(0);
        background: #b4cbd6;
        @include border-radius(0);
        height: 60px;
        line-height: 55px;
        @include rtl-border-right(1px solid #e5eff4);
        &.active {
          background: #fff;
          @include rtl-border-right(none);
        }
      }
    }
    .tab-pane {
      padding-left: 19px;
      padding-right: 19px;
      padding-top: 2.8rem;
      min-height: 129px;
      h4 {
        text-transform: uppercase;
        font-family: $font-family-primary;
        color: #222;
        font-weight: 700;
        font-size: 1.4rem;
        margin-bottom: 15px;
      }
    }
  }
}
//deal-week -----------------------------------------------------------------------------
.deal-week {
  padding-bottom: 15px;
  padding-top: 15px;
  .nov-image {
    z-index: 2;
  }
  .product_list .owl-item {
    .item {
      position: relative;
    }
  }
  .product_list.grid .product-miniature {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    .product-group-price {
        margin-bottom: 29px;
    }
    .product-price-and-shipping .price {
        font-size: 1.8rem;
    }
  }
  .countdownfree {
    margin-bottom: 12px;
  }
  .title_block {
    color: #f03737;
    margin-bottom: 4rem;
    padding-top: 17px;
  }
  .owl-theme .owl-nav [class*="owl-"] {
    background: lighten($theme-color-default, 40%);
    &:hover {
       background: $theme-color-default;
    }
  }
}

// Step Buy -----------------------------------------------------------------------------
.step-buy {
  @include rtl-padding-left(35px);
  background: #e8f2f7;
  font-size: 1.2rem;
  overflow: hidden;
  .stepbuy--title {
    font-family: $font-family-primary;
    color: #222;
    font-size: 2.6rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .step {
    @include rtl-padding-left(80px);
    background-repeat: no-repeat;
    background-position: center left;
    margin-bottom: 40px;
    position: relative;
    &:before {
      content: "";
      width: 4px;
      height: 17px;
      background: #a5cadc;
      position: absolute;
      top: -20px;
      @include rtl-left(20px);
    }
    &:after {
      content: "";
      width: 4px;
      height: 17px;
      background: #a5cadc;
      position: absolute;
      top: -41px;
      @include rtl-left(20px);
    }
    &.step-1 {
      background-image: url(../img/icon/step-1.png);
      &:before, &:after {display: none;}
    }
    &.step-2 {
      background-image: url(../img/icon/step-2.png);
    }
    &.step-3 {
      background-image: url(../img/icon/step-3.png);
    }
    &.step-4 {
      background-image: url(../img/icon/step-4.png);
    }
  }
  .h3 {
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 13px;
    margin-top: 5px;
  }
}


//Vertical Menu -----------------------------------------------------------------------------
.nov-verticalmenu {
  
}

// Tabs-subcat -------------------------------------------------------------------------------
.tabs-subcat {
  .nav-tabs {
    background: $theme-color-primary;
    padding-top: 6px;
    padding-bottom: 1px;
    border: none;
    .nav-item {
      margin-bottom: 0;
      .nav-link {
        border: none;
        background: $theme-color-primary;
        color: #fff;
        padding: 15px 37px;
        font-size: 22px;
        font-family: $font-family-primary;
        text-transform: uppercase;
        @include border-radius(0);
        font-weight: 700;
        line-height: 1;
        &.active {
          background: #fff;
          color: #d6d6d6;
        }
      }
    }
  }
  .tab-pane {
    padding-top: 40px;
    .children-item {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dashed #a7a7a7;
      &.last {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

//Block-bestsellers --------------------------------------------------------------------------
.block-bestsellers {
  .item {
    &:before, &:after {
      content: '';
      display: table;
    }
    margin-bottom: 27px;
  }
  .product-miniature {
    overflow: hidden;
    .thumbnail-container {
      width: 98px;
      border: 1px solid #e6e6e6;
      @include rtl-float-left();
      margin-top: 3px;
      &:hover {
        border-color: $theme-color-default;
      }
    }
    .product-description {
      @include rtl-padding-left(127px);
    }
    .product-title {
      margin-bottom: 5px;
      a {
        font-size: 1.4rem;
        font-family: $font-family-primary;
        font-weight: 300;
        color: #444;
        &:hover {
          color: $theme-color-default;
        }
      }
    }
    .product-comments {
      margin-bottom: 5px;
    }
    .price {
      font-size: 1.8rem;
      font-weight: 700;
      color: $theme-color-default;
      font-family: $font-family-primary;
    }
  }
}
 
//Blocks in side column -----------------------------------------------------------------------
.sidebar {
  margin-bottom: 8rem;
  .sidebar-recentpost{
    .nov-wrap{
      @include border-radius(5px);
      border: 1px solid $theme-color-default;
      .title_block{
        font-size: 2rem;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        color: $theme-color-primary;
        padding: 18px;
        background: $theme-color-default;
      }
      .block-recentpost{
        @include rtl-padding-lr(20px, 20px);
      }
    }
  }
  .block {
    padding: 0;
    margin-bottom: 2.8rem;
    .title_block {
      font-family: $font-family-primary;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 22px;
      font-size: 24px;
      color: $theme-color-primary;
      text-transform: uppercase;
      padding-top: 0;
      padding-bottom: 17px;
      span{
        padding-bottom: 12px;
      }
    }
  }
  .nov-productlist{
    padding-top: 0!important;
    .block-product{
      margin-bottom: 50px !important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      border: 1px solid $theme-color-default;
      @include border-radius(5px);
    }
    .title_block{
      text-align: center;
      padding: 18px;
      background: $theme-color-default;
      span{
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
        color: $theme-color-primary;
      }
    }
    .block_content{
      margin: 0;
      .product_list{
        padding: 0;
        padding-bottom: 70px;
        overflow: hidden;
        .product-miniature{
          border: none;
          padding: 0;
          &:before{
            display: none;
          }
          &.first_item{
            margin-top: 8px;
          }
          &:hover{
            @include box-shadow(0);
            .product-description{
              .group-price{
                @include opacity(1);
                @include translateY(0);
              }
            }
          }
          margin-top: 10px;
          .thumbnail-container{
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 130px;
            -ms-flex: 0 0 130px;
            flex: 0 0 130px;
            max-width: 130px;
            width: auto;
            height: auto;
            @include rtl-padding-lr(10px, 20px);
            @include rtl-margin-right(0);
            a{
              img{
                @include border-radius(5px);
              }
            }
            .group-buttons-one{
              display: none;
            }
          }
          .product-description{
              padding: 0;
            .product-comments{
              display: none;
            }
            .product-title{
              padding-top: 0;
              padding-bottom: 16px;
              @include rtl-padding-lr(0, 10px);
              >a{
                white-space: initial;
                font-size: 15px;
                font-weight: 600;
                color: $theme-color-primary;
                line-height: 24px;
              }
            }
            .group-price{
              margin-bottom: 0!important;
              .product-group-price{
                padding-top: 0;
              }
            }
            .group-buttons{
              display: none!important;
            }
          }
        }
        .owl-dots{
          top: calc(100% - 40px);
          @include rtl-left(50%);
          @include translateX(-50%);
          .owl-dot{
            span{
              background: $theme-color-primary;
              @include border-radius(3px);
            }
            &:hover, &.active{
              span{
                background: $theme-color-default;
              }
            }
          }
        }
      }
    }
    .product_list{
      @media (max-width: 1199px) and (min-width: 768px){
        .product-miniature{
          >div{
            @include flex-direction(column);
            @include align-items(flex-start!important);
          }
          .thumbnail-container, .product-description{
            -webkit-box-flex: 0 0 100% !important;
            -moz-box-flex: 0 0 100% !important;
            -webkit-flex: 0 0 100% !important;
            -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding-left: 15px!important;
            padding-right: 15px!important;
          }
          .thumb-container{
            padding-left: 15px!important;
            padding-right: 15px!important;
          }
          .product-description{
            padding-left: 15px!important;
            padding-right: 15px!important;
            padding-top: 10px !important;
            padding-bottom: 20px!important;
          }
          &:last-child{
            .product-description{
              padding-bottom: 0!important;
            }
          }
        }
      }
      @media (max-width: 767px){
        .product-description{
          width: calc(100% - 140px);
          max-width: calc(100% - 140px);
        } 
      }
      @media (max-width: 575px){
        .product-miniature{
          >div{
            @include flex-direction(column);
            @include align-items(flex-start!important);
          }
          .thumbnail-container, .product-description{
            -webkit-box-flex: 0 0 100% !important;
            -moz-box-flex: 0 0 100% !important;
            -webkit-flex: 0 0 100% !important;
            -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding-left: 15px!important;
            padding-right: 15px!important;
            width: 100%;
          }
          .thumb-container{
            padding-left: 15px!important;
            padding-right: 15px!important;
          }
          .product-description{
            padding-left: 15px!important;
            padding-right: 15px!important;
            padding-top: 10px !important;
            padding-bottom: 20px!important;
            .product-title{
              @include rtl-padding-lr(0,0);
            }
          }
          &:last-child{
            .product-description{
              padding-bottom: 0!important;
            }
          }
        }
      }
    }
  }
  > div {
    &:last-child {
      .block {
        margin-bottom: 0;
      }
    }
  }
}

//Welcome inline
.welcome-inline {
  font-size: 1.1rem;
  font-weight: 700;
  a {
    color: $theme-color-default;
  }
}

//Infomation top
.infomation {
  font-size: 1.2rem;
  font-family: $font-family-primary;
  h3 {
    font-size: 1.2rem;
    color: #444;
    text-transform: uppercase;
  }
  line-height: 1;
}

// Empty with bootstrap 4
.collapse.in {
    display: block;
}
.modal.in .modal-dialog {
  @include translate(0, -50%);
  margin-top: 50vh;
  .modal-content {
    @include box-shadow(0px 0px 10px rgba(#000,0.35));
  }
}

@media (min-width: 768px) {
  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (2.5rem * 2));
  }
}