// Turn off the bullet for an element of a list
@mixin no-bullet {
  list-style-image : none;
  list-style-type  : none;
  margin-left      : 0;
}

// turns off the bullets for an entire list
@mixin no-bullets {
  list-style: none;
  li { @include no-bullet; }
}

// Make a list(ul/ol) have an image bullet.
//
// The mixin should be used like this for an icon that is 5x7:
//
//     ul.pretty
//       +pretty-bullets("my-icon.png", 5px, 7px)
//
// Additionally, if the image dimensions are not provided,
// The image dimensions will be extracted from the image itself.
//
//     ul.pretty
//       +pretty-bullets("my-icon.png")
//
@mixin pretty-bullets($bullet-icon, $width: image-width($bullet-icon), $height: image-height($bullet-icon), $line-height: 18px, $padding: 14px) {
  margin-left: 0;
  li {
    padding-left: $padding;
    background: image-url($bullet-icon) no-repeat ($padding - $width) / 2 ($line-height - $height) / 2;
    list-style-type: none;
  }
}
