//Global
.nov-blog{
	padding-top: 164px;
	padding-bottom: 121px;
}
.novblog-box-content {
	.post-item {
		font-size: 1.3rem;
		font-family: $font-family-default;
		color: $text-color;
	}
	.post-image{
		>a{
			display: block;
			overflow: hidden;
			@include border-radius(5px);
		}
		img{
			@include transition(all 0.35s ease-in-out);
		}
		&:hover{
			img{
				@include scale(1.05,1.05);
			}
		}
	}
	.post-info{
		padding-top: 20px;
		.group-date{
			padding-bottom: 20px;
			border-bottom: 1px solid #f2f2f2;
			.post-date{
				@include rtl-margin-lr(0, 40px);
				@include flexbox();
				.nov-icondate{
				@include size(19px, 19px);
				display: inline-block;
				background-image: url('../img/icon-novblog.png');
				background-repeat: no-repeat;
				background-position: 0 0;
				@include rtl-margin-lr(0, 5px);
				}
				font-size: 13px;
				font-weight: 500;
				color: #6e6e6e;
			}
			.post-author{
				@include flexbox();
				@include align-items(center);
				font-size: 13px;
				.nov-icon-author{
					@include size(15px, 19px);
					display: inline-block;
					background-image: url('../img/icon-novblog.png');
					background-repeat: no-repeat;
					background-position: -32px 0;
					@include rtl-margin-lr(0, 5px);
				}
			}
			.readmore{
				a{
					font-size: 13px;
					font-weight: 600;
					color: #6e6e6e;
					border-bottom: 1px solid transparent;
					@include transition(all 0.35s ease);
					&:hover{
						color: $theme-color-primary;
						border-bottom: 1px solid $theme-color-default;
					}
				}
			}
		}
		.post_title {
			padding-top: 18px;
			a {
				font-size: 20px;
				font-weight: 700;
				color: $theme-color-primary;
				line-height: 1.6;
				display: inline-block;
				@include transition(all 0.35s ease);
				&:hover {
					color: $theme-color-default;
				}
			}
		}
		
	}
	@media (max-width: $screen-sm-max){
		.post-info{
			.group-date{
				@include justify-content(flex-start !important);
				@include flex-wrap(wrap);
				.date-content{
					width: 100%;
					@include justify-content(space-between !important);
				}
				.post-date{
					@include rtl-margin-lr(0, 10px);
				}
			}
			.readmore{
				margin-top: 15px;
			}
		}
	}
}

// block-recentpost
.block-recentpost {
	font-size: 13px;
	font-family: $font-family-primary;
	padding-bottom: 24px!important;
	.post-item {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #e0e0e0;
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		.post-title {
			display: block;
			color: $text-color-title;
			font-weight: 700;
			margin-bottom: 12px;
			font-size: 16px;
			font-family: $font-family-default;
			text-transform: capitalize;
			text-decoration: none;
			&:hover{
				color: $theme-color-default;
			}
		}
		.post-description {
			padding-top: 3px;
			padding-bottom: 10px;
			line-height: 18px;
			font-size: 12px;
			color: $text-color;
		}
		.post-info {
			font-size: 12px;
			color: $text-color-custom;
			margin-bottom: 9px;
			text-transform: uppercase;
			.comment{
				@include rtl-padding-right(11px);
				display: inline-block;
				margin-bottom: 5px;
				&:before{
					content:"";
				}
				a{
					color: $text-color-custom;
					&:hover{
						color: $theme-color-default;
					}
				}
				i{
					display: inline-block;
					@include rtl-margin-right(5px);
				}
			}
			.datetime{
				@include rtl-padding-left(10px);
				margin-bottom: 5px;
				i{
					display: inline-block;
					@include rtl-margin-right(5px);
				}
			}
		}
		.readmore a {
			font-weight: 500;
			color: $theme-color-default;
			text-transform: uppercase;
			@include transition(all 0.35s ease);
			font-size: 12px;
			text-decoration: none;
			&:hover{
				color: $theme-color-primary;
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	#module-smartblog-category .blogwapper {
		&.has-sidebar-left {
			@include rtl-padding-left(8.045%);
		}
		&.has-sidebar-right {
			@include rtl-padding-right(8.045%);
		}
	}
}