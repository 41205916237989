.block-product{
	.block_content{
		margin: -20px;
		.product_list{
			padding: 20px;
			.owl-stage-outer{
				overflow: initial;
			}
		}
	}
}
.qty {
    display: inline-block;
    vertical-align: top;
    .input-group-btn-vertical {
	    float: none;
	    z-index: 1;
	    @include flexbox();
	    @include align-items(center);
	    @include flex-direction(row-reverse);
	    height: 40px;
	    .btn {
	      padding: 0;
	      @include square(17px);
	      line-height: 17px;
	      background: transparent;
	      i {
	        color: $theme-color-primary;
	        font-size: 24px;
	        font-family: $font-family-default;
	        display: block;
	        font-style: inherit;
	        @include rtl-margin-lr(0,0);
	        &.touchspin-up {
	          &:before{
	            content: '+';
	          }
	        }
	        &.touchspin-down {
	          &:before{
	            content: '-';
	          }
	        }
	      }
	      &.bootstrap-touchspin-down {
	        
	      }
	    }
	}
}
#quantity_wanted {
	color: $theme-color-primary;
    background-color: white;
    height: 40px;
    width: 130px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid #f2f2f2;
    @include border-radius(3px);
    z-index: 1;
    text-align: center;
}
//Countdown
.countdownfree {
	text-align: center;
	font-family: $font-family-default;
	position: absolute;
	top: 50%;
	@include translateY(-50%);
	@include rtl-left(-30px);
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(flex-start);
	@include flex-direction(column);
	.item-time {
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		@include flex-direction(column);
		position: relative;
		font-family: $font-family-default;
		width: 60px;
		@include transition(all 0.35s ease);
		background: $theme-color-default;
		margin-top: 17px;
		margin-bottom: 17px;
		&:before {
			content: "";
			position: absolute;
			top: -17px;
			left: 0;
			width: 0;
			height: 0;
			border-left: 30px solid transparent;
			border-right: 30px solid transparent;
			border-bottom: 17px solid $theme-color-default;
		}
		&:after {
			content: "";
			position: absolute;
			bottom: -17px;
			left: 0;
			width: 0;
			height: 0;
			border-left: 30px solid transparent;
			border-right: 30px solid transparent;
			border-top: 17px solid $theme-color-default;
		}
		
	}
	.name-time {
		display: block;
		font-size: 11px;
		color: $theme-color-primary;
		line-height: 1;
		font-weight: 400;
		margin-top: 3px;
		margin-bottom: 3px;
		text-transform: capitalize;
	}
	.data-time {
		color: $theme-color-primary;
		font-size: 20px;
		font-weight: 900;
		line-height: 1;
	}

}

.product-title {
	font-size: 13px;
	line-height: 1;
	a {
		font-family: $font-family-primary;
		color: $text-color-title;
		font-weight: 500;
		line-height: 1;
		&:hover {
			color: $theme-color-default;
			text-decoration: none;
		}
	}
}
.product-flags {
	position: absolute;
	top: 10px;
	@include size(60px, 30px);
	@include border-radius(3px);
	color: $theme-color-primary;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	font-weight: 600;
	&.new {
		@include rtl-right(10px);
		background: #343434;
	}
	&.on-sale {
		@include rtl-left(10px);
		background: #343434;
	}
	&.discount {
		@include rtl-right(10px);
		background: #f1c14d;
	}
	@media (max-width: $screen-xs-max){
		display: none;
	}
}

.product-info {
	position: absolute;
	@include opacity(0);
	width: 100%;
	top: 40%;
	left: 0;
	padding: 20px;
	@include translateY(-50%);
	z-index: 1;
	@include transition(all 300ms ease-in-out);
	.product-desc {
		display: block;
		color: #fff;
		font-size: 1.2rem;
	}
	.product-title {
		a {
			color: #fff;
			font-size: 1.4rem;
			&:hover {
				color: $theme-color-default;
			}
		}
	}
	.product-comments{
		> span {
			display: none;
		}
		.star_content {
			display: inline-block;
		}

	}
	@media (max-width: 767px) {
		display: none;
	}
}

.product-comments {
	.star_content {
		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
	    overflow: hidden;
	}
	span {
		display: none;
	}
}

//Product miniature
.product-miniature {
	position: relative;
	margin-top: 30px;
	@include border-radius(5px);
	border: 1px solid #f2f2f2;
	@include transition(all 0.5s ease);
	&.first_item{
		margin-top: 0;
	}
	//Thumbnail
	.thumbnail-container {
		position: relative;
		overflow: hidden;
		text-align: center;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		//two image
		.two-image{
			display: block;
			position: relative;
			.image-cover {
				@include opacity(1);
				@include transition(all 400ms ease-in-out 0s);
			}
			.image-secondary {
				position: absolute;
				overflow: hidden;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				@include opacity(0);
				@include transition(all 400ms ease-in-out 0s);
				margin: 0 auto;
			}
		}
		.one-image{
			display: block;
			position: relative;
			.image-cover {
				@include opacity(1);
				@include transition(all 400ms ease-in-out 0s);
			}
		}
	}

	.info-stock {
		display: none;
	}
	.group-buttons{
		position: absolute;
		bottom: 0px;
		@include rtl-left(50%);
		@include translateX(-50%);
		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		@include transition(all 0.4s ease);
		.addToWishlist {
			@include opacity(0);
			@include transition(all 500ms ease-in-out 0s);
			position: relative;
			.nov-icon-wishlist{
				display: inline-block;
				@include size(19px, 19px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -97px -0px;
				background-repeat: no-repeat;
			}
			&.checked{
				pointer-events: none;
				cursor: not-allowed;
			}
			&:hover{
				border-color: $theme-color-default;
				@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
				.nov-icon-wishlist{
					background-position: -97px -26px;
				}
			}
		}
		.quick-view{
			@include opacity(0);
			@include transition(all 350ms ease-in-out 0s);
			.nov-icon-quick-view{
				display: inline-block;
				@include square(19px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -125px -0px;
				background-repeat: no-repeat;
			}
			&:hover{
				border-color: $theme-color-default;
				@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
				.nov-icon-quick-view{
					background-position: -125px -26px;
				}
			}
		}
		a{
			font-size: 0;
			width: 40px;
			height: 40px;
			min-width: 40px;
			text-align: center;
			border: 1px solid #d7d7d7;
			@include border-radius(2px);
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			@include transition(all 0.35s ease-in-out);
			@include rtl-margin-lr(5px, 5px);
			&:hover{
				background: $theme-color-default;
			}
		}
		form{
			@include transition(all 350ms ease-in-out 0s);
			@include opacity(0);
		}
		.add-to-cart {
			position: relative;
			@include flexbox();
			@include align-items(center);
			text-decoration: none;
			@include transition(all 0.35s ease-in-out);
			@include rtl-margin-lr(5px, 5px);
			width: auto;
			min-width: 130px;
			span{
				&:not(.loading){
					font-size: 13px;
					font-weight: 600;
					color: #999999;
					display: inline-block;
					@include rtl-padding-lr(10px, 5px);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.loading{
				position: absolute;
				top: 50%;
				@include rtl-left(50%);
				@include translate(-50%,-50%);
				color: #fff;	
				font-size: 1.5rem;
				display: none;
				line-height: 1;
				i{
					margin: 0;
				}
			}
			&.cart-loading{
				background: $theme-color-default;
				.loading{
					display: inline-block;
				}
				.novicon-cart, span:not(.loading){
					@include opacity(0);
				}
				
			}
			i {
				&.novicon-cart {
					display: inline-block;
					background-image: url(../img/icon/icon-group-header.png);
					background-repeat: no-repeat;
					width: 21px;
					height: 19px;
					background-position: 0px -26px;
				}
			}
			&:hover{
				background: $theme-color-default;
				border-color: $theme-color-default;
				@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
				span{
					&:not(.loading){
						font-size: 13px;
						font-weight: 600;
						color: $theme-color-primary;
						display: inline-block;
					}
				}
				i{	
					&.novicon-cart{
						background-position: 0px 0px;
					}
				}
			}
			&:focus {
				outline: none;
			}
			&:disabled {
				cursor: not-allowed;
			}
		}
	}
	
	.product-group-price{
		line-height: 1;
		padding-top: 26px;
		.product-price-and-shipping{
			.price{
				font-size: 18px;
				font-weight: 600;
				color: #e55b2a;
			}
			.regular-price{
				font-size: 15px;
				font-weight: 600;
				color: #6e6e6e;
				@include rtl-margin-lr(0, 10px);
			}
		}
	}
	.product-description{
		padding-bottom: 37px;
		.product-title{
			padding-top: 26px;
			padding-bottom: 13px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			>a{
				font-size: 15px;
				font-weight: 600;
				color: $theme-color-primary;
				&:hover{
					color: $theme-color-default;
				}
			}
		}
	}
	&.item-default {
		text-align: center;
		position: relative;
		.thumbnail-container {
			padding: 10px;
			margin-bottom: 0;
		}
		.product-description {
			padding: 0 10px;
		}
		.product-buttons {
			position: absolute;
			bottom: -37px;
			margin: -1px;
			width: 100%;
			width: calc(100% + 3px);
			@include opacity(0);
			visibility: hidden;
			@include transition(all 0.25s ease);
		}
		&:hover {
			.product-buttons {
				@include opacity(1);
				visibility: visible;
				bottom: -1px;
			}
			.group-action {
				@include rtl-right(0);
				@include opacity(1);
			}
			.product-group-price {
				@include opacity(0);
			}
		}
	}

	&.item-one {
		position: relative;
		.thumbnail-container {
		    position: relative;
		}
		.product-description{
			.product-group-price{
				@include transition(all 0.5s ease);
				@include translateY(0px);
				@include opacity(1);
			}
		}
		&:hover{
			.product-description{
				.product-group-price{
					@include translateY(20px);
					@include opacity(0);
				}
			}
		}
	}

	&:hover {

		@include box-shadow(0 5px 8px 0 rgba(0,0,0,0.10));
		
		.group-buttons{
			bottom: 22px;
			.quick-view{
				@include opacity(1);
			}
			form{
				@include opacity(1);
			}
			.addToWishlist{
				@include opacity(1);
			}

		}
		.thumbnail-container {
			.two-image{
				.image-cover{
					@include opacity(0);
				}
				.image-secondary {
					@include opacity(1);
				}
			}
			.one-image{
				.image-cover{
					@include opacity(1);
				}
			}
			
		}

	}
}

// Item product in list
.product_list {
	&.grid {
		.owl-item {
			&:hover {
				position: relative;
				z-index: 1;
			}
		}
	}
	.item {
		&.item-animate {
			-webkit-animation: fadeInUp 1s ease both 0s;
			-moz-animation: fadeInUp 1s ease both 0s;
			-o-animation: fadeInUp 1s ease both 0s;
			animation: fadeInUp 1s ease both 0s;
	    }
	}
}

#js-product-list {
	.product_list {
		&.grid {
			.product-miniature {

			}
		}
		&.list {	
			.item {
				margin-bottom: 3rem;
				-webkit-flex: 0 0 100%;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				width: 100%;
			}
			.product-miniature {
				margin-top: 0;
				.thumbnail-container {
					border-top-right-radius: 0px;
					position: relative;
					margin: 0;
					>a {
						display: block;
					}
				}
				.product-description {
					@include rtl-text-align-left();
					padding-bottom: 0;
					.product-title {
						font-size: 15px;
						padding-bottom: 17px;
						padding-top: 0px;
						a{
							font-size: 15px;
							font-weight: 600;
							color: $theme-color-primary;
							text-transform: capitalize;
							&:hover{
								color: $theme-color-default;
							}
						}
					}
					.product-desc{
						font-size: 1.5rem;
						color: $theme-color-primary;
						line-height: 24px;
						padding-top: 20px;
						padding-bottom: 23px;
						border-bottom: 1px solid #f2f2f2;
						margin-bottom: 20px;
					}
					.product-comments{
						.star_content{
							@include  justify-content(flex-start !important);
						}
					}
					.product-group-price{
						padding-top: 7px;
						.product-price-and-shipping{
							.regular-price{
								font-size: 1.5rem;
								font-weight: 600;
								color: #6e6e6e;
								@include rtl-margin-lr(0, 15px);
							}
							.price{
								font-size: 2.8rem;
								font-weight: 600;
								color: #e55b2a;
							}
						}
					}
					.group-reviews{
						margin-bottom: 24px;
						padding-bottom: 26px;
						border-bottom: 1px solid #f3f3f3;
						display: none;
						.info-stock{
							text-transform: uppercase;
							font-size: 1.1rem;
							font-weight: 500;
							color: #5fbb52;
							i{
								font-size: 1.7rem;
								@include rtl-margin-right(5px);
								color: #5fbb52;
							}
						}
					}
					
				}
				.group-buttons{
					@include justify-content(flex-start !important);
					position: relative;
					@include rtl-left(0);
					@include translateX(0);
					margin-top: 35px;
					top: 0;
					>a, form{
						position: relative;
						left: initial;
						right: initial;
						top: initial;
						@include opacity(1);
						@include border-radius(3px);
					}
					.quick-view{
						@include rtl-margin-lr(5px, 0px);
						@include order(3);
						&:hover{
							@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
						}
					}
					.addToWishlist{
						@include order(2);
						&:hover{
							@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
						}
					}
					form{
						@include order(1);
						.add-to-cart{
							@include border-radius(3px!important);
							>span{
								font-size: 13px;
								@include rtl-padding-lr(10px, 0);
								color: #999999;
							}
							&:hover{
								@include box-shadow(0 3px 5px rgba(0,0,0,0.10));
								>span{
									color: $theme-color-primary;
								}
							}
						}
					}
				}
				.product-flags{
					top: 10px;
					@include rtl-right(10px);
					.discount{
						@include rtl-right(10px);
					}
				}
				.product-desc-right {
					text-align: left !important;

				}
				
				&:hover {
					.product-groups{
						.product-description{
							.product-group-price{
								@include opacity(1);
								@include translateY(0);
							}
						}
					}
				}
				
			}
		}
	}
}