.contact-rich {
  margin-bottom: rem(32px);
  h4 {
    text-transform: uppercase;
    color: #eaeaea;
    margin-bottom: rem(32px);
  }
  .block {
    height: auto;
    overflow: hidden;
    @include flexbox();
    @include align-items(center);
    .icon {
      background: $theme-color-default;
      color: #fff;
      @include rtl-float-left();
      @include square(24px);
      text-align: center;
      line-height: 28px;
      @include border-radius(2px);
      @include rtl-margin-right(20px);
      i {
        font-size: rem(14px);
      }
    }
    .data {
      width: auto;
      overflow: hidden;
    }
  }
}
.contact-form {
  color: #ccc;
  h3 {
    text-transform: uppercase;
    color: #eaeaea;
  }
  .group-span-filestyle{
    label{
      background: #222222;
    }
    &:hover{
      label{
        background: $theme-color-default;
        border-color: $theme-color-default;
      }
    }
  }
  .form-fields {
    .row {
      margin-left: -5px;
      margin-right: -5px;
      margin-bottom: 0;
    }
    .col-md-6, .col-md-12 {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }
    label {
      margin-bottom: 0;
    }
    .form-control {
      border-color: #ececec;
      min-height: 42px;
      background-color: #ececec;
      font-size: 1.1rem;
      color: #909090;
      text-transform: uppercase;
      &:hover, &:focus {
        border: 1px solid   $theme-color-default;;
        background-color: #fff;
        @include transition(all 0.35s ease 0.1s);
      }
      @include placeholder {
        color: #909090;
      }
    }
    
    .btn {
      &.btn-default {
        padding: 6px 16px;
      }
      .buttonText {
        line-height: 28px;
        color: #fff;
      }
    }
  }
  .form-footer {
    margin-top: 1rem;
    .btn {
      width: 100%;
      background: $theme-color-default;
      border-color: $theme-color-default;
      padding: 13px 12px;
      color: #fff;
      &:hover {
        background: $theme-color-primary;
        border-color: $theme-color-primary;
      }
    }
    input[name="url"]{
      display: none !important;
    }
    input[type="text"]{
      display: none !important;
    }
  }

}
.google-map { position: relative; }
#nov-map-contact {
  min-height: 379px;
  margin-bottom: 40px;
}
.map-locker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}
.icon-comments {
  @include square(60px);
  background: url(../img/icon/icon-comment.png) no-repeat;
  display: inline-block;
  margin-bottom: 25px;
  margin-top: 25px;
}
