@for $i from 0 through 20 {
    $value: 5 * $i;
    .mt-#{$value} {
        margin-top: #{$value}px !important;
    }
    .mb-#{$value} {
        margin-bottom: #{$value}px !important;
    }
}
@for $i from 0 through 20 {
    $value: 5 * $i;
    .pt-#{$value} {
        padding-top: #{$value}px !important;
    }
    .pb-#{$value} {
        padding-bottom: #{$value}px !important;
    }
}
@for $i from 0 through 20 {
    $value: 5 * $i;
    .pl-#{$value} {
        padding-left: #{$value}px !important;
        .lang-rtl & {
          padding-right: #{$value}px !important;
          padding-left: 15px !important;
        }
    }
    .pr-#{$value} {
        padding-right: #{$value}px !important;
        .lang-rtl & {
          padding-left: #{$value}px !important;
          padding-right: 15px !important;
        }
    }
}
@for $i from 0 through 100 {
  $value: $i;
  .spacing-#{$value}{
    .row{
      margin-right:#{-$value/2}px;
      margin-left: #{-$value/2}px;
      
      >[class^="col-"], > [class*=" col-"]{
        padding: 0 #{$value/2}px!important;
      }
    }
    @media (max-width: $screen-xs-max){
      .nov-image {
        text-align: center;
      }
      .row{
        >[class^="col-"], > [class*=" col-"]{
          padding: 0 #{$value/2}px!important;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  @for $i from 0 through 20 {
    $value: 5 * $i;
    .mt-xs-#{$value} {
        margin-top: #{$value}px !important;
    }
    .mb-xs-#{$value} {
        margin-bottom: #{$value}px !important;
    }
  }
  @for $i from 0 through 20 {
    $value: 5 * $i;
    .pl-xs-#{$value} {
        padding-left: #{$value}px !important;
        .lang-rtl & {
          padding-right: #{$value}px !important;
          padding-left: 15px !important;
        }
    }
    .pr-xs-#{$value} {
        padding-right: #{$value}px !important;
        .lang-rtl & {
          padding-left: #{$value}px !important;
          padding-right: 15px !important;
        }
    }
    .pt-xs-#{$value} {
        padding-top: #{$value}px !important;
    }
    .pb-xs-#{$value} {
        padding-bottom: #{$value}px !important;
    }
  }
}
@include media-breakpoint-down(md) {
  @for $i from 0 through 20 {
    $value: 5 * $i;
    .mt-md-#{$value} {
        margin-top: #{$value}px !important;
    }
    .mb-md-#{$value} {
        margin-bottom: #{$value}px !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  @for $i from 0 through 20 {
    $value: 5 * $i;
    .mt-lg-#{$value} {
        margin-top: #{$value}px !important;
    }
    .mb-lg-#{$value} {
        margin-bottom: #{$value}px !important;
    }
  }
}
.alert-info{
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-bottom: 40px!important;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (max-width: 767px){
  
  .col-w-38{
    -webkit-box-flex: 0 0 48%!important;
    -moz-box-flex: 0 0 48%!important;
    -webkit-flex: 0 0 48%!important;
    -ms-flex: 0 0 48%!important;
    flex: 0 0 48%!important;
    max-width: 48%!important;
  }
  .col-w-62{
    -webkit-box-flex: 0 0 52%!important;
    -moz-box-flex: 0 0 52%!important;
    -webkit-flex: 0 0 52%!important;
    -ms-flex: 0 0 52%!important;
    flex: 0 0 52%!important;
    max-width: 52%!important;
  }
}
@media (min-width: 768px) {

  .col-w-54{
    -webkit-box-flex: 0 0 54%!important;
    -moz-box-flex: 0 0 54%!important;
    -webkit-flex: 0 0 54%!important;
    -ms-flex: 0 0 54%!important;
    flex: 0 0 54%!important;
    max-width: 54%!important;
  }
  .col-w-46{
    -webkit-box-flex: 0 0 46%!important;
    -moz-box-flex: 0 0 46%!important;
    -webkit-flex: 0 0 46%!important;
    -ms-flex: 0 0 46%!important;
    flex: 0 0 46%!important;
    max-width: 46%!important;
  }
  .col-w-5573{
    -webkit-box-flex: 0 0 55.73%!important;
    -moz-box-flex: 0 0 55.73%!important;
    -webkit-flex: 0 0 55.73%!important;
    -ms-flex: 0 0 55.73%!important;
    flex: 0 0 55.73%!important;
    max-width: 55.73%!important;
  }
  .col-w-4427{
    -webkit-box-flex: 0 0 44.27%!important;
    -moz-box-flex: 0 0 44.27%!important;
    -webkit-flex: 0 0 44.27%!important;
    -ms-flex: 0 0 44.27%!important;
    flex: 0 0 44.27%!important;
    max-width: 44.27%!important;
  }
  .col-22{
    -webkit-box-flex: 0 0 22%!important;
    -moz-box-flex: 0 0 22%!important;
    -webkit-flex: 0 0 22%!important;
    -ms-flex: 0 0 22%!important;
    flex: 0 0 22%!important;
    max-width: 22%!important;
  }
  .col-34{
    -webkit-box-flex: 0 0 34%!important;
    -moz-box-flex: 0 0 34%!important;
    -webkit-flex: 0 0 34%!important;
    -ms-flex: 0 0 34%!important;
    flex: 0 0 34%!important;
    max-width: 34%!important;
  }
  .col-w-38{
    -webkit-box-flex: 0 0 38%!important;
    -moz-box-flex: 0 0 38%!important;
    -webkit-flex: 0 0 38%!important;
    -ms-flex: 0 0 38%!important;
    flex: 0 0 38%!important;
    max-width: 38%!important;
  }
  .col-w-62{
    -webkit-box-flex: 0 0 62%!important;
    -moz-box-flex: 0 0 62%!important;
    -webkit-flex: 0 0 62%!important;
    -ms-flex: 0 0 62%!important;
    flex: 0 0 62%!important;
    max-width: 62%!important;
  }
  
  .col-sm-15 {
    width: 20%;
    float: left;
  }
  .w-59{
    -webkit-box-flex: 0 0 57.5949%;
    -moz-box-flex: 0 0 57.5949%;
    -webkit-flex: 0 0 57.5949%;
    -ms-flex: 0 0 57.5949%;
    flex: 0 0 57.5949%;
    max-width: 57.5949%;
  }
  .w-41{
    -webkit-box-flex: 0 0 42.4051%;
    -moz-box-flex: 0 0 42.4051%;
    -webkit-flex: 0 0 42.4051%;
    -ms-flex: 0 0 42.4051%;
    flex: 0 0 42.4051%;
    max-width: 42.4051%;
  }
  .w-29{
    -webkit-box-flex: 0 0 29.7469%;
    -moz-box-flex: 0 0 29.7469%;
    -webkit-flex: 0 0 29.7469%;
    -ms-flex: 0 0 29.7469%;
    flex: 0 0 29.7469%;
    max-width: 29.7469%;
  }
  .w-42{
    -webkit-box-flex: 0 0 40.5062%;
    -moz-box-flex: 0 0 40.5062%;
    -webkit-flex: 0 0 40.5062%;
    -ms-flex: 0 0 40.5062%;
    flex: 0 0 40.5062%;
    max-width: 40.5062%
  }
}
@media (max-width: 991px){
  .col-w-20{
    -webkit-box-flex: 0 0 100%!important;
    -moz-box-flex: 0 0 100%!important;
    -webkit-flex: 0 0 100%!important;
    -ms-flex: 0 0 100%!important;
    flex: 0 0 100%!important;
    max-width: 100%!important;
  }
  .col-w-80{
    -webkit-box-flex: 0 0 100%!important;
    -moz-box-flex: 0 0 100%!important;
    -webkit-flex: 0 0 100%!important;
    -ms-flex: 0 0 100%!important;
    flex: 0 0 100%!important;
    max-width: 100%!important;
  }
}
@media (min-width: 992px) {
  .col-w-20{
    -webkit-box-flex: 0 0 20%!important;
    -moz-box-flex: 0 0 20%!important;
    -webkit-flex: 0 0 20%!important;
    -ms-flex: 0 0 20%!important;
    flex: 0 0 20%!important;
    max-width: 20%!important;
  }
  .col-w-80{
    -webkit-box-flex: 0 0 80%!important;
    -moz-box-flex: 0 0 80%!important;
    -webkit-flex: 0 0 80%!important;
    -ms-flex: 0 0 80%!important;
    flex: 0 0 80%!important;
    max-width: 80%!important;
  }
  .col-md-15 {
    width: 20%;
    float: left;
  }
  .col-w-33{
    -webkit-box-flex: 0 0 32.37%!important;
    -moz-box-flex: 0 0 32.37%!important;
    -webkit-flex: 0 0 32.37%!important;
    -ms-flex: 0 0 32.37%!important;
    flex: 0 0 32.37%!important;
    max-width: 32.37%!important;
    @include rtl-padding-lr(15px, 0);
  }
  .col-w-67{
    -webkit-box-flex: 0 0 67.63%!important;
    -moz-box-flex: 0 0 67.63%!important;
    -webkit-flex: 0 0 67.63%!important;
    -ms-flex: 0 0 67.63%!important;
    flex: 0 0 67.63%!important;
    max-width: 67.63%!important;
    @include rtl-padding-lr(0, 15px);
  }
  .w-37{
    -webkit-box-flex: 0 0 48%;
    -moz-box-flex: 0 0 48%;
    -webkit-flex: 0 0 48%;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
  }
  .w-63{
    -webkit-box-flex: 0 0 52%;
    -moz-box-flex: 0 0 52%;
    -webkit-flex: 0 0 52%;
    -ms-flex: 0 0 52%;
    flex: 0 0 52%;
    max-width: 52%;
  }
}

@media (min-width: 1200px) {
    .col-cus-254{
      -webkit-box-flex: 0 0 25.4%!important;
      -moz-box-flex: 0 0 25.4%!important;
      -webkit-flex: 0 0 25.4%!important;
      -ms-flex: 0 0 25.4%!important;
      flex: 0 0 25.4%!important;
      max-width: 25.4%!important;
    }
    .col-cus-492{
      -webkit-box-flex: 0 0 49.2%!important;
      -moz-box-flex: 0 0 49.2%!important;
      -webkit-flex: 0 0 49.2%!important;
      -ms-flex: 0 0 49.2%!important;
      flex: 0 0 49.2%!important;
      max-width: 49.2%!important;
    }
    .col-lg-15 {
        width: 20%;
        float: left;
    }
    .w-37{
    -webkit-box-flex: 0 0 37%;
    -moz-box-flex: 0 0 37%;
    -webkit-flex: 0 0 37%;
    -ms-flex: 0 0 37%;
    flex: 0 0 37%;
    max-width: 37%;
  }
  .w-63{
    -webkit-box-flex: 0 0 63%;
    -moz-box-flex: 0 0 63%;
    -webkit-flex: 0 0 63%;
    -ms-flex: 0 0 63%;
    flex: 0 0 63%;
    max-width: 63%;
  }
}
@media (max-width: 1199px) and (min-width: 768px){
  .col-cus-254{
      -webkit-box-flex: 0 0 50%!important;
      -moz-box-flex: 0 0 50%!important;
      -webkit-flex: 0 0 50%!important;
      -ms-flex: 0 0 50%!important;
      flex: 0 0 50%!important;
      max-width: 50%!important;
    }
    .col-cus-492{
      -webkit-box-flex: 0 0 100%!important;
      -moz-box-flex: 0 0 100%!important;
      -webkit-flex: 0 0 100%!important;
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      max-width: 100%!important;
    }
}
@media (max-width: 767px){
  .col-cus-254{
      -webkit-box-flex: 0 0 100%!important;
      -moz-box-flex: 0 0 100%!important;
      -webkit-flex: 0 0 100%!important;
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      max-width: 100%!important;
    }
    .col-cus-492{
      -webkit-box-flex: 0 0 100%!important;
      -moz-box-flex: 0 0 100%!important;
      -webkit-flex: 0 0 100%!important;
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      max-width: 100%!important;
    }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.spacing-15 {
  .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
    > [class^="col-"], > [class*=" col-"] {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
  }
}
.spacing-10 {
  .row {
    margin-left: -5px;
    margin-right: -5px;
    > [class^="col-"], > [class*=" col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
body.is_safari .no-flex-safari {
  .row {
    display: block;
  }
}
.title-center {
  .title_block {
    text-align: center;
    padding-top: 25px;
    position: relative;
    span.sub_title {
      position: absolute;
      top: 0;
      left: 50%;
      @include translateX(-50%);
      margin-top: 0;
    }
  }
}
.w-34 {
    width: 34%!important;
}
.w-35 {
    width: 35%!important;
}
.w-40 {
    width: 40%!important;
}
.w-60 {
    width: 60%!important;
}
.w-65 {
    width: 65%!important;
}
.w-66 {
    width: 66%!important;
}

.w-42p {
    width: 42%!important;
}
.w-58p {
    width: 58%!important;
}
.w-1464p {
  width: 14.64%!important;
}
.w-2736p {
  width: 27.36%!important;
}
.nov_row-full-width {
  margin-left: 15px;
  margin-right: 15px;
}
.nov-row {
  &[data-nov-stretch-content=true] {
    padding-left: 15px;
    padding-right: 15px;
    &.nov_row-no-padding {
      padding-left: 0!important;
      padding-right: 0!important;
      overflow: hidden;
    }
  }
}
@media (min-width: 768px) {
    .block-w1265 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 12.65%;
        -ms-flex: 0 0 12.65%;
        flex: 0 0 12.65%;
        max-width: 12.65%;
    }
    .block-w2975 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 29.75%;
        -ms-flex: 0 0 29.75%;
        flex: 0 0 29.75%;
        max-width: 29.75%;
    }
    .block-w576 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 57.6%;
        -ms-flex: 0 0 57.6%;
        flex: 0 0 57.6%;
        max-width: 57.6%;
    }
}


@include media-breakpoint-up(md) {

  .w-20 {
    width: 20% !important;
    flex: 0 0 20%;
    max-width: 20%;

  }
  .w-80 {
    width: 80% !important;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .w-35 {
    width: 35% !important;
    flex: 0 0 35%;
    max-width: 35%;

  }
  .w-65 {
    width: 65% !important;
    flex: 0 0 65%;
    max-width: 65%;
  }
  .no-padding-right {
    padding-right: 0;
    .lang-rtl & {
      padding-left: 0;
      padding-right: 15px;
    }
  }
  .no-padding-left {
    padding-left: 0;
    .lang-rtl & {
      padding-right: 0;
      padding-left: 15px;
    }
  }
}

.hidden {
  display: none !important;
}
a {
  color: $text-color-title;
  &:hover {
    color: $theme-color-default;
    text-decoration: none;
  }
}
pre {
  margin-bottom: 1px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-primary;
}
button, input, optgroup, select, textarea {
  font-family: $font-family-default;
}
input,textarea{
  background-image: url("data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw%3D%3D");
}

.ui-autocomplete {
  border-color: #eaeaea;
  @include border-radius(0);
  z-index: 99999;
  text-align: center;
  .ui-state-focus {
    color: $theme-color-default;
    background: transparent;
    border-color: transparent;
  }
}

//checkbox custom
.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 16px;
  height: 16px;
  display: inline-block;
  @include rtl-margin-right(12px);
  cursor: pointer;
  background-size: contain;
  vertical-align: middle;
  &.active,
  &:hover {
    border: 1px solid #f2f2f2;
  }
}
.custom-checkbox {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    font-size: 12px;
    
    input[type=checkbox] {
        @include opacity(1);
        cursor: pointer;
        position: absolute;
    }
    input[type=checkbox]+span {
        @include rtl-margin-right(10px);
        display: inline-block;
        width: 10px;
        height: 10px;
        min-width: 10px;
        vertical-align: middle;
        cursor: pointer;
        @include border-radius(2px);
        border: 1px solid #d7d7d7;
        background-color: #d7d7d7;
    }
    input[type=checkbox]+span .checkbox-checked {
        display: none;
        font-size: 0;
        color: #666;
    }
    input[type=checkbox]:checked+span .checkbox-checked {
      display: block;
      font-size: 0;
    }
    label {
      em {
        font-size: 1.1rem;
        font-style: italic;
        color: #a0a0a0;
      }
    }
}

//Form
.form-control-label {
    @include rtl-text-align-left();
    font-size: 1.6rem;
    margin-bottom: 0;
    color: #666;
    padding-top: 10px;
}
.form-control-comment {
  &.right {
    padding-top: 10px;
    @include rtl-padding-left(15px);
  }
}
.form-control-valign {
  line-height: 44px;
  .label {margin-bottom: 0;}
}
input.form-control {
  height: 44px;
  background-color: #fff;
  border-color: #dfdfdf;
  font-size: 1.2rem;
  padding-left: 20px;
  padding-right: 20px;
  &:hover,&:focus {
    border-color: $theme-color-primary;
    background: #fff;
  }
}
textarea.form-control {
  background-color: #fff;
  border-color: #dfdfdf;
  font-size: 1.2rem;
  &:hover,&:focus {
    border-color: $theme-color-primary;
    background: #fff;
  }
}
.form-control-comment {
  font-size: 1.3rem;
}
.form-control-comment {
  .hidden-comment & {
    display: none;
    &.right {
      display: inline-block;
    }
  }
}
sup.required {
  color: #cb3737;
}

//Nov Icon
.icon-myaccount {
  display: inline-block;
  vertical-align: bottom;
  font-size: 0;
  height: 23px;
  width: 22px;
  margin-bottom: 8px;
  background-image: url(../img/icon/icon-groups-nav2.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}
.novicon {
  content: '';
  width: 30px;
  height: 23px;
  background-image: url(../img/icon/icon-groups-nav2.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
  &.novicon-grid {
    width: 23px;
    background-position: -219px 2px;
  }
  &.novicon-email {
    background-position: -253px 3px;
    width: 18px;
  }
  &.novicon-phone {
    background-position: -282px 3px;
    width: 18px;
  }
}

.novicon-info {
  background-image: url(../img/icon/icon-contact.png);
  width: 39px;
  height: 41px;
  @include rtl-margin-right(20px);
}
.noviconpolicy {
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-image: url(../img/icon/icon-group-policy.png);
  background-repeat: no-repeat;
  display: inline-block;
  @include transition(all 0.5s ease-in-out);
  
  &.noviconpolicy-1 {
    background-position: 0px 0px;
  }
  &.noviconpolicy-2 {
    background-position: 0px -46px;
  }
  &.noviconpolicy-3 {
    background-position: -0px -93px;
  }
}
.noviconsocial {
  content: '';
  width: 63px;
  height: 19px;
  background-image: url(../img/icon/icon-social-2.png);
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  @include opacity(0.3);
  @include transition(opacity 0.3s ease);
  &:hover {
     @include opacity(1);
  }
  &.noviconsocial-facebook {
    background-position: 0 -76px;
  }
  &.noviconsocial-twitter {
    background-position: 0 -57px;
  }
  &.noviconsocial-google {
    background-position: 0 -38px;
  }
  &.noviconsocial-pinterest {
    background-position: 0 -19px;
  }
  &.noviconsocial-instagram {
    background-position: 0 0;
  }
}

.nov-icon-cart-header {
  width: 43px;
  height: 53px;
  background-image: url(../img/icon-cart.png);
  background-repeat: no-repeat;
  display: inline-block;
}

//Custom scrollbar in block cart
.cart_block, .cart_block_content{
  ul::-webkit-scrollbar {
  width: 0.8rem;
  }
}
 
.cart_block, .cart_block_content{
  ul::-webkit-scrollbar-track {
    background: #eaeaea;
    @include border-radius(0.4rem);
  }
}
 
.cart_block, .cart_block_content{
  ul::-webkit-scrollbar-thumb {
    background-color: $theme-color-primary;
    @include border-radius(0.4rem);
  }
}

//Form
.form-group {
  margin-bottom: 15px;
  .radio-inline {
    @include rtl-margin-right(30px);
    font-size: 1.2rem;
    input[type*='radio'] {
      @include square(16px);
      vertical-align: middle;
      @include rtl-margin-right(5px);
    }
  }
  .input-group.js-parent-focus {
    .btn {
      padding: 0 18px;
      @include rtl-border-left(1px solid #e9e9e9);
      i {
        margin: 0;
      }
      &:hover i {
        color: #fff;
      }
    }
  }
  input {
    @include placeholder {
      font-size: 11px;
      color: #cccbcb;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: #fff !important;
  }
}
.form-control-label {
    @include rtl-text-align-left();
    font-size: 1.2rem;
    margin-bottom: 0;
    color: #666;
    padding-top: 10px;
}
.form-control-comment {
  font-size: 10px;
  color: #aaaaaa;
  &.right {
    padding-top: 10px;
    @include rtl-padding-left(15px);
    font-size: 1.1rem;
  }
}
.form-control-valign {
  line-height: 43px;
  .label {margin-bottom: 0;}
}
input.form-control {
  height: 46px;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw%3D%3D");
  border-color: #e9e9e9;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  &:hover,&:focus {
    border-color: $theme-color-default;
    background: #fff;
  }
}
textarea.form-control {
  background-color: #fff;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw%3D%3D");
  border-color: #dfdfdf;
  font-size: 1.2rem;
  &:hover,&:focus {
    border-color: $theme-color-default;
    background: #fff;
  }
}
.form-control-comment {
  .hidden-comment & {
    display: none;
    &.right {
      display: inline-block;
    }
  }
}

select.form-control:not([size]):not([multiple]) {
  height: 46px;
  font-size: 1.2rem;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  background-image: url(../img/icon-dropdown.png);
  background-repeat: no-repeat;
  @include rtl-background-position-right(15px center);
}

sup.required {
  color: #cb3737;
}
.form-footer .btn.btn-primary {
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 43px;
  font-size: 1.1rem;
  font-weight: bold;
  &:hover {
    background: darken($theme-color-default, 10%);
  }
}

//Dropdown menu
.open > .dropdown-menu {
    display: block;
}
.show > .dropdown-menu {
    display: block;
}
//Modal-Review
.modal-review{
  .modal-content{
    .modal-header{
      .modal-title{
        font-size: 1.6rem;
        color: $theme-color-default;
        i{
          color: $theme-color-default;
          font-size: 1.6rem;
          @include rtl-margin-right(10px);
        }
      }
    }
    .modal-body{
      .new_comment_form_content{
        #criterions_list{
          @include flexbox();
          @include align-items(center);
          li{
            @include flexbox();
            @include align-items(center);
            label{
              margin-top: 0;
            }
            .star_content{
              display: flex!important;
              @include align-items(center);
            }
          }
        }
      }
    }
  }
}
//Modal
.modal {
  .modal-header {
    padding: 0;
    border-bottom: none;
    .modal-title {
      padding: 12px 20px 11px;
    }
    button {
      @include square(22px);
      position: absolute;
      @include rtl-right(10px);
      top: 10px;
      z-index: 30000;
      outline: none;

      i {
        color: #bebebe;
        font-size: 20px;
        width: 22px;
        font-weight: 900;
        @include transition(all 0.5s ease);
      }
      &:hover{
        i{
          @include rotate(360deg);
        }
      }
    }
  }
}

//Popover
.popover {
  border-color: $base-border-color;
  padding: 0;
  @include box-shadow(0 4px 5px rgba(0, 0, 0, 0.1));
  .popover-title {
    font-size: 1.2rem;
  }
  .popover-content {
      padding: 5px 10px;
      font-size: 1.2rem;
      font-family: $font-family-default;
     .item-wishlist {padding: 5px 0;cursor: pointer;}
  }
}

.ui-slider {
  height: 8px;
  width: calc(100% - 30px);
  background: #e1e1e1;
  border-color: #e1e1e1;
  .ui-slider-handle {
    width: 19px;
    height: 19px;
    border: 6px solid #2d2d2d;
    @include border-radius(50%);
    background: #fff;
  }
  .ui-widget-header {
    background: #2d2d2d;
    border-color: #2d2d2d;
  }
  &.ui-slider-horizontal .ui-slider-handle {
    @include rtl-margin-left(-2px);
    top: -7px;
  }
}

@include media-breakpoint-up(sm) {
  .col-w-35p {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-w-65p {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    padding-right: 15px;
    padding-left: 15px;
  }

}

.process-loading {
  position: relative;
  height: 0;
  @include opacity(0);
  overflow: hidden;
  background: #fff;
  @include transition(all 0.3s ease);
  &.active {
    height: 150px;
    @include opacity(1);
  }
  .loader {
    position: absolute;
    top: 15px;
    left: 50%;
    @include translateX(-50%);
    .shadow-slider{
      width: 50px;
      height: 5px;
      background: #000;
      opacity: 0.1;
      position: absolute;
      top: 59px;
      left: 0;
      border-radius: 50%;
      @include animation('shadow-slider 0.5s linear infinite');
    }
    .box-slider{
      width: 50px;
      height: 50px;
      background: #feb332;
      @include animation('box-slider 0.5s linear infinite');
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
    }
  }
  .nov_preload & {
    .loader {
      top: 50%;
      margin-top: -10px;
    }
  }
}


//Animation
@include keyframes(shadow-slider){
  50% {
    @include scale(1.2, 1);
  }
}
@include keyframes(box-slider){
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1,.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}


@-webkit-keyframes slideInUpNov {
    0% {
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes slideInUpNov {
    0% {
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@-webkit-keyframes slideOutDownNov {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0);
    }
}
@keyframes slideOutDownNov {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0);
    }
}

@-webkit-keyframes fadeInNov {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInNov {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes beforehover {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes beforehover {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
//Animate cycle
@keyframes nov-cycle {
 0%,50%,100% {
  animation-timing-function:cubic-bezier(0.5,0.5,0.5,0.5)
 }
 0% {
  -webkit-transform:rotate(0);
  transform:rotate(0)
 }
 50% {
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg)
 }
 100% {
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg)
 }
}
@-webkit-keyframes nov-cycle {
 0%,50%,100% {
  animation-timing-function:cubic-bezier(0.5,0.5,0.5,0.5)
 }
 0% {
  -webkit-transform:rotate(0);
  transform:rotate(0)
 }
 50% {
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg)
 }
 100% {
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg)
 }
}

//Animate Nov slide
@keyframes nov-slide {
  0% {
      @include scale(1,1);
  }
  50% {
      @include opacity(0.3);
      @include scale(2,2);
  }
  100% {
      @include scale(1,1);
  }
}
@-webkit-keyframes nov-slide {
  0% {
      @include scale(1,1);
  }
  50% {
      @include opacity(0.3);
      @include scale(2,2);
  }
  100% {
      @include scale(1,1);
  }
}