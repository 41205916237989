// makes a list inline.

@mixin inline-list {
  list-style-type: none;
  &, & li {
    margin: 0px;
    padding: 0px;
    display: inline;
  }
}

// makes an inline list delimited with the passed string.
// Defaults to making a comma-separated list.
//
// Please make note of the browser support issues before using this mixin:
//
// use of `content` and `:after` is not fully supported in all browsers.
// See quirksmode for the [support matrix](http://www.quirksmode.org/css/contents.html#t15)
//
// `:last-child` is not fully supported.
// see quirksmode for the [support matrix](http://www.quirksmode.org/css/contents.html#t29).
//
// IE8 ignores rules that are included on the same line as :last-child
// see http://www.richardscarrott.co.uk/posts/view/ie8-last-child-bug for details

@mixin delimited-list($separator: ", ") {
  @include inline-list;
  li {
    &:after { content: $separator; }
    &:last-child {
      &:after { content: ""; }
    }
    &.last {
      &:after { content: ""; }
    }
  }
}

// See [delimited-list](#mixin-delimited-list)
// @deprecated
@mixin comma-delimited-list {
  @warn "comma-delimited-list is deprecated. Please use delimited-list instead.";
  @include delimited-list;
}
