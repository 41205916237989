.quickview {
  .divide-right {
    @include rtl-padding-right(2rem);
  }
  .modal-dialog {
    @media (min-width: 768px){
      max-width: 720px;
    }
  }
  .modal-content {
    border: none;
    max-width: 100%;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    @media (min-width: 1200px){
      max-height: 535px;
    }
    @media (max-width: 1199px){
      max-height: 535px;
    }
  }
  .modal-footer {

  }
  .product-images{
    .thumb-container{
      .js-thumb{
        max-width: 100%;
      }
    }
  }
  .layer {
    display: none;
  }
  .product-actions{
    margin-top: 2rem;
  }
  .product-cover, .thumb-container {
    border: 1px solid #e9e9e9;
    border-radius: 3px;
  }
  .js-qv-image {
    margin-top: 10px;
  }
  .product-name {
    font-size: 1.6rem;
    font-weight: 600;
    color: #343434;
    margin-bottom: 15px;
  }
  .product-prices{
    margin-top: 10px;
  }

  .instock{
    @include rtl-margin-right(15px);
    .product-availability{
      .product-available{
        color: $theme-color-default;
      }
    }
  }
  #product-description-short {
    font-size: 1.3rem;
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .current-price {
    color: $theme-color-default;
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 15px;
    .price{
      margin-right: 10px;
    }
    .regular-price{
      font-size: 1.4rem;
      color: #dfdfdf;
    }
    .discount {
      font-size: 1.6rem;
      color: $text-color;
      font-weight: 700;  
    }
  }
  .social-sharing {
    margin-top: 0;
    .dropdown-menu {
      padding: 0;
      i {
        @include rtl-margin-right(1rem);
      }
    }
  }
  .in_border {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border: none;
  }
  .product-add-to-cart {
    padding-top: 0;
  }
  .product-variants {
    .product-variants-item select {
      min-width: 10rem;
    }
  }

}
