body:not(.page-index){
  #wrapper-site{
    @media (min-width: 1200px){
      .container{
        width: 1200px!important;
        max-width: 100%;
      }
    }
  }
  
  #header{
    
  }
  //Footer
  .footer{
    
  }
}
//Header style
#header {
  position: relative;
  background: $header-bg;
  color: $header-color;
  font-size: $header-font-size;
  @mixin header-search(){
    .header_search{
      .search-widget{
        .toggle-search{
          @include flexbox();
          @include rtl-margin-lr(0, 10px);
        }
        .form-search{
          position: absolute;
          @include transition(all 0.5s ease);
          top: 50%;
          @include translateY(-50%);
          @include rtl-right(0);
          @include opacity(0);
          visibility: hidden;
          .ui-autocomplete-input{
            @include border-radius(40px);
          }
        }
        &.active-search{
          .form-search{
            @include opacity(1);
            visibility: visible;
            @include rtl-right(170px);
          }
        }
      }
    }
  }
  .group-header-list{
    .header_link_myaccount{
      .block-novaccount{
        @include square(40px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(50%);
        @include transition(all 0.35s ease);
        @include rtl-margin-lr(0, 10px);  
        cursor: pointer;
        .account{
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          @include square(40px);
        }
        .icon-novaccount{
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -36px -0px;
          width: 15px;
          height: 19px;
          @include transition(all 0.35s ease);
          display: inline-block;
        }
        &:hover{
          background: $theme-color-default;
          .icon-novaccount{
            background-position: -36px -26px;
          }
        }
      }
    }
    .header_link_wishlist{
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include square(40px);
      @include border-radius(50%);
      @include rtl-margin-lr(0, 10px);
      background: $bg-header;
      @include transition(all 0.35s ease);
      a{
        display: inline-block;
        .icon-novwishlist{
          display: inline-block;
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -68px -0px;
          width: 16px;
          height: 14px;
          @include transition(all 0.35s ease);
        }
      }      
      &:hover{
        background: $theme-color-default;
        a{
          .icon-novwishlist{
            background-position: -68px -26px;
          }
        }
        
      }
    }
    .header_link_myaccount_infos{
      .myaccount-icon{
        @include square(40px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(50%);
        @include transition(all 0.35s ease);
        cursor: pointer;
        &:after{
          display: none;
        }
        .icon_link_myaccount_infos{
          display: inline-block;
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -68px -0px;
          width: 19px;
          height: 19px;
          @include transition(all 0.35s ease);
        }
        &:hover{
          background: $theme-color-default;
          .icon_link_myaccount_infos{
            background-position: -68px -26px;
          }
        }
      }
      .account-list{
        top: calc(100% + 25px);
      }
    }
  }
  &.header-1{
    .header-top{
      .container{
        width: 100%;
        .logo-header{
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          height: 90px;
          background: $theme-color-default;
          @include rtl-padding-lr(30px, 30px);
          @include rtl-margin-lr(-15px, 0);
        }
        @include header-search();
      }
      .header-menu{
        .nov-megamenu{
          .menu{
            >.item{
              &:last-child{
                @include rtl-padding-lr(0, 25px);
              }
              &:last-child{
                @include rtl-padding-lr(25px, 0);
              }
            }
          }
        }
      }
    }
  }
  &.header-2{
    z-index: 3;
    background: transparent;
    .header-top{
      .container{
        width: 100%;
        @include header-search();
      }
      .blockcart{
        .header-cart{
          .header-cart-icon{
            .cart-products-count{
              background: $theme-color-primary;
              color: $white;
              &:before{
                border-bottom-color: $theme-color-primary;
              }
              &:after{
                border-top-color: $theme-color-primary;
              }
            }
          }
        }
      }
    }
  }
  &.header-3{
    .container{
      width: 1800px;
    }
    .header-top{
      #_desktop_logo{
        a{
          width: 170px;
          height: 70px;
          background: $theme-color-default;
          border-bottom-right-radius: 35px;
          border-bottom-left-radius: 35px;
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
        }
      }
    }
    .header-center{
      .header_search{
        .search-widget{
          .form-search{
            width: 300px;
            .ui-autocomplete-input{
              width: 300px;
              @include border-radius(40px);
              @include rtl-padding-lr(20px, 55px);
            }
          }
        }
      }
      .header-menu{
        .nov-megamenu{
          .menu{
            >.item{
              padding-top: 22px;
              padding-bottom: 30px;
              &:last-child{
                @include rtl-padding-lr(0, 25px);
              }
              &:last-child{
                @include rtl-padding-lr(25px, 0);
              }
            }
          }
        }
      }
    }
  }
}

#displayTop {
  .nov-row {
    &.no-gutters .nov-row-wrap {
      margin-right: 0;
      margin-left: 0;
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &.displaytoptwo{
    margin-top: -93px;
  }
  &.displaytopthree{
    #nov-slider{
      .nivo-controlNav{
        .nivo-control{
          background: $theme-color-primary;
          &.active, &:hover{
            background: $white;
          }
        }
      }
    }
  }
}

//Homepage style
.page-home {
  .natural-html{
    position: relative;
    padding-top: 130px;
    padding-bottom: 128px;
    &:before{
      content: "";
      position: absolute;
      top:0px;
      @include rtl-left(0);
      width: 100%;
      height: 145px;
      background-image: url(../img/logo-bf-white.png);
      background-repeat: no-repeat;
      background-position: left center;
      z-index: 2;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: -144px;
      @include rtl-right(0);
      width: 100%;
      height: 145px;
      background-image: url(../img/logo-af-yellow.png);
      background-repeat: no-repeat;
      background-position: right center;
      z-index: 2;
    }
    .natural-content{
      .natural-text{
        >div{
          &:not(:last-child){
            margin-bottom: 60px;
          }
        }
        .natural-left{
          width: 100px;
          min-width: 100px;
          height: 100px;
          @include border-radius(5px);
          vertical-align: middle;
          text-align: center;
          font-size: 4rem;
          font-weight: 600;
          color: $theme-color-primary;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include rtl-margin-lr(0,30px);
          @include gradient-horizontal();
        }
        .natural-right{
          h3{
            font-size: 18px;
            font-weight: 600;
            color: $theme-color-primary;
            margin-bottom: 9px;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: $theme-color-primary;
            line-height: 24px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .choose-us{
    background-color: #f3f3f3;
    padding-top: 130px;
    padding-bottom: 90px;
    position: relative;
    &:after{
      content: "";
        position: absolute;
        bottom:-40px;
        @include rtl-left(0);
        width: 100%;
        height: 111px;
        background-image: url(../img/img-af-choose.png);
        background-repeat: no-repeat;
        background-position: bottom left;
    }
  }
  .choose-content-text{
    &:not(:last-child){
      margin-bottom: 53px;
    }
    &:last-child{
      .choose-text-img{
        &:after{
          display: none;
        }
      }
    }
    .choose-text-img{
      width: 90px;
      min-width: 90px;
      height: 90px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include border-radius(50%);
      background-color: $theme-color-default;
      @include rtl-margin-lr(0, 20px);
      position: relative;
      &:after{
        content: "";
        position: absolute;
        bottom: -92px;
        @include rtl-left(50%);
        width: 1px;
        height: 92px;
        border-right: 1px dashed $theme-color-default;
      }
    }
    .choose-text{
      margin-top: 34px;
      h2{
        font-size: 18px;
        font-weight: 600;
        color: $theme-color-primary;
        margin-bottom:11px;
        padding-bottom: 8px;
        line-height: 1;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          @include rtl-left(0);
          bottom: 0;
          width: 133px;
          height: 1px;
          background-color: $theme-color-default;
        }
      }
      p{
        font-size: 14px;
        color: $theme-color-primary;
        line-height: 24px;
      }
    }
  }
  .our-story-content{
    padding-top: 115px;
    padding-bottom: 80px;
    .our-story-text{
      h3{
        font-size: 3.2rem;
        font-weight: 600;
        color: $theme-color-primary;
        margin-bottom: 14px;
      }
      span{
        font-size: 18px;
        font-weight: 500;
        color: #505050;
        padding-bottom: 44px;
        display: inline-block;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: #505050;
        line-height: 24px;
        padding-bottom: 43px;
      }
      .readmore-story{
        display: inline-block;
        padding: 18px 57px;
        font-size: 16px;
        font-weight: 600;
        color: $theme-color-primary;
        @include box-shadow(0 3px 5px rgba(0,0,0,0.10));
        background: $theme-color-default;
        @include border-radius(5px);
        @include transition(all 0.35s ease);
        &:hover{
          color: $white;
        }
      }
    }
  }
  &.pagehome-one {
    .policy-home{
      padding-top: 125px;
      padding-bottom: 130px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top:-150px;
        @include rtl-left(0);
        width: 284px;
        height: 235px;
        background-image: url(../img/logo-bf-policy.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: -90px;
        @include rtl-right(0);
        width: 350px;
        height: 252px;
        background-image: url(../img/logo-af-policy.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
    }
    .testimonial-home{
      position: relative;
      padding-top: 130px;
      &:before{
        content: "";
        position: absolute;
        top:-24px;
        @include rtl-right(0);
        width: 265px;
        height: 266px;
        background-image: url(../img/logo-bf-testimonial.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: 60px;
        @include rtl-left(0);
        width: 253px;
        height: 236px;
        background-image: url(../img/logo-af-testimonial.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
    }
    .our-story{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: -30px;
        @include rtl-left(0);
        width: 100%;
        height: 40px;
        background-image: url(../img/bg-bf-our-story.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: -30px;
        @include rtl-left(0);
        width: 100%;
        height: 40px;
        background-image: url(../img/bg-af-our-story.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
    }
  }
  &.pagehome-two{
    .our-story{
      position: relative;
      &:after{
        content: "";
        position: absolute;
        bottom: -30px;
        @include rtl-left(0);
        width: 100%;
        height: 40px;
        background-image: url(../img/bg-af-our-story.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
    }
    .our-story-content{
      padding-top: 125px;
    }
    .info-about-content{
      span{
        font-size: 18px;
        font-weight: 500;
        color: #505050;
        display: inline-block;
        padding-bottom: 12px;
      }
      h3{
        font-size: 3.2rem;
        font-weight: 600;
        color: $theme-color-primary;
        margin-bottom: 54px;
      }
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          font-size: 15px;
          font-weight: 500;
          color: $theme-color-primary;
          @include flexbox();
          @include align-items(center);
          padding-bottom: 10px;
          &:last-child{
            padding-bottom: 0;
          }
          .icon-novcheck{
            display: inline-block;
            @include square(40px);
            background-image: url('../img/icon/icon-check.png');
            background-repeat: no-repeat;
            background-position: center center;
            @include rtl-margin-lr(0,20px);
          }
        }
      }
      .discover{
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        background: $theme-color-primary;
        padding: 18px 44px;
        @include transition(all 0.35s ease);
        @include border-radius(5px);
        margin-top: 60px;
        &:hover{
          background: $theme-color-default;
        }
      }
    }
    .info-about{
      padding-top: 205px;
      padding-bottom: 130px;
    }
    .banner-home{
      padding-bottom: 130px;
    }
    .testimonial-home{
      padding-top: 98px;
    }
    .nov-blog{
      padding-top: 0;
      padding-bottom: 75px;
    }
    .group-productlist-home{
      padding-top: 130px;
      padding-bottom: 130px;
    }
  }
  &.pagehome-three{
    .container-box{
      @media (min-width: 1200px){
        width: 1200px!important;
        max-width: 1200px!important;
        margin: 0 auto;
        @include rtl-padding-lr(15px, 15px);
      }
    }
    .product-deal-home{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top:-100px;
        @include rtl-right(-350px);
        width: 170px;
        height: 240px;
        background-image: url(../img/bg-bf-deal.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: 60px;
        @include rtl-left(-352px);
        width: 214px;
        height: 177px;
        background-image: url(../img/bg-af-deal.png);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
      }
      @media  (max-width: 1919px){
        &:before, &:after{
          display: none;
        }
      }
    }
    .policy-home{
      padding-top: 130px;
      padding-bottom: 130px;
      .nov-html{
        >.block{
          @include border-radius(5px);
          border: 1px solid $theme-color-default;
        }
      }
    }
    .testimonial-home{
      padding-top: 120px;
    }
  }
}

//Footer Style
.footer {
  .links {
    a {
      color: #9f9d9d;
      font-size: 1.4rem;
      &:hover {
        color: $theme-color-default;
      }
    }
    .list-inline {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include flex-direction(row-reverse);
      @include flex-wrap(wrap);
      .list-inline-item {
        @include rtl-padding-right(17px);
        position: relative;
        a{
          font-size: 1.4rem;
          color: #9f9d9d;
          font-weight: 400;
          @include transition(all 0.35s ease);
          text-transform: capitalize;
          &:hover{
            color: $theme-color-default;
          }
        }
        &:last-child{
          @include rtl-margin-right(5px);
        }
        &:first-child {
          @include rtl-padding-right(0);
          &:before{
            display: none;
          }
        }
      }
    }
  }
  .data{
    .content-data{
      p{
        font-weight: 400;
        font-size: 14px;
        color: $theme-color-primary;
      }
    }
  }
  .payment-image-contact{
    padding-top: 18px;
  }
  

  &.footer-one {
    .inner-footer{
      .footer-center{
        padding-top: 126px;
        padding-bottom: 80px;
      }
      .newsletter-home{
        .nov-modules{
          padding-top: 180px;
          padding-bottom: 180px;
          .block{
            .title_block{
              @include flexbox();
              @include align-items(center);
              @include justify-content(center);
              @include flex-direction(column-reverse);
              font-size: 3.2rem;
              font-weight: 600;
              color: $theme-color-primary;
              margin-bottom: 50px;
              &:after{
                display: none;
              }
              .sub_title{
                font-size: 15px;
                text-align: center;
                font-weight: 400;
                color: #6e6e6e;
                margin-top: 16px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    #nov-copyright{
      background-image: url('../img/bg-copyright-h1.jpg');
      background-repeat: no-repeat;
      background-position: top center;
      padding-top: 176px;
      background-color: $theme-color-default;
      .content-logo-copyright{
        .logo-footer{
          padding-bottom: 30px;
        }
        .content-logo{
          font-family: $font-family-default;
          font-size: 15px;
          font-weight: 400;
          color: $theme-color-primary;
          line-height: 23px;
          text-align: center;
          max-width: 470px;
          margin: auto;
        }
      }
      .text-copyright{
        padding-top: 32px;
        border-top: 1px solid #fad667;
        padding-bottom: 18px;
        span{
          font-size: 14px;
          color: #414141;
          font-weight: 400;
        }
      }
    }
  }
  &.footer-two{
    background-image: url('../img/bg-copyright-h1.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 176px;
    background-color: $theme-color-default;
    .content-logo-copyright{
      border-bottom: 1px solid #fad667;
      padding-bottom: 64px;
      .logo-footer{
        padding-bottom: 30px;
      }
      .content-logo{
        font-family: $font-family-default;
        font-size: 15px;
        font-weight: 400;
        color: $theme-color-primary;
        line-height: 23px;
        text-align: center;
        max-width: 470px;
        margin: auto;
      }
    }
    .inner-footer{
      padding-top: 70px;
      padding-bottom: 64px;
      .footer-center{
        .title_block{
          &:after{
            background: $white;
          }
        }
        .block_content{
          ul li {
            a{
              &:hover{
                color: $white;
              }
            }
          }
        }
        .block_newsletter{
          .social-copyright{
            margin-top: 44px;
          }
        }
      }
    }
    #nov-copyright{
      padding: 33px 0;
      border-top: 1px solid #fad667;
      background: transparent;
      .text-center{
        span{
          font-size: 14px;
          color: #414141;
          font-weight: 400;
        }
      }
    }
  }
  &.footer-three{
    .inner-footer{
      background-image: url('../img/bg-footer-3.jpg');
      background-repeat: no-repeat;
      background-position: top center;
      padding-top: 130px;
      .container{
        @media (min-width:1200px){
          width: 1200px;
          max-width: 1200px;
          padding-right: 15px;
          padding-left: 15px;
          margin: 0 auto;
        }
      }
      .newsletter-home{
        .nov-modules{
          >.block{
            border-bottom: 1px solid #fcdf85;
            padding-bottom: 66px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            .title_block, .block_newsletter{
              width: 50%;
            }
            .title_block{
              padding: 0;
              margin: 0;
              font-size: 3.2rem;
              font-weight: 400;
              color: $theme-color-primary;
              @include flexbox();
              @include flex-direction(column-reverse);
              h4{
                font-size: 3.2rem;
                font-weight: 400;
                color: $theme-color-primary;
                span{
                  font-weight: 600;
                }

              }
              .sub_title{
                font-size: 15px;
                font-weight: 400;
                color: $theme-color-primary;
                margin-top: 10px;
                margin-bottom: 0;
                text-transform: initial;

              }
            }
          }
        }
      }
      .footer-center{
        padding-top: 88px;
        padding-bottom: 180px;
        .nov-html{
          .block_content{
            .logo-footer{
              margin-bottom: 32px;
            }
            .content-text{
              font-size: 15px;
              color: $theme-color-primary;
              line-height: 2.2;
            }
            .social-copyright{
              margin-top: 49px;
              ul li a{
                font-size: 0;
              }
            }
            ul li a{
              &:hover{
                color: $white;
              }
            }
          }
        }
      }
    }
    #nov-copyright{
      padding: 33px 0;
      background: $theme-color-default;
      .text-center{
        span{
          font-size: 14px;
          font-weight: 400;
          color: $theme-color-primary;
        }
      }
    }
  }
}