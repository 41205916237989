/*******************************************************************
          novMEGAMENU STYLE
********************************************************************/
@media (min-width: 767px) {
  .nov-megamenu {
    text-align: center;
    .dropdown-menu {
      visibility: hidden;
      @include opacity(0);
      z-index: 100;
      background-color: #fff;
      display: none;
      padding: 0;
      margin: 0;
      @include rtl-left(0);
      border: none;
      @include border-radius(0);
      @include transition(all 0.3s ease);
    }

    .menu {
      margin: 0;
      padding: 0;
      > li {
        display: inline-block;
        list-style: none;
        position: relative;
        padding: 30px 25px 35px 25px;
        //Menu leve1
        > a {
          display: block;
          font-family: $mainmenu-font-family;
          font-size: $mainmenu-font-size;
          font-weight: 600;
          padding: 5px 0;
          line-height: 100%;
          color: $theme-color-primary;
          position: relative;
          text-transform: capitalize;
          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            @include rtl-left(0);
            width: 0;
            height: 2px;
            background: #212121;
            @include transition(all 0.35s ease);

          }
          i {
            display: none;
            font-size: 15px;
            @include rtl-margin-right(7px);
          }
        }
        &:first-child{
          @include rtl-padding-lr(0, 25px);
        }
        >.dropdown-menu{
          background: transparent;
          >ul{
            background: $white;
          }
        }
        //Icon has child menu
        &.group, &.has-sub {
            position: relative;
        }

        //Menu active lever 1
        &.active{
          >a {
            &:after {
              width: 100%;
            }
          }
        }
        // Menu when hover lever1
        &:focus,
        &:hover {
          > a {
            &:after{
              width: 100%;
            }
          }
          > .dropdown-menu{
            @include opacity(1);
            visibility: visible;
            display: block;
          }
        }
        //Menu title
        .menu-title {
          color: $theme-color-primary;
          font-weight: 700;
          font-family: $font-family-default;
          font-size: 1.5rem;
          padding-top: 22px;
          display: block;
          border-bottom: none;
          text-transform: capitalize;
        }


        //Submenu
        &.has-sub {
          > .dropdown-menu {
            @include box-shadow(0 0px 10px 0px rgba(0,0,0,0.05));
            @include rtl-left(0px);
            li.has-sub {position: relative;}
            .has-sub{
              .opener{
                &:before{
                  content: "\f105 ";
                  position: absolute;
                  top: 3px;
                  @include rtl-right(25px);
                  font-family: $font-icon;
                  font-size: 12px;
                  color: #999;
                  @include transition(all 0.35s ease);
                }
              }
              &:hover{
                .opener{
                  &:before{
                    color: $theme-color-default;
                  }
                }
              }
            }
            ul {
              @include rtl-text-align-left();
              padding: 0;
              @include border-radius(5px);
              @include box-shadow(0 0 10px 0 rgba(0,0,0,0.05));
              li {
                display: block;
                @include rtl-padding(5px, 10px, 14px, 30px);
                a {
                  display: inline-block;
                  font-size: 15px;
                  color: #414141;
                  font-weight: 500;
                  @include transition(all 0.35s ease);
                  line-height: 1.3;
                }
                &:first-child {
                  padding-top: 23px;
                }
                &:last-child{
                  padding-bottom: 26px;
                }
                &:hover {
                  > a {
                    color: $theme-color-default;
                    border-color: $theme-color-default;
                    
                  }
                }
              }
            }
            .dropdown-menu {
              position: absolute;
              @include rtl-left(100%);
              top: 0;
              padding: 0;
              margin: 0;
              border: 0;
              @include border-radius(0);
              @include box-shadow(0 0px 5px 0px rgba(0,0,0,0.05));
            }
            li:hover {
              > .dropdown-menu {
                @include opacity(1);
                visibility: visible;
                display: block;
              }
            }
          }
        }
        &.group {
          &.shop-mega{
            position: static;
            >.dropdown-menu{
              @include rtl-left(50%);
              @include translateX(-50%);
              @include border-radius(5px);
              >ul{
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 24px;
                @include border-radius(5px);
              }
              .menu-content{  
                .menu-title{
                  font-size: 15px;
                  color: $theme-color-primary;
                  font-weight: 700;
                  text-transform: capitalize;
                  padding-bottom: 5px;
                  position: relative;
                }
                ul{
                  li{
                    &:last-child{
                      margin-bottom: 0;
                    }
                    a{
                      font-size: 14px;
                      font-weight: 400;
                      color: #222222;
                      &:hover{
                        color: $theme-color-default;
                      }
                    }
                  }
                }
              }
            }
          }
          &.blog-mega{
            position: static;
            >.dropdown-menu{
              @include rtl-left(50%);
              @include translateX(-50%);
              @include border-radius(5px);
              >ul{
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 24px;
                @include border-radius(5px);
              }
            }
          }
          > .dropdown-menu {
            padding: 0px 15px 0px 15px;
            @include box-shadow(0 0px 5px 0px rgba(0,0,0,0.05));
            background-color: $white;
            @include border-radius(5px);
            > ul {
              @include border-radius(5px);
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-right: -15px;
              margin-left: -15px;
              li{
                a{
                  color: $text-color;
                  font-size: 1.4rem;
                  font-weight: 500;
                  text-transform: capitalize;
                  &:hover{
                    color: $theme-color-default;
                  }
                }
              }
              > .has-sub {
                > .dropdown-menu {
                  > ul {
                    > li {
                      margin-bottom: 14px;
                      > a {
                        color: #666;
                        text-transform: uppercase;
                        &:hover {
                          color: $theme-color-primary;
                        }
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
            .has-sub {
              > a {
                text-transform: uppercase;
                color: #222222;
                font-weight: 700;
                font-family: "Poppins",sans-serif;
                text-transform: uppercase;
                font-size: 1.6rem;
                margin-bottom: 10px;
                padding-bottom: 10px;
                display: block;
                border-bottom: none;
              }
            }
            .dropdown-menu {
              visibility: visible;
              @include opacity(1);
              position: relative;
              @include rtl-left(inherit);
              top: inherit;
              float: none;
              background: transparent;
              font-size: 1.1rem;
              color: #666;
              display: block;
            }
          }
          ul {
            padding: 0;
            list-style: none;
          }

          //menu-content
          .menu-content {
            .menu-title{
              margin-bottom: 3px;
              &:last-child{
                padding-top: 56px!important;
              }
            }
            ul {
              padding-top: 30px;
              padding-bottom: 25px;
              li {
                margin-bottom: 17px;
                a {
                  font-size: 14px;
                  color: $theme-color-primary;
                  font-weight: 400;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .product-image-container {
      a {
         padding: 0px;
      }
    }
  }

  #nov-megamenu {
    @include transition(all 0.3s ease);
    &.nov-megamenu--fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      background: #f1f6f8;
      @include box-shadow(0px 5px 5px -5px #999);
      @include transition(top,height,background 0.3s ease);
    }
    &.nov-megamenu_vertical {
      .nov-megamenu .menu > li {
        display: block;
        > a {
          font-size: 12px;
          font-weight: 500;
          padding: 17px 20px;
          color: #666;
          &:hover {
            color: $theme-color-default;
          }
          &:after {
            display: none;
          }
        }
        &.active {
          > a {
            color: $theme-color-default;
          }
        }
        &.has-sub > .dropdown-menu {
          @include rtl-left(100%);
          top: 0;
          .dropdown-menu {
            @include box-shadow(0 5px 7px rgba(0, 0, 0, 0.35));
          }
        }
        &.group > .dropdown-menu {
          @include rtl-left(100%);
          top: 0;
        }
        &.active {
          &:after {display: none;}
        }
        &:hover, &:focus {
          &:after {display: none;}
        }
        .opener {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: 14px;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: $font-icon;
          width: 20px;
          height: 20px;
          position: absolute;
          @include rtl-right(12px);
          top: 50%;
          margin-top: -10px;
          color: #666;
          font-size: 14px;
          line-height: 20px;
          z-index: 1;
          &:before {
            content: "\f105";
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  span.opener {
    position: absolute;
    @include rtl-right(0);
    z-index: 1;
    padding: 10px;
    display: block;
    top: -2px;
    cursor: pointer;
    &:before {
      content: '\f278';
      font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
      font-size: 15px;
      text-rendering: auto;
    }
  }
  .menu-active {
    > span.opener {
      &:before {
        content: '\f273';
      }
    }
  }
  #nov-megamenu {
    .dropdown-menu {
      position: relative;
      width: 100% !important;
      top: inherit;
      float: none;
      @include rtl-left(inherit);
      border: none;
      padding: 0;
      ul {
        padding: 0;
        list-style: none;
      }
    }
    .nov-megamenu {
      .menu {
        @include rtl-padding-left(0);
        margin-bottom: 0;
        list-style: none;
        .menu-title {
          padding: 8px 0;
          text-transform: capitalize;
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 0;
        }
        [class*=" col-"] {
          padding-left: 0;
          padding-right: 0;
        }
        > li {
          position: relative;
          padding: 8px 0;
          display: block;
          font-size: 11px;
          > a {
            font-family: $font-family-primary;
            text-transform: uppercase;
            font-weight: 400;
            i {
              font-size: 15px;
              @include rtl-margin-right(10px);
              min-width: 20px;
              text-align: center;
            }
          }
          a {
            position: relative;
            z-index: 2;
          }
          .dropdown-menu{
            .menu-content{  
              li {
                padding: 8px 0;
                border-bottom: 1px dotted $base-border-color;
                font-size: 11px;
              }  
            }
          }
          &.has-sub{
            >.dropdown-menu{
              >ul{
                @include rtl-padding-left(25px);
                >li{
                  padding: 5px;
                  a{
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
        li.group {
          position: relative;
          > .dropdown-menu {
            > ul {
              @include rtl-padding-left(30px);
              padding-top: 10px;
              > li {
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          .group {
            border-bottom: none;
            padding: 0;
            margin-bottom: 10px;
            > .dropdown-menu {
              > ul {
                @include rtl-padding-left(0);
                padding-top: 0px;
                li {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }

      }
    }
  }
}
@media( max-width: $screen-md-max)  and (min-width: $screen-md){
  .nov-megamenu{
    .menu{
      >li{
        &.category-mega{
          >.dropdown-menu{
            width: 730px!important;
          }
        }
        &.collection-mega{
          position: static;
          >.dropdown-menu{
            @include rtl-left(50%);
            @include translateX(-50%);
            width: 738px!important;
          }
          
        }
      }
    }
  }
  .nov-megamenu_vertical{
    .nov-megamenu{
      .menu{
        >li{

          &.category-mega{
            position: relative!important;
            >.dropdown-menu{
              width: 738px!important;
            }
          }
          &.collection-mega{
            position: relative!important;
            >.dropdown-menu{
              width: 738px!important;
              @include translateX(0);
            }
          }
        }
      }
    }
  }
}
@media( max-width: $screen-sm-max)  and (min-width: $screen-sm){
  .nov-megamenu{
    .menu{
      >li{
        &.category-mega{
          >.dropdown-menu{
            width: 630px!important;
          }
        }
        &.collection-mega{
          position: static;
          >.dropdown-menu{
            width: 630px!important;
            @include rtl-left(50%!important);
            @include translateX(-50%);
          }
          
        }
      }
    }
  }
  .nov-megamenu_vertical{
    .nov-megamenu{
      .menu{
        >li{
          &.category-mega{
            position: relative!important;
            >.dropdown-menu{
              width: 514px!important;
            }
          }
          &.collection-mega{
            position: relative!important;
            >.dropdown-menu{
              width: 514px!important;
              @include translateX(0);
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1800px) and (min-width: 1200px){
  .nov-megamenu{
    .menu{
      >li{
        &.collection-mega{
          position: static;
          >.dropdown-menu{
            @include rtl-left(50%);
            @include translateX(-50%);
          }
          
        }
      }
    }
  }
}