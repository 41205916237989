@import 'owl-carousel/core';
@import 'owl-carousel/animate';
@import 'owl-carousel/autoheight';
@import 'owl-carousel/lazyload';
@import 'owl-carousel/video';

/*
 * 	Default theme - Owl Carousel CSS File
 */
$color-base:			#869791 !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

//nav
$nav-color-hover:		$color-white !default;
$nav-font-size: 		14px !default;
$nav-rounded: 			3px !default;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				10px !default;
$dot-height:			10px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$color-base !default;

@import 'owl-carousel/theme';