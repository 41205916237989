// BREADCRUMB
.breadcrumb {
  background: #f2f2f2;
  color: $theme-color-primary;
  font-size: 1.5rem;
  margin-bottom: 13rem;
  @include border-radius(0);
  padding: 115px 0;
  .title-breadcrumb{
    text-align: center;
    font-size: 3.2rem; 
    font-weight: 600;
    color: #212222;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  ol {
    text-align: center;
    font-family: $font-family-default;
    margin: 0;
    padding: 0;
    padding-top: 5px;

    li {
      padding: 0 20px;
      position: relative;
      display: inline-block;
      color: $breadcrumb-link-color;
      a {
        font-size: 15px;
        font-weight: 400;
        color: $theme-color-primary;
        &:hover {
          color: $theme-color-default;
        }
      }
      &:before {
        content: "\e916";
        font-family: $font-icon-tello;
        font-size: 13px;
        color: $theme-color-primary;
        position: absolute;
        top: 50%;
        @include translateY(-50%);
        @include rtl-left(-4px);
      }
      &:first-child {
        @include rtl-padding-lr(20px,20px);
         &:before {
          display: none;
         }
      }
      &:last-child{
        @include rtl-padding-lr(20px,20px);
      }
    }
  }
}