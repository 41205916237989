// Prevent long urls and text from breaking layouts
// [originally from perishablepress.com](http://perishablepress.com/press/2010/06/01/wrapping-content/)
@mixin force-wrap {
  white-space: pre;           // CSS 2.0
  white-space: pre-wrap;      // CSS 2.1
  white-space: pre-line;      // CSS 3.0
  white-space: -pre-wrap;     // Opera 4-6
  white-space: -o-pre-wrap;   // Opera 7
  white-space: -moz-pre-wrap; // Mozilla
  white-space: -hp-pre-wrap;  // HP Printers
  word-wrap: break-word;      // IE 5+
}
