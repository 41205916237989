#product {
  #main{
    margin-bottom: 6rem;
  }
	#content {
	    position: relative;
	}
	.detail-product-name {
		font-family: $font-family-primary;
		color: $theme-color-primary;
		font-size: 2.8rem;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 26px;
	}
  .product-description-short{
    font-size: 15px;
    font-weight: 400;
    color: $theme-color-primary;
    line-height: 24px;
  }
  .group-reviews{
    margin-top: 21px;
    margin-bottom: 33px;
    padding-bottom: 33px;
    border-bottom: 1px solid #f2f2f2;
  }
  .group-price {
    margin-bottom: 31px;
  }
	.product-prices {
		.current-price {
      font-family: $font-family-primary;
			font-size: 2.8rem;
			color: #e55b2a;
			font-weight: 600;
      line-height: 1;
      .regular-price {
        font-size: 1.5rem;
        color: #6e6e6e;
        font-weight: 600;
        @include rtl-margin-lr(0, 10px);
        text-decoration: line-through;      }
		}
	}
	.product-information {
    line-height: 20px;
    font-size: 1.4rem;
		#product-description-short-2 {
			font-size: 1.4rem;
      color: #6c6c6c;
		}
    .product-description-short{
      font-size: 14px;
      color: #6c6c6c;
      line-height: 22px;
    }
    .product-actions{

    }
	}
  .images-container {
    &.thumb-vertical {
      .js-qv-mask {
        width: 180px;
        @include rtl-margin-right(10px);
        .product-images {
          margin-top: 0;
          height: 100%;
        }
        .slick-slide {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .slick-images{
          position: relative;
          .slick-arrow{
            @include square(30px);
            display: none!important;
            @include align-items(center);
            border: 1px solid #f3f3f3;
            position: absolute;
            @include rtl-left(50%);
            @include translateX(-50%);
          }
        }
        .slick-prev, .slick-next {
          width: 100%;
        }
      }
      @media (max-width: 1024px) {
        .js-qv-mask {
          width: 100%;
          margin-top: 5px;
          @include rtl-margin-right(0);
        }
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
        .slick-list{
          .slick-slide{
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
	.product-cover {
		position: relative;
    background: #f5f5f5;
		.layer {
			position: absolute;
    		@include rtl-right(8px);
    		bottom: 1px;
    		@include rotate(90deg);
    		font-size: 28px;
    		cursor: pointer;
        color: #444;
    		&:hover {
    			color: $theme-color-default;
    		}
		}
	}
	.product-images {
		padding: 0;
		margin: 0;
		margin-top: 5px;
		list-style: none;
		.thumb-container {
			display: inline-block;
      position: relative;
      @include transition(all 0.3s ease);
      outline: none;
      .js-thumb{
        border: 1px solid transparent;
      }
      .selected{
        border: 1px solid $theme-color-default;
      }
      &:hover {
        img{
          border: 1px solid $theme-color-default;
        }
        
      }
      .thumb {
        max-width: 100%;
        @include border-radius(5px);
			}
		}
    .owl-nav{
      display: none;
    }
	}
}
.product-information {
  .manufacturer-logo {
    height: 35px;
  }
}
.input-container {
  position: relative;
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

.input-color{
  &:checked + span,
  &:hover + span {
    border: 2px solid #bbbbbb;

  }
}

.input-radio {
  cursor: pointer;
}
.product-actions {
  margin-top: 19px;
  .control-label {
    display: inline-block;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 0;
    vertical-align: middle;
    color: #666766;
    @include rtl-margin-right(5px);
    display: none;
  }
}

.product-quantity {
  display: inline-block;
  vertical-align: middle;
  .qty {
    display: inline-block;
    vertical-align: top;
    @include rtl-margin-lr(0, 20px);
    .input-group-btn-vertical{
      .btn-touchspin{
        position: absolute;
        top: 50%;
        @include translateY(-70%);
        &.bootstrap-touchspin-up{
          @include rtl-right(10px);
        }
        &.bootstrap-touchspin-down{
          @include rtl-left(10px);
        }
      }
    }
  }
  .add {
    display: inline-block;
    vertical-align: top;
    .add-to-cart {
      @include border-radius(3px);
      min-width: 130px;
      padding: 0;
      border-color: #f2f2f2;
      color: #999999;
      background: transparent;
      font-size: 1.3rem;
      font-weight: 600;
      height: 40px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include transition(all 300ms ease-in-out 0s);
      i.shopping-cart {
        display: inline-block;
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          width: 24px;
          height: 19px;
          background-position: 0px -26px;
          @include rtl-margin-lr(0,0);
      }
      span {
        vertical-align: middle;
        line-height: 1;
        font-size: 13px;
        color: #999999;
        text-transform: capitalize;
        @include rtl-margin-lr(8px,4px);
      }
      &:hover {
        background: $theme-color-default;
        border-color: $theme-color-default;
        span{
          color: $theme-color-primary;
        }
        i.shopping-cart {
          background-position: 0px 0px;
        }
      }
    }
  }
  #quantity_wanted {
    color: $theme-color-primary;
    background-color: white;
    height: 40px;
    width: 130px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid #f2f2f2;
    @include border-radius(3px);
    z-index: 1;
    text-align: center;
  }
  .addToWishlist {
    font-size: 0;
    width: 40px;
    height: 40px;
    min-width: 40px;
    text-align: center;
    background: #f3f3f3;
    @include border-radius(3px);
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include opacity(1);
    @include rtl-margin-lr(5px ,0);
    @include transition(all 300ms ease-in-out 0s);
    position: relative;
    .nov-icon-wishlist{
      display: inline-block;
      @include size(19px, 19px);
      background-image: url('../img/icon/icon-group-header.png');
      background-position: -97px -0px;
      background-repeat: no-repeat;
    }
    &:hover{
      background: $theme-color-default !important;
      border-color: $theme-color-default !important;
      .nov-icon-wishlist{
        background-position: -97px -26px;
      }
    }
  }

  .wishlist {
    display: inline-block;
  }
}

.product-discounts {
  margin-bottom: rem(24px);
  > .product-discounts-title {
    font-weight: normal;
    font-size: 14px;
  }
  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: 6px 12px;
      background: white;
      border: 5px #ccc solid;
      text-align: center;
    }
    tbody tr {
      background: #ccc;
      &:nth-of-type(even) {
        background: white;
      }
      td {
        padding: 6px 12px;
        text-align: center;
        border: 5px #ccc solid;
      }
    }
  }
}

.sku {
  font-weight: 500;
  .control-label{

  }
}
.pro-cate {
  a {
    color: $theme-color-primary;
    &:hover {color: $theme-color-default;}
  }
}

.tabs {
  margin-top: 4.5rem;
  background: white;
  font-size: 1.2rem;
  line-height: 1.67;
  .tab-pane {
    padding: 35px 25px 35px 25px;
    background: #f7f8f8;
    .product-description{
      p{
        font-size: 13px;
        font-weight: 400;
        color: #6e6e6e;
        line-height: 24px;
      }
    }
  }
  .nav-tabs {
    border: none;
    display: block;
    overflow: hidden;
    .nav-item{
      .nav-link {
        color: $text-color-custom;
        border: none;
        font-size: 16px;
        line-height: 1;
        font-weight: 400; 
        font-family: $font-family-primary;
        padding: 12px 30px;
        text-transform: capitalize;
        position: relative;
        &:before{
          content:"";
          position: absolute;
          top: 0px;
          @include rtl-left(30px);
          background: transparent;
          width: calc(100% - 60px);
          height: 1px;
        }
        &.active,&:hover {
          background: #f7f8f8;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          &:before{
            background: $theme-color-default;
          }
        }
        .count-comment {
          color: #d7d7d7;
          font-size: 1.3rem;
          font-weight: 400;
          display: inline-block;
          vertical-align: middle;
          font-family: $font-family-default;
          @include rtl-margin-left(5px);
        }
      }
    }
    
    .nav-item {
      @include rtl-float-left();
    }
  }
  .tab-content{
    .product-description{
      font-size: 1.4rem;
      font-weight: 400;
      color: $text-color;
      line-height: 22px;
      p{
        margin-bottom: 23px;
        &:last-child{
          margin-bottom: 13px;
        }
      }
    }
  }
}
.accordion{
  margin-top: 5rem;
  .nav-tabs{
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    border: none;
    .nav-item{
      margin: 0;
      width: 100%;
      padding-bottom: 10px;
      .nav-link{
        font-size: 16px;
        font-weight: 400;
        color: $text-color-custom;
        text-transform: capitalize;
        text-align: center;
        border:none;
        background: #f7f8f8;
        @include border-radius(5px);
        padding: 18px 55px;
        &.active{
          background: $theme-color-default;
          color: $white;
        }
      }
      
      &:hover, {
        a{
          background: $theme-color-default;
          color: $white;
        }
      }
    }
  }
  .tab-content{
    background: #f7f8f8;
    padding: 30px 20px 30px 20px;
    @include border-radius(5px);
    .product-description{
      p{
        font-size: 1.4rem;
        color: #666766;
        line-height: 22px;
        margin-bottom: 23px;
      }
    }
  }
}
#product-modal {
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;
    @include box-shadow(none);
    .modal-body {
      @include display(flex);
      @include rtl-margin-left(-46px);
      figure {
        margin-bottom: 0;
      }
      .product-cover-modal {
        background: white;
      }
      .image-caption {
        background: white;
        padding: 10px 20px;
        border-top: #ccc 1px solid;
        p {
          margin-bottom: 0;
        }
      }
      .thumbnails {
        position: relative;
      }
      .mask {
        position: relative;
        overflow: hidden;
        max-height: 610px;
        z-index: 1;
        &.nomargin {
          margin-top: 0;
        }
        .thumb-container {
          margin-top: 10px;
        }
      }
      .product-images {
        margin-left: 10px;
        margin-top: 0;
        img {
          width: 85px;
          cursor: pointer;
          background: white;
        }
        .thumb-container {
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .arrows {
        .arrow-up {
          position: absolute;
          bottom: 36px;
          opacity: 0.2;
          z-index: 1;
        }
        .arrow-down {
          position: absolute;
          bottom: 0;
        }
        i {
          font-size: 18px;
          display: inline;
          cursor: pointer;
          width: 85px;
          height: 35px;
          line-height: 35px;
          left: 10px;
          text-align: center;
          background: #b2b2b2;
          color: #fff;
          &:hover {
            background: $theme-color-primary;
          }
        }
      }
    }
  }
}

.js-qv-mask {
  .owl-nav {
    [class*='owl-'] {
      visibility: hidden;
      @include opacity(0);
      @include scale(0,0);
      @include transition(all 0.35s ease 0.1s);
      top: 50%;
      margin-top: -15px;
      font-size: 19px;
    }
    .owl-next {
      right: -15px;
    }
    .owl-prev {
      right: inherit;
      left: -15px;
    }

  }
  &:hover {
    .owl-nav {
      [class*='owl-'] {
        visibility: visible;
        @include opacity(1);
        @include scale(1,1);
      }
    }
  }
}

.in_border {
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
    &.end {
      display: none;
      border-bottom: none;
      padding-bottom: 20px;
      margin-bottom: 0;
      line-height: 25px;
      .sku{
        margin-bottom: 0px;
        .control-label{
          font-size: 12px;
          font-weight: 400;
          color: #666766;
          text-transform: uppercase;
        }
        span{
          font-size: 1.2rem;
          font-weight: 700;
          color: #222222;
          text-transform: uppercase;
        }
      }
      .pro-cate, .pro-tag{
        margin-bottom: 0px;
        .control-label{
          font-size: 12px;
          font-weight: 400;
          color: #666766;
          text-transform: uppercase;
        }
        span{
          a{
          font-size: 12px;
          font-weight: 700;
          color: #222222;
          &:hover{
            color: $theme-color-default;
          }
          }
        }
      }
    }
}
.product-availability {
  display: inline-block;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 400;
  color: $theme-color-default;
  .material-icons {
    line-height: inherit;
  }
  .control-label {
    margin-bottom: 0;
    color: $text-color;
    display: none;
  }
  i {
    font-size: 1.6rem;
    @include rtl-margin-lr(5px,8px);
  }
  .product-available {
    color: #5fbb52;
  }
  .product-unavailable {
    
  }
  .product-last-items {
    
  }
}
.pro-tag, .pro-cate {
  span {
    &:after {
      content: ",";
      display: inline-block;
      color: #444;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  a {
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
    color: #444;
    &:hover {
      color: $theme-color-default;
    }
  }
}
.product-features {
  margin-top: 10px;
  > dl.data-sheet {
    @include display(flex);
    @include flex-wrap(wrap);
    @include align-items(flex-start);
    dd.value,
    dt.name {
      @include flex(1 0 40%);
      font-weight: normal;
      background: #e7e7e7;
      padding: 5px;
      margin-right: 5px;
      min-height: rem(40px);
      word-break: break-all;
      &:nth-of-type(even) {
        background: #e7e7e7;
      }
    }
    dt.name {
      text-transform: capitalize;
    }
  }
}
.product-variants {
  padding-bottom: 0;
  line-height: 1;
  > .product-variants-item {
    &:last-child{
      padding-bottom: 20px;
    }
    padding-bottom: 30px;
    .control-label{
      font-size: 15px;
      font-weight: 600;
      color: $theme-color-primary;
      display: block;
      @include rtl-margin-lr(0,0);
      margin-bottom: 25px;

    }
    select {
      border: 1px solid #dfdfdf;
      height: 43px;
      min-width: 14rem;
      @include border-radius(3px);
      font-family: $font-family-default;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 0 20px;
      color: $text-color;
      &:hover, &:focus {
          outline: none;
      }
    }
    ul {
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      li {
        margin-right: 5px;
        display: inline-block;
        line-height: 1;
      }
    }
    .input-color {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      @include border-radius(50%);

    }
    .color {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      @include border-radius(3px);
      @include square(20px);
    }
    .group-radio{
      .input-container{
        @include rtl-margin-lr(0, 27px);
        label{
          font-size: 14px;
          color: #999999;
          font-weight: 400;
          margin: 0;
          padding-bottom: 3px;
          border-bottom: 1px solid transparent;
          cursor: pointer;
          .input-radio{
            @include opacity(0);
            display: none;
          }
          &.checked{
            border-bottom: 1px solid $theme-color-primary;
            color: $theme-color-primary;
          }
        }
      }
    }
  }
}

.product-add-to-cart {
  @include flexbox();
  @include align-items(center);
  padding: 0;
  margin: 0;
  .product-quantity{
    @include flexbox();
    @include align-items(center);
  }
  p {
    margin: 0;
  }
}

.productbuttons{
  display: none;
  padding-top: 4px;
  padding-bottom: 2.4rem;
  .social-sharing{
    span{
      font-size: 12px;
      font-weight: 400;
      color: #666766;
      text-transform: capitalize;
      i{
        color: $theme-color-default;
      }
    }
    &:hover{

      span{
        color: $theme-color-default;
      }
    }
  }
  a{
    span{
      font-size: 1.2rem;
      font-weight: 500;
      color: #666766;
      text-transform: capitalize;
      i{
        color: $theme-color-default;
      }
    }
    &:hover{
      span{
        color: $theme-color-default;
      }
    }
  }
}
.in_border-reviews{
  display: none;
  padding-top: 7px;
  padding-bottom: 19px;
}
li.product-flag {
    display: block;
    position: absolute;
    background: #ccc;
    font-weight: 700;
    padding: rem(5px) rem(7px);
    text-transform: uppercase;
    color: white;
    margin-top: 5px;
    margin-left: rem(-5px);
    z-index: 2;
    &.online-only{
      top: rem(400px);
      right: 0;
      font-size: 14px;
      &::before{
        content:"\E30A";
        font-family: 'Material Icons';
        vertical-align: middle;
        margin: rem(5px);
      }
    }
    &.discount {
      display: none;
    }
    &.on-sale {
      background: #ccc;
      width: 100%;
      text-align: center;
      margin: 0;
      left:0;
      top:0;
    }
}

.product-customization {
  margin: 1.5rem 0;
  .product-customization-item {
    margin: 1rem 0;
  }
  .product-message {
    background: #e7e7e7;
    border: none;
    width: 100%;
    height: rem(50px);
    resize: none;
    padding: rem(10px);
  }
  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: rem(42px);
    overflow: hidden;
    position: absolute;
  }
  .custom-file {
    position: relative;
    background: #e7e7e7;
    width: 100%;
    height: rem(42px);
    line-height: rem(42px);
    text-indent: rem(10px);
    display: block;
    color: #ccc;
    margin-top: 10px;
    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  small {
    color: #ccc;
  }
}
.product-pack {
  margin-top: 15px;
  .pack-product-container {
    @include display(flex);
    justify-content: space-around;
    .pack-product-name {
      @include flex(0 0 50%);
      font-size: 1.4rem;
      color: #ccc;
    }
    .pack-product-quantity {
      border-left: #e7e7e7 2px solid;
      padding-left: 5px;
    }
    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      @include display(flex);
      align-items: center;
    }
  }
}
.product-refresh {
  margin-top: 10px;
}

.productbuttons {
  .btn {
    border-color: transparent;
    background: transparent;
    font-weight: 500;
    cursor: pointer;
    color: $text-color;
    font-size: 1.1rem;
    text-transform: uppercase;
    padding: 0;
    font-family: $font-family-default;
    @include box-shadow(none);
    @include rtl-margin-right(2.5rem);
    &:hover,&:focus {
      background: transparent;
      border-color: transparent;
      color: $theme-color-default;
      text-decoration: none;

    }
    i {
      @include rtl-margin-right(1rem);
      color: $theme-color-default;
      font-size: 1.4rem;
    }
  }
  .social-sharing {
    display: inline-block;
    .dropdown-menu {
      padding: 0;
      i {
        @include rtl-margin-right(1rem);
      }
    }
  }
}

.products-selection {
  .title {
    color: #ccc;
  }
}
#blockcart-modal .cart-content {
  .btn {
    margin-bottom: 5px;
  }
}

.relate-product {
  padding-top: 13rem;
  margin-bottom: 13rem;
  .title_block {
    color: $theme-color-primary;
    font-family: $font-family-default;
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 50px;
    @include rtl-text-align-left();
  }
  .block_product_relate{
    margin: -70px -20px -20px -20px;
    overflow: hidden;
    .product_list{
      padding: 70px 20px 20px 20px;
      .owl-stage-outer{
        overflow: initial;
      }
      .product-miniature{
        position: relative;
      }
      .owl-nav{
        @include opacity(0);
        display: none;
        [class*='owl-']{
          background: transparent;
          top: 0px;
          @include translateY(0);
          color: #aaaaaa;
          width: auto;
          height: auto;
          @include transition(all 0.35s ease);
          width: 30px;
          height: 30px;
          font-size: 1.4rem;
          &:hover{
            background: $theme-color-default;
            border-color: $theme-color-default;
            i{
              color: $white;
            }
          }
        }
        .owl-next{
          @include rtl-right(60px);
          @include rtl-left(auto);
        }
        .owl-prev{
          @include rtl-right(20px);
        }
      }
      @media (max-width: 425px){
        .owl-nav{
          display: none;
          @include opacity(0);
        }
      }
    }
  }
  @media (max-width: 767px){
    margin-bottom: 2rem;
    padding-top: 6rem;
  }
}
.same-products{
  h2{
    font-family: $font-family-default;
    font-size: 2rem;
    color: $theme-color-primary;
    font-weight: 900;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }
  .product-miniature{
    .product-groups{
      .product-desc{
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body {
    @include flex-direction(column);
    margin-left: 0;
    img.product-cover-modal {
      width: 100%;
    }
    .arrows {
      display: none;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }
    .modal-body {
      padding: rem(30px);
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }
    .modal-body {
      padding: rem(16px);
    }
  }
}
