$rtl-left : left;
$rtl-right : right;
//------- Variables

@import "variables";
$image-path:                   "images/";
$font-family-path:             "../font/";
$image-theme-base-path:        "../img/";
$image-theme-path:             "../img/icon/";
$image-base-path:              "../../../img/";
$image-skin-path:              "../../img/icon/";
$image-module-path:            "../../../img/icon/";
$module-path:                  "../../../modules/";
$screen-xs-min: 575px;
$screen-xs-max : 767px;
$screen-sm-max : 991px;
$screen-md-max: 1199px;
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-lg-max: 1439px;
$screen-xlg: 1440px;
//---------Color


$gray-darker: #232323;
$gray: #7A7A7A;
$gray-dark: #222222;
$gray-light: #F1F1F1;
$gray-lighter: #f6f6f6;
$brand-primary: #2fb5d2;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$black:					#000000;
$brown:					#b39770;
$browndark: 			#e4dcd2;
$lightbrown:			#f6eee3;
$blue:                  #049cdb;
$bluedark:              #0064cd;
$yellow:                #ffc40d;
$orange:                #f89406;
$pink:                  #c3325f;
$purple:                #7a43b6;
$white :			 	#ffffff;
$nocolor:				transparent !default;

$theme-color-default:   #fdc726 !default; //Home 1
$theme-color-primary:   #222222 !default;
$theme-color-custom: 	#f16f6c !default;
$button-hover:			#587336 !default;
$text-color: 			#666666;
$text-color-title: 		#444444;
$text-color-custom: 	#444545;

//------- FONTS
$font-family-default:   'Poppins', sans-serif !default;
$font-family-primary:   'Poppins', sans-serif !default;
$font-family-custom: 	'Playfair Display', serif !default;

$font-icon: 			'FontAwesome';
$font-icons:			'Material-Design-Iconic-Font';
$font-icon-tello: 		'fontello';

//-------- BORDERS
$base-border-color:		            #d7d7d7 !default;
$main_border:						#e7e7e7;
$dark-border-color:					#e7e7e7;
$light-border-color:				#fff;
$homepage-footer-border:            #e7e7e7;

//--------- BACKGROUNDS
$body-bg:							#fff;
$base-box-bg:                       #fbfbfb;
$table-background:                  $base-box-bg;
$dark-background:                   #333;
$light-background:                  #fff;
$homepage-footer-bg:                #f2f2f2;
$bg-header		   :				#f7f8f8;

//--------- COLORS
$base-body-color:                   $text-color;
$base-text-color:                   $text-color;
$base-title-color:					#202020;
$light-text-color:                  #fff;
$link-color:						#585858;
$link-hover-color:                  $link-hover-color;
$description-color:					$text-color;
	
//--------- Breadcrumb ---------
	$breadcrumb-text-color:       #666;
	$breadcrumb-font-size:        1.2rem;
	$breadcrumb-link-color:       #666;
	$breadcrumb-bg:               transparent;
	$breadcrumb-link-hover-color:  $breadcrumb-text-color;

//--------- Base Heading
	$base-heading-color:			#666666;
	$base-heading-fontsize:			$font-size-base * 1.2;
	$base-heading-bg:			    transparent;
	$base-headding-padding:         0 0 15px;
	$base-headding-font:         	$font-family-default;
	$base-header-border:            $base-border-color;

//--------- Indents
$base-box-padding:                  14px 18px 13px;
$base-box-indent:                  	0 0 30px 0;

//---------- Product Elements
$product-name-color:                #202020;
$product-name-hover-color:          $theme-color-default;
$price-color:						$theme-color-default;
$product-price-color:				$theme-color-default;
$product-new-price-color:		    $theme-color-default;
$product-old-price-color:			$base-text-color;
$product-reduced-price:				#f00;
$product-info-margin: 				15px;

//---------- Buttons
$button-text-color:					$light-text-color;

	//-------------- Grid/List vars
	$GL-text-color:								$base-text-color;
	$GL-title-color:							$base-text-color;
	$GL-icon-color:								$base-text-color;
	$GL-icon-color-hover:						$base-text-color;

	//-------------- Pagination vars
	$pagination-prevnext-color:					#777676;
	$pagination-bgcolor:	               		$base-box-bg;
	$pagination-hover-bg:						#f6f6f6;

//---------- Product Info



//------------- BLOCK --------------
	$block-module-margin:             0 0 15px;
	$block-module-heading-border:     none;
	$block-module-heading-bg:         $theme-color-default;
	$block-module-heading-padding:    22px 0;
	$block-module-heading-span-padding:    8px 38px;
	$block-module-heading-margin:     0;
	$block-module-heading-font:       $font-family-default;
	$block-module-heading-color:      #272727;
	$block-module-heading-fontsize:   36px;
	$block-module-border-color:       $base-border-color;
	$block-module-content-bg:         #fff;
	$block-module-content-padding:    15px;

//------------- BOX ---------------------
	$box-bg:                    #fff !default;
	$box-inner-border:          #ddd !default;
	$box-border-radius:         3px !default;
	$box-footer-bg:             #f5f5f5 !default;

	$box-default-text:          $gray-dark !default;
	$box-default-border:        #ddd !default;
	$box-default-heading-bg:    #f5f5f5 !default;
 /*
  * LAYOUTS VAIRABLES
  */
//  Topbar
	$topbar-bg				: #f9f9f9;
	$topbar-color			: #666666;
	$topbar-border-color	: transparent;
	$topbar-font-size  		: 13px;
	$topbar-font-family     : $font-family-default;
	$topbar-link-color      : #666666;
	$topbar-link-hover-color: $link-hover-color;

//  Header
    $header-bg:                #fff;
	$header-color:             #666;
    $header-font-size:         1.4rem;
    $header-link-color:        #666;
    $header-link-hover-color:  $link-hover-color;

//  Megamenu
	$mainmenu-bg:                   transparent;
	$mainmenu-link-color:           #666666;
	$mainmenu-link-hover-color:     $theme-color-default;
	$mainmenu-font-family:			$font-family-primary;
	$mainmenu-font-size:			1.5rem;

	$mainsubmenu-bg: 				$white;
	$mainsubmenu-color:             #666;
	$mainsubmenu-link-color:        #666;
	$mainsubmenu-link-hover-color:  #fff;
	$mainsubmenu-font-family:		$font-family-default;
	$mainsubmenu-font-size:			1.4rem;
	$mainsubmenu-title-color:       $theme-color-default;
	$mainsubmenu-title-font-size:   15px;

//  Vertical Menu
	$vertical-menu-bg:                   #fff;
	$vertical-menu-link-color:           #454545;
	$vertical-menu-link-hover-color:     $theme-color-default;
	$vertical-menu-font-weight:          500;
	$vertical-menu-font-family:			 $font-family-default;
	$vertical-menu-font-size:			 13px;

	$vertical-submenu-bg: 				 #fff;
	$vertical-submenu-color:             #7a7878;
	$vertical-submenu-link-color:        #7a7878;
	$vertical-submenu-link-hover-color:  $link-hover-color;
	$vertical-submenu-font-family:		 $font-family-default;
	$vertical-submenu-font-size:		 12px;
	$vertical-submenu-title-color:       #202020;
	$vertical-submenu-title-font-size:   14px;


//slidebar
	$slidebar-bg:                    none;
	$slidebar-color:                 inherit;
	$slidebar-link-color:            $link-color;
	$slidebar-link-hover-color:      $link-hover-color;
	$siderbar-headding-color:        $base-heading-color;

//Bottom
	

// footer position
	$footer-bg:                  #fff;
	$footer-text-color:          #414141;
	$footer-font-size:			 1.3rem;
	$footer-link-color:          #222222;
	$footer-link-hover-color:    $theme-color-default;
	$footer-padding:             0;
	$footer-margin:              0;

	$footer-heading-font-size:   2rem;
	$footer-heading-color:       #222222;

// Copyright
$copyright-bg              : $white;
$copyright-text-color      : $footer-text-color;
$copyright-link-color      : $theme-color-default;
$copyright-link-color-hover: $theme-color-primary;
$copyright-padding         : 20px 0;
$copyright-margin          : 0;