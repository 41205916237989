// Product tabs Categories
.product-tabs-categories {
	padding-top: 130px;
	padding-bottom: 130px;
	overflow: hidden;
	.group-title{
		padding: 20px;
		@include title_block_1();
	}
	.product_tab_content{
		.tab-pane{
			.product_list{
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					@include opacity(0);
					display: block;
					[class*='owl-']{
						@include square(40px);
						@include border-radius(2px);
						@include transition(all 0.5s ease);
						border: 1px solid #f2f2f2;
						top: calc(50% - 75px);
						background: $white;
						color: $theme-color-primary;
						&:hover{
							border-color: $theme-color-default;
							background: $theme-color-default;
						}
					}
					.owl-prev{
						@include rtl-right(5px);
					}
					.owl-next {
						@include rtl-left(5px);
					}
				}
				&:hover{
					.owl-nav{
						@include opacity(1);
					}
				}
			}
		}
	}
	.nav-tabs {
	    border: none;
	    padding: 0;
	    margin-bottom: 8px;
	    .nav-item {
			cursor: pointer;
			margin-bottom: 0;
			.nav-link {
				border: none;
				background: transparent;
				font-size: 18px;
				color: #999999;
				@include border-radius(0);
				padding: 9px 20px;
				text-transform: capitalize;
				font-weight: 500;
				line-height: 1;
				white-space: nowrap;
				@include transition(all 0.35s ease);
				&:hover, &:focus {
					color: $white;
					background: $theme-color-default;
					@include border-radius(16px);

				}
				&.active {
					color: $white;
					background: $theme-color-default;
					@include border-radius(16px);
				}
			}
		}
	}
	@media (max-width: $screen-sm-max){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media (max-width: $screen-xs-max){
		.block_content{
			.group-title{
				.title_block{
					span{
						font-size: 2.8rem!important;
					}
				}
				.nav-tabs{
					width: 100%;
					overflow-x: auto;
				}
			}
		}
	}
	@media (max-width: 575px){
		.block_content{
			.group-title{
				.title_block{
					span{
						&:before, &:after{
							display: none;
						}
					}
				}
			}

		}
	}
}

// Product Tabs
.nov-producttab{
	overflow: hidden;
	padding-top: 130px;
	padding-bottom: 130px;
	.nav-tabs {
	    border: none;
		padding: 0;
		margin-bottom: 60px;
		position: relative;
		@include rtl-padding-lr(40px, 40px);
		&:before, &:after{
			content: "";
			position: absolute;
			top: 50%;
			@include translateY(-50%);
			width: 74px;
			height: 10px;
		}
		&:before{
			@include rtl-right(100%);
			background-image: url('../img/icon-title.png');
			background-repeat: no-repeat;
			background-position: 0 -3px;
		}
		&:after{
			@include rtl-left(100%);
			background-image: url('../img/icon-title.png');
			background-repeat: no-repeat;
			background-position: -86px -3px;
		}
	    .nav-item {
			position: relative;
			@include rtl-margin-lr(0,10px);
			cursor: pointer;
			white-space: nowrap;
			margin-bottom: 0;
			&:last-child {
				@include rtl-margin-lr(0,0);
			}
		}
		.nav-link {
			border: none;
			background: #f2f2f2;
			color: $theme-color-primary;
			font-size: 18px;
			font-weight: 500;
			border: none;
			@include border-radius(45px);
			padding: 15px 23px;
			line-height: 1;
			@include transition(all 0.35s ease);
			&:hover, &:focus, &.active {
				color: $white;
				background: $theme-color-default;
			}
		}
	}
	.block_content{
		.product_tab_content{
			margin: -20px;
			.product_list{
				padding: 20px;
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					.owl-prev{
						@include rtl-right(20px);
					}
					.owl-next{
						@include rtl-left(20px);
					}
				}
			}
		}	
	}
	@media (max-width: $screen-sm-max){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media (max-width: $screen-xs-max){
		.block{
			.group-title{
				@include flex-direction(column);
				@include justify-content(flex-start !important);
				@include align-items(flex-start !important);
				.title_block{
					margin-bottom: 40px;
				}
				.nav-tabs{
					width: 100%;
					overflow-x: auto;
					padding: 0;
					margin-bottom: 40px;
					&:before, &:after{
						display: none;
					}
				}
			}
		}
	}
}
// Product List
.nov-productlist {
	&.productlist-slider {
		.block-product{
			overflow: hidden;
			padding-top: 130px;
			padding-bottom: 200px;
			.block_content{
				.product_list {
					.owl-nav{
						@include opacity(1);
						[class*='owl-']{
							top: -45px;
							border: none;
							background: transparent;
							@include square(25px);
							&:hover{
								color: $theme-color-default;
							}
						}
						.owl-prev{
							@include rtl-right(10px);
						}
						.owl-next{
							--left: calc(100% - 60px);
							@include rtl-left(var(--left));
						}
					}
					.owl-dots{
						@include rtl-left(50%);
						@include translateX(-50%);
						top: calc(100% + 40px);
						.owl-dot{
							@include flexbox();
							@include align-items(center);
							@include justify-content(center);
							span{
								margin: 0 5px;
								@include border-radius(2px);
								background: $theme-color-primary;
								&:hover{
									background: $theme-color-default;
								}
							}
							&.active{
								span{
									background: $theme-color-default;
								}
							}
						}
					}
				}
			}
		}
		@media (max-width: $screen-sm-max){
			.block-product{
				padding-top: 60px;
				padding-bottom: 140px;
			}
		}
	}
	&.productlist-liststyle {
		.block-product{
			overflow: hidden;
			.product_list{
				.owl-nav{
					display: none;
				}
			}
		}
		.item-list {
			.product-miniature {
				border: 1px solid #f2f2f2;
				padding: 4px;
				margin-top: 10px;
				&.first_item{
					margin-top: 0; 
				}
				.thumbnail-container{
					width: 155px;
					height: 155px;
					max-width: 155px;
					@include border-radius(5px);
					@include rtl-margin-lr(0, 15px);
					position: relative;
					.group-buttons-one{
						position: absolute;
						top: 5px;
						@include flexbox();
						@include flex-direction(column);
						@include rtl-right(5px);
						a{
							font-size: 0;
							width: 40px;
							height: 40px;
							min-width: 40px;
							text-align: center;
							border: 1px solid #d7d7d7;
							@include border-radius(2px);
							@include flexbox();
							@include justify-content(center);
							@include align-items(center);
							@include transition(all 0.35s ease-in-out);
							background-color: $white;
						}
						.addToWishlist {
							@include opacity(0);
							@include transition(all 500ms ease-in-out 0s);
							position: relative;
							margin-bottom: 5px;
							.nov-icon-wishlist{
								display: inline-block;
								@include size(19px, 19px);
								background-image: url('../img/icon/icon-group-header.png');
								background-position: -97px -0px;
								background-repeat: no-repeat;
							}
							&.checked{
								pointer-events: none;
								cursor: not-allowed;
							}
							&:hover{
								border-color: $theme-color-default;
								background: $theme-color-default;
								.nov-icon-wishlist{
									background-position: -97px -26px;
								}
							}
						}
						.quick-view{
							@include opacity(0);
							@include transition(all 350ms ease-in-out 0s);
							.nov-icon-quick-view{
								display: inline-block;
								@include square(19px);
								background-image: url('../img/icon/icon-group-header.png');
								background-position: -125px -0px;
								background-repeat: no-repeat;
							}
							&:hover{
								border-color: $theme-color-default;
								background: $theme-color-default;
								.nov-icon-quick-view{
									background-position: -125px -26px;
								}
							}
						}
					}
				}
				.product-description{
					padding-bottom: 0;
					position: relative;
					.product-title {
						padding-top: 0;
						padding-bottom: 10px;
						a{
							font-size: 15px;
							font-weight: 600;
							color: $theme-color-primary;
							&:hover{
								color: $theme-color-default;
							}
						}
					}
					.product-comments {
						padding-top: 13px;
						margin-bottom: 0px;
						padding-bottom: 18px;
						border-bottom: 1px solid #f2f2f2;
						.star_content{
							@include justify-content(flex-start);
						}
					}
					.group-price{
						@include transition(all 0.35s ease);
						@include opacity(1);
						.product-group-price{
							padding-top: 18px;
						}
					}
					.group-buttons{
						@include rtl-left(-5px);
						@include translateX(0);
						bottom: -15px;
					}
				}
				&:hover{
					border-color: $theme-color-default;
					@include box-shadow(none);
					.thumbnail-container{
						.group-buttons-one{
							.quick-view, .addToWishlist{
								@include opacity(1);

							}
						}
					}
					.product-description{
						.group-price{
							@include opacity(0);
							@include translateY(15px);

						}
					}
				}
			}
			@media(max-width: 374px){
				.product-miniature{
					.content-list-product{
						@include flex-direction(column);
						@include align-items(center!important);
						.thumbnail-container{
							margin: 0;
							width: 100%;
							height: auto;
							max-width: 100%;
						}
						.product-description{
							width: 100%;
							padding-top: 20px;
							padding-bottom: 20px;
							text-align: center;
							.product-comments{
								.star_content{
									@include justify-content(center!important);
								}
							}
						}
					}
				}
			}
		}
	}

}

// Productlist Deal List
.productslist-deal-list {
	.title_block {
		font-size: 23px;
		line-height: 1;
		margin-bottom: -25px;
		position: relative;
		z-index: 2;
		span {
			background: $theme-color-default;
			@include border-radius(25px);
			padding: 14px 35px;
			color: #fff;
		}
	}
	.item {
		.product-miniature {
			-webkit-box-align: center !important;
			-webkit-align-items: center !important;
			-ms-flex-align: center !important;
			align-items: center !important;
			border: none;
			&:hover {
				border: none;
			}
		}
	}
	.product_list {
		border: 1px solid $base-border-color;
		
	}

	// -----------------------------
	.owl-stage-outer {
		@include rtl-margin-left(-1px);
		.owl-item {
		    .item {
		      @include rtl-border-left(1px solid $base-border-color);
		    }
	  	}
	}
	.owl-theme {
		.owl-nav {
			[class*="owl-"] {
				top: 50%;
				margin-top: -15px;
				@include opacity(0);
				@include transition(all 0.35s);
			}
			.owl-next {
				@include rtl-right(-15px);
			}
			.owl-prev {
				@include rtl-left(-15px);
			}
		}
	}
	&:hover {
		.owl-theme .owl-nav [class*="owl-"] {
		  @include opacity(1);
		}
	}
}
//Manufacture
.nov-manufacture {
	padding: 130px 15px;
  .block_content {
    overflow: hidden;
    .manufacture{
    	.owl-stage-outer{
    		overflow: initial;
    	}
    }
    .logo-manu {
    	text-align: center;
    	&:hover{

    	}
    }
  }
  @media(max-width: $screen-sm-max){
  	padding: 80px 15px;
  }
}

// Testimonial
#testimonial_block {
  	&.testimonial-type-one {
  		padding-top: 122px;
  		padding-bottom: 130px;
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow:initial;
  			background: #f3f3f3;
  		}
  		.type-one{
  			
  			padding-bottom: 130px;
  			.box-image{
				@include flexbox();
				margin-top: -123px;
  			}
  			.box-image-testimonial{
  				@include hexagon-testimonial();
  				margin-left: auto;
  				margin-right: auto;
  			}
  			.testimonial-info{
  				padding-top: 34px;
  				padding-bottom: 44px;
  				.box-info{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 600;
  					margin-bottom: 0;
  				}
  				.box-company{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 400;
  					display: inline-block;
  					@include rtl-padding-lr(5px, 0);
  				}
  			}
  			.testimonial-content{
  				padding-bottom: 53px;
  				.text{
  					max-width: 760px;
  					position: relative;
  					margin: auto;
  					&:before{
  						content: "";
  						position: absolute;
  						top: -15px;
  						@include rtl-left(-8px);
  						background-image: url('../img/icon-note.png');
  						background-repeat: no-repeat;
  						background-position: 0 0;
  						width: 10px;
  						height: 9px;
  					}
  					p{
  						font-size: 14px;
  						line-height: 24px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						text-align: center;
  						margin: 0;
  					}
  				}
  			}
  		}
  		.owl-dots{
  			@include rtl-left(50%);
  			@include translateX(-50%);
  			top: auto;
  			bottom: 220px;
  			.owl-dot{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(center);
  				span{
  					margin: 0 5px;
  					@include border-radius(2px);
  					background: $theme-color-primary;
  					&:hover{
  						background: $theme-color-default;
  					}
  				}
  				&.active{
  					span{
  						background: $theme-color-default;
  					}
  				}
  			}
  		}
  		@media (max-width: $screen-sm-max){
  			.type-one .testimonial-content .text{
  				max-width: 100%;
  				margin-left: 30px;
  				margin-right: 30px;
  			}
  		}
  	}
  	&.testimonial-type-two{
  		padding-top: 122px;
  		padding-bottom: 130px;
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow:initial;
  			.owl-item{
  				background-color: $white;
  				@include border-radius(5px);
  			}
  		}
  		.type-two{
  			.box-image{
				@include flexbox();
				margin-top: -123px;
  			}
  			.box-image-testimonial{
  				@include hexagon-testimonial();
  				margin-left: auto;
  				margin-right: auto;
  			}
  			.testimonial-info{
  				padding-top: 34px;
  				padding-bottom: 44px;
  				.box-info{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 600;
  					margin-bottom: 0;
  					
  				}
  				.box-company{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 400;
  					display: inline-block;
  					@include rtl-padding-lr(5px, 0);
  				}
  			}
  			.testimonial-content{
  				padding-bottom: 83px;
  				.text{
  					max-width: 760px;
  					position: relative;
  					margin: auto;
  					@include rtl-padding-lr(20px, 30px);
  					&:before{
  						content: "";
  						position: absolute;
  						top: -15px;
  						@include rtl-left(20px);
  						background-image: url('../img/icon-note.png');
  						background-repeat: no-repeat;
  						background-position: -25px 0;
  						width: 25px;
  						height: 21px;
  					}
  					p{
  						font-size: 14px;
  						line-height: 24px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						@include rtl-text-align-left();
  						text-indent: 30px;
  						margin-bottom: 0;
  					}
  				}
  			}
  		}
  		.owl-dots{
  			@include rtl-left(50%);
  			@include translateX(-50%);
  			top: auto;
  			bottom: 220px;
  			.owl-dot{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(center);
  				span{
  					margin: 0 5px;
  					@include border-radius(2px);
  					background: $theme-color-primary;
  					&:hover{
  						background: $theme-color-default;
  					}
  				}
  				&.active{
  					span{
  						background: $theme-color-default;
  					}
  				}
  			}
  		}
  		@media (max-width: $screen-sm-max){
  			padding-bottom: 80px;
  			.type-two{
  				.testimonial-info{
  					padding-top: 24px;
  					padding-bottom: 34px;
  				}
  				.testimonial-content{
  					padding-bottom: 63px;
  				}
  			}
  		}
  	}
  	&.testimonial-type-three{
  		padding-top: 122px;
  		padding-bottom: 130px;
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow:initial;
  		}
  		.type-three{
  			.box-image{
  				@include flexbox();
  				margin-top: -123px;
  			}
  			.box-image-testimonial{
  				@include hexagon-testimonial();
  				margin-left: auto;
  				margin-right: auto;
  			}
  			.testimonial-info{
  				padding-top: 34px;
  				padding-bottom: 44px;
  				.box-info{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 600;
  					margin-bottom: 0;

  				}
  				.box-company{
  					font-size: 16px;
  					color: $theme-color-primary;
  					font-weight: 400;
  					display: inline-block;
  					@include rtl-padding-lr(5px, 0);
  				}
  			}
  			.testimonial-content{
  				padding-bottom: 58px;
  				.text{
  					max-width: 760px;
  					position: relative;
  					margin: auto;
  					&:before{
  						content: "";
  						position: absolute;
  						top: -15px;
  						@include rtl-left(-8px);
  						background-image: url('../img/icon-note.png');
  						background-repeat: no-repeat;
  						background-position: 0 0;
  						width: 10px;
  						height: 9px;
  					}
  					p{
  						font-size: 14px;
  						line-height: 24px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						text-align: center;
  						margin-bottom: 0;
  					}
  				}
  			}
  		}
  		.owl-dots{
  			@include rtl-left(50%);
  			@include translateX(-50%);
  			top: auto;
  			bottom: 130px;
  			.owl-dot{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(center);
  				span{
  					margin: 0 5px;
  					@include border-radius(2px);
  					background: $theme-color-primary;
  					&:hover{
  						background: $white;
  					}
  				}
  				&.active{
  					span{
  						background: $white;
  					}
  				}
  			}
  		}
  		@media (max-width: $screen-sm-max){
  			.type-three .testimonial-content .text{
  				max-width: 100%;
  				margin-left: 30px;
  				margin-right: 30px;
  			}
  		}
  		@media (max-width: $screen-xs-max){
  			padding-bottom: 90px;
  			.owl-dots{
  				bottom: 90px;
  			}
  		}
  	}
}

// Groupproductlist
.groupproductlist {
	padding-bottom: 68px;
	.block_content{
		position: relative;
		margin: 0 -20px;
		.effect{
			overflow: hidden;
			position: relative;
			@include transition(all 0.35s ease);
			&:hover{
				img{
					-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
				}
			}
		}
		.title_block{
			margin-bottom: 41px!important;
		}
		.product_list_content{
			overflow: hidden;
			margin: 0 -20px;
			
			.product_list{
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					display: none;
				}
			}
		}
	}
	@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
		padding-bottom: 28px;
	}
	@media (max-width: $screen-xs-max){
		padding-bottom: 28px;
		.block_content{
			.title_block{
				margin-bottom: 11px!important;
			}
		}
	}
}

//nov-block_newsletter
.nov-block_newsletter {
	.title_block {
		text-align: center;
		font-size: 2.4rem!important;
		color: $theme-color-primary!important;
		margin-bottom: 28px!important;
		.sub_title{
			font-size: 1.2rem;
			color: $text-color;
			font-weight: 400;
			text-transform: initial;
			margin-top: 22px;
			line-height: normal;
		}
	}
	.block_newsletter {
		p {display: none;}
		.input-group {
			width: 570px;
			margin: 0 auto;
			@include border-radius(23px);
			input {
				background: #f3f3f3;
				border-color: #f3f3f3;
				border: none;
				background: $white;
			}
		}
		button {
			background: $theme-color-primary;
			border-color: $theme-color-primary;
			@include transition(all 0.35s ease);
			&:hover{
				background: $theme-color-default;
				border-color: $theme-color-default;
			}
		}

	}
	.social-content{
		.title_block{
			display: none!important;
		}
		#social_block{
			margin-top: 30px;
		}
	}
}

.nov-countdown-productlist {
	padding-bottom: 130px;
	
	.block-product{
		overflow:hidden;
		@include title_block_1();
		.title_block{
			margin-bottom: 59px;
		}
	}
	.block_content{
		margin: 0;
		.owl-theme{
			.owl-stage-outer{
				overflow: initial;
			}
		}
		.product-miniature{
			border: none;
			padding: 0 9%;
			overflow: initial;
			@include transition(all 0.35s ease);
			&:before{
				display: none; 
			}
			.thumbnail-container{
				@include rtl-padding-lr(0, 60px);
			}
			.product-description{
				padding: 50px 40px 50px 70px;
				border: 1px solid $theme-color-default;
				position: relative;
				.group-buttons{
					@include justify-content(flex-start !important);
					position: relative;
					@include rtl-left(0);
					@include translateX(0);
					margin-top: 26px;
					top: 0;
					>a, form{
						position: relative;
						left: initial;
						right: initial;
						top: initial;
						@include opacity(1);
					}
					.quick-view{
						@include rtl-margin-lr(5px, 5px);
					}
				}
				.product-title{
					padding-top: 0;
					padding-bottom: 17px;
					@include rtl-text-align-left();
					a{
						font-size: 15px;
						font-weight: 600;
						color: $theme-color-primary;
						&:hover{
							color: $theme-color-default;
						}
					}
				}
				.product-comments{
					padding-bottom: 23px;
					.star_content{
						@include justify-content(flex-start !important);
					}
				}
				.product-desc{
					font-size: 14px;
					color: $theme-color-primary;
					font-weight: 400;
					line-height: 24px;
					padding-top: 16px;
					padding-bottom: 5px;
					@include rtl-text-align-left();
					border-top: 1px solid #f3f3f3;
				}
				.group-price{
					.product-group-price{
						@include rtl-text-align-left();
						.product-price-and-shipping{
							.price{
								font-size: 24px;
								font-weight: 600;
								color: #e55b2a;
							}
							.regular-price{
								font-size: 15px;
								font-weight: 600;
								color: #6e6e6e;
								@include rtl-margin-lr(20px, 0);
							}
						}
					}
				}
			}
			&:hover{
				@include box-shadow(none);
			}
		}
	}
	@media (max-width: $screen-sm-max){
		padding-bottom: 80px;
	}
	@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
		.block_content{
			.product-miniature{
				padding: 0;
			}
		}
	}
	@media (max-width: $screen-xs-max){
		.block_content{
			.product-miniature{
				padding: 0 30px;
				.thumbnail-container{
					padding-left: 0;
					padding-right: 0;
					padding-bottom: 30px;
				}
			}
		}
	}
	@media (max-width: 575px){
		.title_block{
			span{
				font-size: 2.8rem !important;
				line-height: normal!important;
				&:before, &:after{
					display: none;
				}
			}
			margin-bottom: 40px!important;
		}
		.block_content{
			.product-miniature{
				.product-description{
					padding: 50px 40px 50px 50px;

				}
			}
		}
	}
	@media (max-width: 375px){
		.block_content{
			.product-miniature{
				.product-description{
					.group-buttons{
						.quick-view, .addToWishlist{
							display: none;
						}
					}

				}
			}
		}
	}
}
//Slider Image
.nov-slider-image{
	padding: 0;
	&:focus{
		outline: none;
	}
	.slick-arrow{
		position: absolute;
		font-family: $font-icon-tello;
		top: 50%;
		@include translateY(-50%);
		cursor: pointer;
		width: 30px;
		height: 50px;
		overflow: hidden;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		z-index: 102;
		background-color: $white;
		border: none;
		@include transition(all 0.35s  ease);
		font-size: 0;
		&:hover{
			background-color: $theme-color-default;
			&:before{
				color: $white;
			}
		}
		&:focus{
			outline: none;
		}
		&.slick-prev {
			left: 0px;
			@include rtl-border-radius-topright(14px);
			@include rtl-border-radius-bottomright(14px);
			&:before{
				content: "\f007";
				font-size: 1.4rem;
			}
		}
		&.slick-next {
			right: 0px;
			@include rtl-border-radius-topleft(14px);
			@include rtl-border-radius-bottomleft(14px);
			&:before{
				content: "\f006";
				font-size: 1.4rem;
			}
		}
	}
	.slick-images-slider{
		.slick-list{
			overflow: inherit;
			.slick-slide{
				@include rtl-padding-lr(15px, 15px);
			}
		}
		.slick-next{
			@include rtl-right(15px);
		}
		.slick-prev{
			@include rtl-left(15px);
		}
	}
}

// Image
.nov-image {
	.block{
		position: relative;
		.content-banner{
			position: absolute;
			z-index: 2;
			.button-image{
				font-size: 1.4rem;
				font-weight: 700;
				color: $theme-color-primary;
				padding: 2.2rem 5.2rem;
				background: $white;
				@include transition(all 0.35s ease);
				text-transform: uppercase;
				white-space: nowrap;
				@media (max-width: $screen-sm-max){
					padding: 1.2rem 2rem !important;
					min-width: 120px!important;
				}
			}
			&:hover{
				.button-image{
					background: $theme-color-primary;
					color: $white;
				}
			}
		}
		&.effect_default{
			.content-banner{
				display: none;
			}
		}
		&.effect_1{
			.block_content{
				@include transition(all 0.35s ease);
			}
			.content-banner{
				@include rtl-left(10px);
				bottom: 20px;
				.button-image{
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					color: #444545;
					padding: 16px;
					text-transform: uppercase;
					min-width: 220px;
					@include transition(all 0.35s ease);
					text-align: center;
					&:hover{
						background-color: $theme-color-default;
						color: $white;
						@include box-shadow(0 2px 5px 0 rgba(0,0,0,0.05));
					}
				}
			}
			@media(max-width: $screen-xs-max){
				.content-banner{
					@include rtl-left(50%);
					@include translateX(-50%);
				}
			}
		}
		&.effect_2{
			.block_content{
				@include transition(all 0.35s ease);
			}
			.content-banner{
				@include rtl-right(20px);
				bottom: 20px;
				.button-image{
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					color: #444545;
					padding: 16px;
					text-transform: uppercase;
					min-width: 200px;
					@include transition(all 0.35s ease);
					text-align: center;
					&:hover{
						background-color: $theme-color-default;
						color: $white;
						@include box-shadow(0 2px 5px 0 rgba(0,0,0,0.05));
					}
				}
			}
		}
		
	}
	.effect {
		overflow: hidden;
		position: relative;
		@include transition(all 0.35s ease);
		text-align: center;
		a{
			display: block;
			overflow: hidden;
			position: relative;
			&:before{
				content: "";
				bottom: 0;
				left: 50%;
				right: 51%;
				top: 0;
				@include opacity(1);
				position: absolute;
				background: rgba(0,0,0,.1) none repeat scroll 0 0;
			}
			&:after{
				content: "";
				bottom: 50%;
				left: 0;
				right: 0;
				top: 50%;
				@include opacity(1);
				position: absolute;
				background: rgba(0,0,0,.1) none repeat scroll 0 0;
			}
		}
		&:hover {
			a{
				&:before{
					left: 0;
					right: 0;
					@include opacity(0);
					@include transition(all 700ms ease-in-out 0s);
				}
				&:after{
					bottom: 0;
					top: 0;
					@include opacity(0);
					@include transition(all 700ms ease-in-out 0s);
				}
			}
		}
	}
}
//Instagram
.nov-instafeed {
	.boxInstagram{
		.owl-nav{
			[class*='owl-']{
				top: 50%;
				@include translateY(-50%);
				color: $white;
				&:hover{
					color: $black;
				}
			}

			.owl-prev{
				@include rtl-left(0);
			}
			.owl-next{
				@include rtl-right(0);
			}
		}
		.owl-dots{
			bottom: 0px;
			top: auto;
			@include rtl-left(50%);
			@include translateX(-50%);
			line-height: 1;
			.owl-dot{
				span{
					&:before{
						@include size(13px, 13px);
						background: #d8d8d8;
					}
				}
				&.active, &:hover{
					span{
						&:before{
							background: $theme-color-default;
						}
					}
				}
			}
		}	
	}
	.block-instagram{
		position: relative;
		display: block;
		&:before{
			content: "\eba9";
			font-size: 1.6rem!important;
			font-family: $font-icon-tello;
			color: $white;
			background-color: $theme-color-primary;
			position: absolute;
			@include border-radius(50%);
			width: 40px;
			height: 40px;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			line-height: 40px;
			filter: alpha(opacity=0);
			@include opacity(0);
			z-index: 1;
			font-size: 50px;
			@include scale(0, 0);
			@include transition(all 0.4s ease);
		}
		&:after{
			content: '';
			position: absolute;
			@include transition(all 0.4s ease);
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(37,37,37,0.6);
			@include opacity(0);
		}
		&:hover{
			&:after, &:before{
				@include opacity(1);
			}
			&:before{
				color: $white;
				@include scale(1,1);
			}
		}
	}
	.boxInstagram-grid{
		>div{
			@include rtl-padding-lr(5px, 5px);
		}
	}
}