$contrasted-dark-default:   #000 !default;
$contrasted-light-default:  #fff !default;
$contrasted-lightness-threshold: 30% !default;

// Returns the `$light` color when the `$color` is dark
// and the `$dark` color when the `$color` is light.
// The `$threshold` is a percent between `0%` and `100%` and it determines
// when the lightness of `$color` changes from "dark" to "light".
@function contrast-color(
  $color,
  $dark: $contrasted-dark-default,
  $light: $contrasted-light-default,
  $threshold: $contrasted-lightness-threshold
) {
  @return if(lightness($color) < $threshold, $light, $dark)
}

// Sets the specified background color and calculates a dark or light contrasted text color.
// The arguments are passed through to the [contrast-color function](#function-contrast-color).
@mixin contrasted(
  $background-color,
  $dark: $contrasted-dark-default,
  $light: $contrasted-light-default,
  $threshold: $contrasted-lightness-threshold
) {
  background-color: $background-color;
  color: contrast-color($background-color, $dark, $light, $threshold);
}