/******************************************************
 * @package Version Theme Framework for novatheme
 * @version 1.0
 * @author http://www.novatheme.com
 * @copyright Copyright (coffee) Augus 2013 novatheme.com <@emai:novatheme@gmail.com>.All rights reserved.
 * @license   GNU General Public License version 2
********************************************************************
          VELVERTICALMENU STYLE
********************************************************************/
.verticalmenu{
  .menu {
    display: none;
    list-style: none;
    @include rtl-padding-lr(10px, 20px);
    margin-bottom: 0;
    > li {
      position: relative;
      width: 100%;
      padding: 0 0;  
      &:first-child {
        > a {
          padding-top: 17px;
        }
      }
      //Menu leve1
      > a {
        font-family: $vertical-menu-font-family;
        font-size: $vertical-menu-font-size;
        color: $vertical-menu-link-color;
        font-weight: $vertical-menu-font-weight;
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 1;
        border-bottom: 1px solid #f2f2f2;
        position: relative;
        @include transition(all 0.35s ease);
      }
      &.more, &.less{
        width: 100%;
        height: 44px;
        position: relative;
        &:before{
          content:"";
          position: absolute;
          top: 50%;
          @include rtl-left(50%);
          @include translate(-50%, -50%);
          width: 20px;
          height: 20px;
          color: $white;
          font-size: 1.6rem;
          font-family: $font-family-default;
          background-color: $theme-color-default;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          text-align: center;
          @include border-radius(50%);
          text-indent: 2px;
          cursor: pointer;
        }
      }
      &.more{
        &:before{
          content: "+";
        }
      }
      &.less{
        &:before{
          content: "-";
        }
      }
      > .dropdown-menu {
        position: relative;
        display: block;
        top: inherit;
        left: inherit;
        border: none;
        padding: 0;
        display: none;
        float: none;
        @include border-radius(0);
        >ul{
          @include flexbox();
          @include flex-wrap(wrap);
        }
        ul {
          padding: 0;
          list-style: none;
          li.item {
            a {
              font-family: $vertical-submenu-font-family;
              font-size: 1.4rem;
              padding: 10px 0;
              display: block;
              position: relative;
              color: $text-color;
            }
            &:hover{
              a{
                color: $text-color;
              }
            }
          }
        }
      }
      //Menu active lever1
      &.active {
        > a {
          background-color: transparent;
          color: #fff;
          &:hover {
            background-color: transparent;
          }
        }
      }
      // Menu when hover lever1
      &:focus,
      &:hover {
        > a {
          color: $theme-color-default;
          border-color: $theme-color-default;
        }
      }
    }
  }
  //submenu
  .hasicon {
    width: 26px;
    height: 22px;
    display: inline-block;
    vertical-align: top;
    @include rtl-margin-right(10px);
    display: none;
  }
}

// Vertical menu Global
.verticalmenu {
  .show-sub {
      position: absolute;
      @include rtl-right(0);
      top: 11px;
      text-align: center;
      cursor: pointer;
  		&:before {
        content: "";
        display: inline-block;
        @include square(15px);
        background-image: url(../img/icon/icon-plus-minus.png);
        background-color: $theme-color-primary;
        background-position: 0 -15px;
        position: relative;
        @include rtl-float-right();
        @include border-radius(2px);
      }
      &:hover {
        &:before {
          background-color: $theme-color-default;
        }
      }
  }
  li.menu-active > .show-sub {
    &:before {
      background-position: 0 0;
    }
  } 
  
}

// Element
.menu-title {
  display: block;
  font-family: $font-family-primary;
  font-weight: 700;
  font-size: 14px;
  color: $theme-color-primary;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
.buttons {
  margin-bottom: 20px;
  .btn {
    font-size: 11px;
    padding: 6px 14px !important;
    background: #45ab67;
    color: #fff;
  }
  .list-inline-item {
    &:first-child {
      .btn {
        background: #f5872f;
      }
    }
    &:last-child {
      .btn {
        background: #f05a5a;
      }
    }
  }
}
.tags {
  padding-bottom: 9px;
  border-bottom: 1px dotted #b8b8b8;
  margin-bottom: 15px;
  .title {
    font-size: 14px;
    color: #222;
    text-transform: uppercase;
    font-family: $font-family-primary;
    b {
      min-width: 157px;
      display: inline-block;
    }
  }
  .list-inline-item {
    position: relative;
    @include rtl-padding-right(9px);
    @include rtl-margin-right(9px);
    margin-bottom: 10px;
    a {
      padding: 0;
      font-size: 14px;
    }
    &:hover{
      a{
        color: $theme-color-default;
      }
    }
  }
}
.group-category {
  ul {
    margin-bottom: 13px;
    min-width: 170px;
    li a {
      font-size: 12px;
      padding: 5px 0;
      display: block;
    }
  }
}

@media(min-width: 992px) and (max-width: 1199px){
  .verticalmenu > .menu {
    >li{
      >.dropdown-menu{
        max-width: 755px!important;
      }
    }
  }
}
@media (max-width: 991px) and (min-width: 768px){
  .vertical-menu-main{
    overflow-y: auto;
    .verticalmenu-content{
      min-width: 230px!important;
    }
  }
  .verticalmenu {
    .menu {
      li {
        position: relative !important;
        width: 220px!important;
        .dropdown-menu {
          z-index: 300 !important;
          visibility: visible !important;
          opacity: 1 !important;
          position: static !important;
          width: 100% !important;
          background-color: $white !important;
          @include box-shadow(none);
          overflow: hidden;
        }
      }
      > li{
        &.group{
          >.dropdown-menu{
            >ul{
              text-align: center;
              padding-bottom: 0!important;
              >li{
                max-width: 100%!important;
                flex: 0 0 100%!important;
                padding: 0 !important;
                .menu-content{
                  .title-category{
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-top: 10px;
                    @include rtl-text-align-left();
                  }
                  ul{
                    li{
                      a{
                        @include rtl-text-align-left();
                        padding: 5px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.has-sub{
          >.dropdown-menu{
            >ul{
              @include rtl-text-align-left();
              padding: 0!important;
              >li{
                a{
                  padding: 10px 5px!important;
                }
              }
            }
          }
        }
      }
    }
    .show-sub {
      position: absolute;
      @include rtl-right(15px);
      display: block!important;
      top: 6px;
      text-align: center;
      cursor: pointer;
      &:before {
        content: "";
        display: inline-block;
        @include square(15px);
        background-image: url(../img/icon/icon-plus-minus.png);
        background-color: $theme-color-primary;
        background-position: 0 -15px;
        position: relative;
        @include rtl-float-right();
        @include border-radius(2px);
      }
      &:hover {
        &:before {
          background-color: $theme-color-default;
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .verticalmenu {
    .menu {
      //Menu Sub level 1
      > li {
        .show-sub {
          display: none;
        }
        > .dropdown-menu {
          background: transparent;
          min-width: 242px;
          position: absolute;
          top: 0;
          right: inherit;
          left: 100%;
          margin-top: 0;
          @include rtl-padding-left(42px);
          > ul {
            background: #fff;
          }
        }
        &.has-sub, &.group{

          &:hover{
            &:after{
              content: " ";
              left: calc(100% + 20px);
              border-style: solid;
              border-width: 14px 0 14px 13px;
              border-color: transparent transparent transparent $white;
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              top: 50%;
              @include translateY(-50%);
            }
            &:before{
              content: "";
              left: calc(100% + 20px);
              border-style: solid;
              border-width: 17px 0 17px 14px;
              border-color: transparent transparent transparent #f2f2f2;
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              top: 50%;
              @include translateY(-50%);
            }
          }
        }
        &.has-sub{
          >.dropdown-menu{
            >ul{
              @include border-radius(8px);
            }
          }
        }
        &.has-sub{
          .dropdown-menu{
            ul{
              margin: 0;
              padding: 0;
              @include box-shadow(0 0 7px 0 rgba(0,0,0,0.15));
              li{
                width: 100%;
                &:first-child{
                  a{
                    border-top: 0;
                  }
                }
                a{
                  font-size: 1.3rem;
                  color: $vertical-menu-link-color;
                  font-weight: 500;
                  padding: 6px 5px 6px 10px;
                }
                &:hover{
                  a{
                    color: $theme-color-default;
                  }
                }
              }
            }
          }
        }
        &.group {
          >.dropdown-menu{
            >ul{
              @include border-radius(8px);
              @include box-shadow(0 0 7px 0 rgba(0,0,0, 0.15));
              >li{
                width: 100%;
                @include border-radius(8px);
              }
              .group-list-category{
                padding-top: 27px!important;
                padding-bottom: 83px;
                padding-left: 30px;
                background-image: url(../img/vertical-menu/banner-vertical-menu-1.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                .title-category{
                  font-size: 14px;
                  font-weight: 900;
                  color: $gray-dark;
                  text-transform: uppercase;
                  margin-bottom: 11px;
                }
                ul{
                  >li{
                    border-bottom: 1px solid #eeeeee;
                    &:last-child{
                      border-bottom: none;
                    }
                    a{
                      font-size: 13px;
                      color: #848484;
                      font-weight: 400;
                      padding: 8px 0;
                    }
                    &:hover{
                      a{
                        color: $theme-color-default;
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
              .group-list-category-1{
                padding-top: 27px!important;
                padding-bottom: 47px;
                padding-left: 30px;
                background-image: url(../img/vertical-menu/banner-vertical-menu-2.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                .title-category{
                  font-size: 14px;
                  font-weight: 900;
                  color: $gray-dark;
                  text-transform: uppercase;
                  margin-bottom: 11px;
                }
                .menu-content{
                  .row{
                    >div{
                      @include rtl-margin-right(50px);
                    }
                  }
                }
                ul{
                  >li{
                    border-bottom: 1px solid #eeeeee;
                    &:last-child{
                      border-bottom: none;
                    }
                    a{
                      font-size: 13px;
                      color: #848484;
                      font-weight: 400;
                      padding: 8px 0;
                    }
                    &:hover{
                      a{
                        color: $theme-color-default;
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
              .group-list-category-2{
                padding-top: 27px!important;
                padding-bottom: 47px;
                padding-left: 30px;
                background-image: url(../img/vertical-menu/banner-vertical-menu-3.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                .title-category{
                  font-size: 14px;
                  font-weight: 900;
                  color: $gray-dark;
                  text-transform: uppercase;
                  margin-bottom: 11px;
                }
                .menu-content{
                  .row{
                    >div{
                      @include rtl-margin-right(50px);
                    }
                  }
                }
                ul{
                  >li{
                    border-bottom: 1px solid #eeeeee;
                    &:last-child{
                      border-bottom: none;
                    }
                    a{
                      font-size: 13px;
                      color: #848484;
                      font-weight: 400;
                      padding: 8px 0;
                    }
                    &:hover{
                      a{
                        color: $theme-color-default;
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
              .horizontal-image{
                padding-bottom: 29px;
              }
              .image-vertical{
                padding: 0;
              }
            }
          }
        }
        &:hover {
          //background: #d8e3e8;
          &.parent, &.group {
            &:before,&:after {
              display: block;
            }
          }
          >.dropdown-menu {
            display: block;
          }
        }
      }

      //Menu Sub level 2
      > li > .dropdown-menu {
        > ul {
          background: #fff;
          > li {
            padding: 0 20px;
            position: relative;
            >a{
              font-size: 1.3rem;
              color: #848484;
              border-bottom: 1px solid #e5e5e5;
            }
            > .dropdown-menu {
                position: absolute;
                min-width: 200px;
                top: 0;
                left: 100%;
                background: $white;
                padding: 0;
                @include rtl-margin-left(5px);
                border: none;
                @include border-radius(0);
                display: none;
                ul {
                  background: #fff;
                  @include box-shadow(0 3px 7px 0px rgba(#000,0.45));
                }

            }
            &:hover {
              >a{
                color: $theme-color-default;
                font-weight: 700;
              }
              > .dropdown-menu {
                display: block;
              }
            }
            &:first-child {
              padding-top: 5px;
            }
            &:last-child {
              padding-bottom: 15px;
              >a{
                border-bottom: none;
              }
            }
          }
        }
      }
      //Menu sub level 3
      > li > .dropdown-menu li > .dropdown-menu  {
        > ul {
          background: #fff;
          > li {
            border-bottom: 1px solid #e5e5e5;
            padding: 0 20px;
            position: relative;
            &:hover {
              &:hover {
                background: $theme-color-default;
                > a {
                  color: #fff;
                }
                > .dropdown-menu {
                  display: block;
                }
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
      >li >.dropdown-menu{
        >ul{
          .item-html{
            .menu-content{
              ul{
                li{
                  &:hover{
                    a{
                      color: $theme-color-default;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// Responsive Vertical menu
@media (max-width: 767px) {
  .verticalmenu-dropdown {
    z-index: 2;
    width: 100%;
  }
  .verticalmenu {
    .menu {
      float: inherit !important;
      margin: 0;
      left: 0 !important;
      display: block;
      li.item {
        float: none;
        clear: both;
        position: relative !important;
        width: 100% !important;
        .nov-verticalmenu-toggle {
          content: "";
          @include square(30px);
          position: absolute;
          top: 5px;
          @include rtl-right(0);
          background: url('#{$image-module-path}bg-menu-toggle.png') scroll top center transparent;
          cursor: pointer;
          z-index: 9999;
        }
        &.menu-active {
          > .nov-verticalmenu-toggle {
            background: url('#{$image-module-path}bg-menu-toggle.png') scroll bottom center transparent;
          }
        }
        .dropdown-menu {
          z-index: 300 !important;
          visibility: visible !important;
          opacity: 1 !important;
          display: none;
          position: relative !important;
          max-width: 100% !important;
          width: 100% !important;
          background-color: $white !important;
          @include box-shadow(none);
          overflow: hidden;
          border: none;
        }
        .menu-title {
            color: #222;
            margin-bottom: 10px;
            padding-bottom: 5px;
            font-size: 14px;
            display: block;
        }
        a {
          float: none;
          clear: both;
          @include box-shadow(none !important);
        }
        &.parent {
          &:not(.group) {
            .dropdown-menu {
              display: none;
            }
          }
          > a:before {
            display: none !important;
          }
        }
        &.group {
          .dropdown-menu {
            display: none;
          }
          > .dropdown-menu {
            padding-bottom: 15px;
            top: 0!important;
          }
          > a {
            padding: 10px 0 !important;
            &:before{
              display: none;
            }
          }
        }
        &.html {
          //display: none;
          img {max-width: 100%;}
        }
        > span.menu-title {
          padding: 10px 0 !important;
          border-bottom: 1px solid #CCC;
          font-size: 14px;
        }
      }
      > li.item {
        padding: 0;
        >.dropdown-menu{
          >ul{
            @include rtl-text-align-left();
            padding-bottom: 0!important;
            >li{
              max-width: 100%!important;
              flex: 0 0 100%!important;
              padding: 0 !important;
              a{
                padding: 5px 0 !important;
                font-size: 1.3rem!important;
              }
              .menu-content{
                .title-category{
                  font-size: 1.6rem;
                  font-weight: 500;
                  margin-top: 10px;
                  @include rtl-text-align-left();
                }
                ul{
                  li{
                    a{
                      @include rtl-text-align-left();
                      font-size: 1.3rem!important;   
                    }
                  }
                }
              }
            }
          }
        }
        > a {
          padding: 10px;
          border-bottom: 1px solid rgba(#ccc,0.2);

        }
      }
    }
  }

  .tags {
    display: block !important;
    .title {
      margin-bottom: 10px;
    }
  }
  .buttons {
    margin-top: 5px;
    .list-inline-item {
      margin-top: 5px;
    }
  }
  .group-category {
    padding-top: 10px;
    .d-flex {
      display: block !important;
    }
    .col {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

}

@-webkit-keyframes hovermenuu {
  0% {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
            @include opacity(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(0);
            transform: translateX(0);
            @include opacity(1);
  }
}

@keyframes hovermenuu {
  0% {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
            @include opacity(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(0);
            transform: translateX(0);
            @include opacity(1);
  }
}