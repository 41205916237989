.owl-carousel .owl-item img{
	width: auto;
	display: inline-block;
}
.owl-theme {
	// Styling Next and Prev buttons
	position: relative;
	.owl-nav {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		@include opacity(0);
		@include transition(all 0.5s ease);
		margin-top: 0;
		[class*='owl-'] {
			position: absolute;
			top: 50%;
			@include translateY(-50%);
			border: 1px solid #f3f3f3;
			color: #686868;
			font-size: 1.4rem;
			background: $white;
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			cursor: pointer;
			position: absolute;
			@include size(38px, 38px);
			text-align: center;
			margin: 0;
			padding: 0;
			@include border-radius(0px);
			@include transition(all 0.5s ease);
			&:hover {
				background: $theme-color-default;
				text-decoration: none;
				border-color: $theme-color-default;
				color: $white;
			}
			&.disabled {
				cursor: not-allowed;
				@include opacity(1);
			}
			span{
				display: none;
			}
		}
		.owl-prev {
			@include rtl-right(0);
			i{
				font-family: "fontello" !important;
				&:before{
					content: "\e916" !important;
				}
			}
		}
		.owl-next {
			@include rtl-left(0);
			i{
				font-family: "fontello" !important;
				&:before{
					content: "\e915" !important;
				}
			}
		}


	}

	&:hover {
		.owl-nav {
			@include opacity(1);
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 0;
	}
	.owl-dots {
	  top: 0;
	  @include flexbox();
	  position: absolute;
	  z-index: 99;
	  @include rtl-left(0);
	  @include rtl-text-align-right();
	  margin: 0;
	  .owl-dot {
		display: inline-block;
		zoom: 1;
		span {
			background-color: transparent;
			cursor: pointer;
			display: inline-block;
			height: 10px;
			width: 10px;
			margin: 2px;
			padding: 0;
			position: relative;
			text-indent: -9999px;
			border-radius: 50%;
			background: #d7d7d7;
			@include transition(all 0.35s ease);
		}
	    &:hover, &.active {
	    	span {
	    		background: $theme-color-default;
	    	}
	    }
	  	
	  }
	}
}
